import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css';
import './../solutions/esim.css'
import { Helmet } from "react-helmet";
import Book from './book';
const MobileExpert = () => {

const imagesRef = useRef([]);
const sideImagesRef = useRef([]);


// Initialize ScrollTrigger
useEffect(() => {
let ctx = gsap.context(() => {

gsap.registerPlugin(ScrollTrigger);

gsap.set(".image", { zIndex: (i, target, targets) => targets.length - i });
gsap.set(".side-image", { zIndex: (i, target, targets) => targets.length - i });

const images = imagesRef.current;
images.forEach((image, i) => {
const nextImage = image.nextElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "100%" }, { height: "0%", ease: "none" }, 0);
// You can add more animations here if needed
});

const sideImages = sideImagesRef.current;
sideImages.forEach((image, i) => {
const prevImage = image.previousElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "0%", scale: 1.00 }, { height: "100%", scale: 1.125, ease: "none" }, 0)
.fromTo(prevImage, { scale: 1.125 }, { scale: 1.25, ease: "none" }, 0);
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((images.length) * window.innerHeight),
pin: '.image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((sideImages.length) * window.innerHeight),
pin: '.side-image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});
});
return () => ctx.revert();
}, []); // Empty dependency array to run effect only once


return (

<>
<Helmet>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Managed Security Services (MSS) | Cybersecurity | Procal" />
            <meta property="og:description" content="Leveraging our expertise and advanced tools, we offer a tailored end-to-end security solution from L1 to L3, enabling organizations to focus on core operations while entrusting security to us" />
            <meta property="og:url" content="https://www.procaltech.com/solutions/" />
            <meta property="og:site_name" content="Procal Technologies" />

            </Helmet>


<div className="scroller">

<div id='loc'>
<section className="showcase">
<div className="text-wrap">
<div className="text-wrapper">



<div className="textL blue">
<h4>OS Integration and Compatibility</h4>

<p>Procal specializes in integrating and ensuring compatibility between the mobile OS and clients’ software ecosystem. We seamlessly merge the mobile OS with third-party applications, cloud services, and backend systems, guaranteeing a smooth user experience. By harmonizing various components, Procal enables clients to create a cohesive and integrated software environment that enhances efficiency and user satisfaction. Our expertise in OS integration and compatibility ensures a seamless and streamlined user experience across different platforms and services.</p>
</div>
<div className="textL c">
<h4>SDK’s</h4>

<p>Procal is a leading company with comprehensive expertise in all major Software Development Kits (SDKs) within the mobile OS solutions domain. With our deep knowledge of SDKs, we enable developers to harness the full potential of mobile operating systems. Our extensive familiarity with these SDKs empowers us to provide top-notch support, integration, and optimization services. Whether it’s iOS, Android, or other prominent mobile OS platforms, Procal ensures that developers can leverage the SDKs seamlessly, resulting in robust and feature-rich mobile applications.</p>
</div>
<div className="textL green"><h4>MDM</h4>

<p>Procal, a leading provider of mobile OS solutions, integrates robust Mobile Device Management (MDM) capabilities into its offerings. With our MDM solutions, businesses can efficiently manage and secure their fleet of mobile devices. Procal’s MDM empowers organizations to enroll and configure devices, enforce security policies, distribute applications, monitor device health, and ensure compliance. With our comprehensive MDM features, Procal enables seamless device management, enhanced security, and streamlined mobile operations for our clients.</p></div>

<div className="textL purple"><h4>API</h4>

<p>Procal’s has expertise in creating and delivering multiple APIs  tailored to our customer needs. We build API’s that provide seamless integration with existing systems on the network. The API’s we build help promote and generate revenue for our customers. Our service and data APIs are simple to use that can be easily integrated. For fast-moving developers building on a global-scale, APIs are no longer a stop-gap to the future – they’re a critical part of their strategy, and Procal is here to build and support in fast and efficient manner.</p></div>




</div>
</div>

<div className="image-wrap">
<div className="image " ref={el => imagesRef.current.push(el)}></div>
<div className="image uic" ref={el => imagesRef.current.push(el)}></div>
<div className="image uic" ref={el => imagesRef.current.push(el)}></div>
<div className="image uic" ref={el => imagesRef.current.push(el)}></div>
</div>



</section>
</div>

</div>

</>
)
}

export default MobileExpert;