// /* eslint-disable import/first */
import { gsap } from "gsap";
import React from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import 'bootstrap/dist/js/bootstrap.bundle';
import united from './../../images/jump_astronaut.png'
import globe from './../../images/procal-1.png'
import sixg from './../../images/6g.jpg'
import spot from './../../images/spot-finder.jpg'
import art from './../../images/art.jpg'
import iso1 from './../../images/iso1.png'
import iso2 from './../../images/iso2.png'
import iso3 from './../../images/iso3.png'
import whatsnew from './../../images/whats-new-bg.png'
import cutting from './../../images/cutting-edge.jpg'
import cloud from './../../images/cloud-solution.jpg'
import banking from './../../images/banking.jpg'
import bankingfin from './../../images/banking-finace.jpg'
import lifesc from './../../images/life-sc.jpg'
import telecomtower from './../../images/telecom-tower.jpg'
import consumer from './../../images/cosumer-product.jpg'
import indus from './../../images/indus.jpg'
import healthcare from './../../images/healthcare.jpg'
import insurance from './../../images/insurance.jpg'
import software from './../../images/software-tech.jpg'
import mike from './../../images/mike.png'
import "./home.css";
import { useCallback } from "react";
import { useEffect } from 'react';
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import { _horizontal } from "gsap/Observer";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Footer from './../../common/Footer';
import Menu from './../../common/Menu'
import { Helmet } from 'react-helmet';
// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
function Home() {

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  /* ScrollTrigger Docs
  
  https://greensock.com/docs/v3/Plugins/ScrollTrigger
  
  */

  /* 
  
  learn more GreenSock and ScrollTrigger
  
  https://www.creativeCodingClub.com
  
  new lessons weekly
  less than $1 per week
  
  */
  // gallery  code end

  

  useEffect(() => {
    // Initialize ScrollTrigger

    let ctx = gsap.context(() => {
      // Select all elements with the className '.section-title__square'
      const gsapSq = gsap.utils.toArray('.section-title-square');

      // Loop through each element
      gsapSq.forEach((gSq, i) => {
        // Create animation
        const rotat = gsap.from(gSq, { duration: 3, rotation: 720 });

        // Create ScrollTrigger
        ScrollTrigger.create({
          trigger: gSq,
          animation: rotat,
          start: 'top bottom',
          scrub: 1.9
        });
      });


      gsap.from(".circle", {});
      gsap.to('.box', {
        scrollTrigger: {
          trigger: '.star',
          start: 'top top',
          scrub: 1.9
        },
        xPercent: 50
      })

      gsap.from('.animate-element-4', {
        scrollTrigger: {
          trigger: '.animate-element-4',
          start: 'top top',
          end: "40% 80%",
          scrub: 2,
          toggleActions: 'restart none none none',
          pin: true,
          pinSpacer: false
        }

      })



      gsap.to("#sec2", {
        y: -50,
        duration: 0.2,

        //ease: "bounce",
        scrollTrigger: {
          trigger: "#sec2",
          // markers: false,
          start: "top 50%",
          end: "top 80%",
          scrub: 2,
          toggleActions: 'restart none none none',
          pin: true,
          pinSpacer: false
        }
      });
      gsap.from('#four', {
        scrollTrigger: {
          trigger: '#four',
          start: "top center",
          end: "bottom 80px",
          scrub: true,
        },
        opacity: 1,
        duration: 1,
        ease: 'power2.inOut',
        duration: 1,
        delay: 0.5,
      });


      const tl = gsap.timeline({
        scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
        },
        ease: 'power.inOut',
        });
        
        tl.to('.title-outline', {
        yPercent: 100,
        })
        .to('.title--hello', {
        yPercent: 100,
        }, 0)
        .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
        }, 0)
        .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
        trigger: ".outline-box",
        start: "top center",
        scrub: 1,
        
        },
        ease: 'power.inOut',
        }, 0)
        .from('.image-box0 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
        trigger: ".image-box0",
        start: "top center",
        scrub: 2,
        
        }, ease: 'power.inOut',
        }, 0)
        

      gsap.registerPlugin(ScrollTrigger);

      // Define animation to move element from left to right
      const moveLeftToRight = (element) => {
        gsap.to(element, {
          x: '-15%',
          opacity: 1,
          duration: 1,
          ease: 'power2.inOut',
        });
      };

      // Define animation to move element from right to left
      const moveRightToLeft = (element) => {
        gsap.to(element, {
          x: '15%',
          opacity: 1,
          duration: 1,
          ease: 'power2.inOut',
        });
      };

      // Set up ScrollTriggers for left to right and right to left animations
      const animateElements = document.querySelectorAll('.animate-element');
      animateElements.forEach((element) => {
        ScrollTrigger.create({
          trigger: element,
          start: 'top bottom-=100',
          end: 'top center',
          onEnter: () => moveLeftToRight(element),
          onLeaveBack: () => moveRightToLeft(element),
          onEnterBack: () => moveRightToLeft(element),
          onLeave: () => moveLeftToRight(element),
        });
      });



    })


    // Define animation to move element from left to right
    const moveLeftToRight = (element) => {
      gsap.to(element, {
        y: '20%',
        opacity: 1,
        duration: 1,
        ease: 'power2.inOut',
      });
    };

    // Define animation to move element from right to left
    const moveRightToLeft = (element) => {
      gsap.to(element, {
        y: '-20%',
        opacity: 1,
        duration: 1,
        ease: 'power2.inOut',
      });
    };

    // Set up ScrollTriggers for left to right and right to left animations
    const animateElements = document.querySelectorAll('.animate-element-4');
    animateElements.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: 'top bottom-=0',
        end: 'top center',

        scrub: 1,
        onEnter: () => moveLeftToRight(element),
        onLeaveBack: () => moveRightToLeft(element),
        onEnterBack: () => moveRightToLeft(element),
        onLeave: () => moveLeftToRight(element),
      });
    });

    const animat = document.querySelectorAll('.animate-element-5');
    animat.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: 'top bottom+=50',
        end: 'top center',
        onEnter: () => moveLeftToRight(element),
        onLeaveBack: () => moveRightToLeft(element),
        onEnterBack: () => moveRightToLeft(element),
        onLeave: () => moveLeftToRight(element),
      });
    });

    const animatd = document.querySelectorAll('.animate-element-412');
    animatd.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: 'top bottom+=50',
        end: 'top center',
        pin: true,
        onEnter: () => moveLeftToRight(element),
        onLeaveBack: () => moveRightToLeft(element),
        onEnterBack: () => moveRightToLeft(element),
        onLeave: () => moveLeftToRight(element),
      });
    });


    // Define animation to animate each letter of the text
    const animateText = (element) => {
      const text = element.textContent.trim();
      element.textContent = ''; // Clear the text content

      text.split('').forEach((char, index) => {
        const letter = document.createElement('span');
        letter.textContent = char === ' ' ? '\u00A0' : char; // Add non-breaking space for regular space
        letter.style.opacity = 0;
        letter.style.display = 'inline-block';
        gsap.to(letter, {
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out',
          delay: index * 0.1, // Add delay for each letter
        });
        element.appendChild(letter);
      });
    };

    // Set up ScrollTriggers for text animations

    const animateElementsT = document.querySelectorAll('.animate-text-2');

if (window.innerWidth > 768) { // Only activate on screens wider than 768px
  animateElementsT.forEach((element) => {
    ScrollTrigger.create({
      trigger: element,
      start: 'top bottom-=100',
      end: 'top center',
      onEnter: () => animateText(element),
      once: false // Animation will happen only once
    });
  });
}
    // const animateElementsT = document.querySelectorAll('.animate-text-2');
    // animateElementsT.forEach((element) => {
    //   ScrollTrigger.create({
    //     trigger: element,
    //     start: 'top bottom-=100',
    //     end: 'top center',
    //     onEnter: () => animateText(element),
    //     once: false // Animation will happen only once
    //   });
    // });


    // Define animation to animate each letter of the text
    const animateTextW = (element) => {
      const letters = element.textContent.split('');
      element.textContent = ''; // Clear the text content
      letters.forEach((letter, index) => {
        const span = document.createElement('span');
        span.textContent = letter;
        span.style.display = 'inline-block';
        span.style.opacity = 0;
        span.style.transform = 'translateY(100%)'; // Move letters down initially
        gsap.to(span, {
          opacity: 1,
          transform: 'translateY(0)',
          duration: 1,
          ease: 'power2.out',
          delay: index * 0.1, // Add delay for each letter
        });
        element.appendChild(span);
      });
    };

    // Set up ScrollTriggers for text animations
    const animateElementsO = document.querySelectorAll('.animate-text');
    animateElementsO.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: 'top bottom-=100',
        end: 'top center',
        onEnter: () => animateTextW(element),
        once: false // Animation will happen only once
      });
    });

    return () => ctx.revert();
  }, []); // Empty dependency array to run effect only once




  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);
  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);

  return (
    <div className="App">

      <Helmet>
        <title>Digital Transformation Services and Solutions | IT Consulting </title>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://www.procaltech.com/" />
        <meta name="description" content="Elevate your business with ProcalTech & 15+ years of experience in digital transformation. Harnessing the latest AI technology. Book Free Consultation Today" />
        <meta property="og:url" content="https://www.procaltech.com/" />
        <meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <main>
        <section className="r px-4w p-5w sec1">
          <Menu />
          <div className="container-fluid">
            <Particles
              id="tsparticles"
              init={particlesInit}
              loaded={particlesLoaded}

              options={{
                fullScreen: {
                  enable: false
                },
                fpsLimit: 100,
                interactivity: {
                  events: {
                    onClick: {
                      enable: true,
                      mode: "push",
                    },
                    onHover: {
                      enable: true,
                      mode: "repulse",
                    },
                    resize: true,
                  },
                  modes: {
                    push: {
                      quantity: 4,
                    },
                    repulse: {
                      distance: 200,
                      duration: 0.4,
                    },
                  },
                },
                particles: {
                  color: {
                    value: "#010267",
                  },
                  links: {
                    color: "#c9e3f9",
                    distance: 150,
                    enable: true,
                    opacity: 0.5,
                    width: 4,
                  },
                  move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                      default: "bounce",
                    },
                    random: false,
                    speed: 0.5,
                    straight: false,
                  },
                  number: {
                    density: {
                      enable: true,
                      area: 600,
                    },
                    value: 150,
                  },
                  opacity: {
                    value: 0.5,
                  },
                  shape: {
                    type: "circle",
                  },
                  size: {
                    value: { min: 1, max: 5 },
                  },
                },
                detectRetina: true,
              }}
            />
            <div className="row align-items-center py-5 box1 about__img top-large-margin w-100">



              <div className="col-lg-7 offset-lg-1 col-xxl-5 offset-xxl-1 col-md-6 col-12">

                <div className="r2ow">          <div className="col-lg-12  col-12 hero"><h1 className="display-5 lh-1 mb-3 star">Digital Transformation Services </h1>

                </div>
                  <div className="col-lg-10 col-12 hero">
                    <p className="lead"><strong>Driving The World Forward.</strong><br/>Procal is a digital software company specializing in tailored solutions for businesses. Our focus is on delivering top-notch user experiences through advanced technology and digital innovations in today's fast-paced world. We combine strategic design, advanced engineering, and industry knowledge to push innovation to new levels, leading to exceptional customer satisfaction.</p>
                  </div></div>
              </div>

              <div className="col-lg-5 col-xxl-5 offset-xxl-1 col-md-6 col-12">

                <div className="2row">
                  <div className="col-lg-12 col-12 "><img src={globe} className="img-fluid globe-scale" alt="Driving The World Forward" /></div></div>
              </div>


            </div>
          </div>
        </section>


     

        {/* Dimond section */}
        <section class="1hero-c9w section-5d5 pt-5">
          <div class="1container-xsm container-fluid">

            <div className="row">
              <div class="1col-ajh 1text-wl7 col-lg-12 col-sm-1 col-12 text-center">
                <h2 className="grand1  h2-heading">We are Procal</h2>

              </div>



            </div>

            <div className="row 1align-items-center py-5 box1 about__img ">

              {/* <div className="col-10 col-sm-8 col-lg-6">
<img src="bootstrap-themes.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
</div> */}



<div className="col-lg-3 col-xxl-3 offset-xxl-1 col-md-3 col-12 text-justify" >
                <img className="clippath" src={united} alt="We are Procal"/>

                {/* <div className="row">
                  <div className="col-lg-12 col-12 ">
                    <p className="lead">At Procal, we understand that in today’s rapidly evolving landscape, staying ahead means embracing digital innovation. That’s why we’re here to guide businesses through every step of their digital transformation journey.Join us in shaping the future of technology and transforming industries one innovation at a time. With Procal, the possibilities are limitless.</p>
                  </div></div> */}
              </div>

              <div className="col-lg-5 offset-lg-0 col-xxl-7  col-md-6 col-12 text-justify">

                <div className="row">
                  <div className="col-lg-11 col-12 hero">
                    <p className="lead"> We focus on providing exceptional user experiences by harnessing the power of AI technology, advanced digital innovations, and the latest technological advancements in today’s competitive market. Our approach blends strategic design, cutting-edge engineering, and deep industry expertise to drive innovation, ensuring unparalleled customer satisfaction.</p>
                  </div></div>
              </div>


            </div>

          </div>
        </section>


<section class="masonry px-4 py-51 p-51 pb-5 sec16 d-lg-block d-none">
  <div class="section-zqn pb-69f">
    <div class="container-mji">
      <h2 className="display-5 fw-boldw lh-1 mb-3 text-center animate-text-2">Industries, we serve</h2>
      <p className="text-center mb-5 lead">
        Creating business value at the intersection of your industry transformation and today's latest technology innovation.
      </p>

      <div>
        <div id="pos-zry" class="row-3os style-aAggA">
          <div class="item-2rh col-pc8 col-tog col-a3y style-VgSH5" id="style-VgSH5">
            <Link to="/our-industries#banking">
              <div href="portfolio-single-1.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Banking And Financial Services</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-RosCv" id="style-RosCv"></span>
                  <div class="content-4kg style-VBoe5" id="style-VBoe5">
                    <div class="overlay-1rh"></div>
                    <img src={bankingfin} class="img-hwe style-geFdo" id="style-geFdo" alt="Banking And Financial Services" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div class="item-2rh col-pc8 col-tog col-a3y style-MKyDl" id="style-MKyDl">
            <Link to="/our-industries#life">
              <div href="portfolio-single-2.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Life Sciences</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-6VgTp" id="style-6VgTp"></span>
                  <div class="content-4kg style-29s3Q" id="style-29s3Q">
                    <div class="overlay-1rh"></div>
                    <img src={lifesc} class="img-hwe style-M9rK4" id="style-M9rK4" alt="Life Sciences" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div class="item-2rh col-pc8 col-tog col-a3y style-Bqtdn" id="style-Bqtdn">
            <Link to="/our-industries#telecom">
              <div href="portfolio-single-3.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Telecom and Media</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-iaTgQ" id="style-iaTgQ"></span>
                  <div class="content-4kg style-vGqCZ" id="style-vGqCZ">
                    <div class="overlay-1rh"></div>
                    <img src={telecomtower} class="img-hwe style-gdj1w" id="style-gdj1w" alt="Telecom and Media" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div class="item-2rh col-pc8 col-tog col-a3y style-7oMxj" id="style-7oMxj">
            <Link to="/our-industries#insurance">
              <div href="portfolio-single-3.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Insurance</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-yIROP" id="style-yIROP"></span>
                  <div class="content-4kg style-WHChB" id="style-WHChB">
                    <div class="overlay-1rh"></div>
                    <img src={insurance} class="img-hwe style-jv9Qs" id="style-jv9Qs" alt="Insurance" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div class="item-2rh col-pc8 col-tog col-a3y style-xxfl8" id="style-xxfl8">
            <Link to="/our-industries#industrial">
              <div href="portfolio-single-3.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Industrial</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-rfsxH" id="style-rfsxH"></span>
                  <div class="content-4kg style-1Lo3V" id="style-1Lo3V">
                    <div class="overlay-1rh"></div>
                    <img src={indus} class="img-hwe style-FE9zG" id="style-FE9zG" alt="Industrial" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div class="item-2rh col-pc8 col-tog col-a3y style-LDWzW" id="style-LDWzW">
            <Link to="/our-industries#consumer">
              <div href="portfolio-single-1.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Consumer Tech</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-I5s3h" id="style-I5s3h"></span>
                  <div class="content-4kg style-px3v6" id="style-px3v6">
                    <div class="overlay-1rh"></div>
                    <img src={consumer} class="img-hwe style-jNBGk" id="style-jNBGk" alt="Consumer Tech" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div class="item-2rh col-pc8 col-tog col-a3y style-poQoe" id="style-poQoe">
            <Link to="/our-industries#healthcare">
              <div href="portfolio-single-2.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Healthcare</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-l3oc8" id="style-l3oc8"></span>
                  <div class="content-4kg style-NK6MC" id="style-NK6MC">
                    <div class="overlay-1rh"></div>
                    <img src={healthcare} class="img-hwe style-jhw8x" id="style-jhw8x" alt="Healthcare" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div class="item-2rh col-pc8 col-tog col-a3y style-X2z1n" id="style-X2z1n">
            <Link to="/our-industries#software">
              <div href="portfolio-single-3.html" class="item-pgv gsap-reveal-69z">
                <div class="content-h8n">
                  <h3>Software and Hi-Tech</h3>
                </div>
                <div class="reveal-7r9">
                  <span class="cov-h3h style-BczkW" id="style-BczkW"></span>
                  <div class="content-4kg style-RH5JG" id="style-RH5JG">
                    <div class="overlay-1rh"></div>
                    <img src={software} class="img-hwe style-Ull37" id="style-Ull37" alt="Software and Hi-Tech" />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

{/* mobile version massonary start*/}
<div className='dock d-block d-sm-none'>

  <h2 className="display-5 fw-boldw lh-1 mb-3 text-center animate-text-2">Industries, we serve</h2>
  <p className="text-center mb-5 lead">Creating business value at the intersection of your industry transformation and today's latest technology innovation.</p>
  
  <div className="masonry-grid">
    <div className="grid-item">
      <Link to="/our-industries#banking">
        <img src={bankingfin} class="img-hwe style-geFdo" id="style-geFdo" alt="Banking And Financial Services" />
        <div className="overlay">Banking And Financial Services</div>
      </Link>
    </div>
    <div className="grid-item">
      <Link to="/our-industries#life">
        <img src={lifesc} class="img-hwe style-M9rK4" id="style-M9rK4" alt="Life Sciences" />
        <div className="overlay">Life Sciences</div>
      </Link>
    </div>
    <div className="grid-item">
      <Link to="/our-industries#telecom">
        <img src={telecomtower} class="img-hwe style-gdj1w" id="style-gdj1w" alt="Telecom and Media" />
        <div className="overlay">Telecom and Media</div>
      </Link>
    </div>
    <div className="grid-item">
      <Link to="/our-industries#consumer">
        <img src={consumer} class="img-hwe style-jNBGk" id="style-jNBGk" alt="Consumer Tech" />
        <div className="overlay">Consumer Tech</div>
      </Link>
    </div>
    <div className="grid-item">
      <Link to="/our-industries#insurance">
        <img src={insurance} class="img-hwe style-jv9Qs" id="style-jv9Qs" alt="Insurance" />
        <div className="overlay">Insurance</div>
      </Link>
    </div>
    <div className="grid-item">
      <Link to="/our-industries#industrial">
        <img src={indus} class="img-hwe style-FE9zG" id="style-FE9zG" alt="Industrial" />
        <div className="overlay">Industrial</div>
      </Link>
    </div>
    <div className="grid-item">
      <Link to="/our-industries#healthcare">
        <img src={healthcare} class="img-hwe style-jhw8x" id="style-jhw8x" alt="Healthcare" />
        <div className="overlay">Healthcare</div>
      </Link>
    </div>
    <div className="grid-item">
      <Link to="/our-industries#software">
        <img src={software} class="img-hwe style-Ull37" id="style-Ull37" alt="Software and Hi-Tech" />
        <div className="overlay">Software and Hi-Tech</div>
      </Link>
    </div>
  </div>
</div>

{/* mobile version massonary end */}


        {/* section 4 start */}

        <section className="px-4 py-5 p-5 pb-5 sec4">
          <div className="container-fluid">
            <div className="row align-items-center py-5">

              <div className="col-lg-8 offset-lg-1 hero col-12 pt-5"><h2 className="display-5 fw-boldw lh-1 mb-3 animate-text-2">Unleash Your Potential
                With Procal</h2></div>

              <div className="col-lg-5 offset-lg-1 hero">

                <p className="lead">At Procal, our mission is to empower businesses to thrive by delivering innovative and customized technology solutions that drive success</p>

              </div>
            </div>
          </div>
        </section>

        {/* section5 start */}

        <section className="px-4 py-5 p-5 pb-5 sec5">
          <div className="container-fluid">
            <div className="row align-items-center py-5 pos-40">

              <div className="col-lg-9 offset-lg-1 hero col-12 pt-5">
              </div>

              <div className="col-lg-8 offset-lg-1 col-12 hero-black animate-element-4 p-5">
                <h2 className="display-5 fw-bold1 lh-1 mb-3 text-white animate-text-2">Industry Expertise</h2>
                <p className="lead text-white ">Our subject matter experts possess extensive industry expertise across diverse sectors, allowing us to cater to a wide variety of clients needs.</p>

              </div>
            </div>
          </div>

        </section>


        {/* Annoucment section */}
        <section className="px-4 py-5 p-5 pb-5 sec-annoucement">
          <div className="container-fluid">

            <div className="row align-items-center py-5 pos-20">
<div className="col-lg-2  offset-lg-1 col-12 col-sm-2"><img src={mike} className="img-fluid" alt="Announcements"/></div>
              <div className="col-lg-5 offset-lg-0 col-12 hero pt-5 animate-element-4">
                <span><h2 className="display-5 fw-boldw lh-1 mb-3 animate-text-2">Announcements</h2></span>
                <p className="lead animate-element-4">Procal opened offices in Dubai, UAE to invest in Dubai's technology adaption</p>

              </div>


            </div>
          </div>
        </section>
        {/* Annoucemnt section end */}

        {/* section 6 start */}

        <section className="px-4 py-5 p-5 pb-5 sec6">
          <div className="container-fluid">
            <div className="row align-items-center py-5 pos-30">

              <div className="col-lg-7 offset-lg-1 col-12 hero pt-5">    <h2 className="display-5 fw-boldw lh-1 mb-3 text-white animate-text-2">Efficiency and Cost Savings</h2></div>

              <div className="col-lg-5 offset-lg-1 col-12 hero-black1s animate-element-4">

                <p className="lead text-white">Procal's cost-effective solutions boost efficiency and productivity, delivering tangible improvements to streamline operations and drive optimal performance.</p>

              </div>
            </div>
          </div>
        </section>
        {/* section7 start */}


        <section className="px-4 py-5 p-5 pb-5 sec7">
          <div className="container-fluid">

            <div className="row align-items-center py-5 pos-20">

              <div className="col-lg-6 offset-lg-1 col-12 hero pt-5 animate-element-4"><h2 className="display-5 fw-boldw lh-1 mb-3 animate-text-2">Reduced Time to Market</h2>
                <p className="lead animate-element-4">Procal's solutions accelerate time-to-market, enabling you to deliver a more viable product efficiently and swiftly.</p>

              </div>


            </div>
          </div>
        </section>

        {/* section 8 start */}

        <section className="px-4 py-5 p-5 pb-5 sec8">
          <div className="container-fluid">
            <div className="row align-items-center py-5 pos-20">

              {/* <div className="col-10 col-sm-8 col-lg-6">
        <img src="bootstrap-themes.png" className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
      </div> */}
              <div className="col-lg-6 offset-lg-6 col-12 hero pt-5 animate-element-4"><h2 className="display-5 lh-1 mb-3 text-white animate-text-2">Privacy and Security</h2>
                <p className="lead text-white">We prioritize your business's safety and security by delivering solutions that are designed to safeguard your privacy and protect your sensitive information.</p>
              </div>

            </div>
          </div>

        </section>

        {/* test section */}

        {/* <section className="px-4 py-1 p-5 pb-5 sec9">
          <div className="container">

            <div class="wrap">
              <h3 className='d3'><strong>CLIENTELE</strong></h3>
              <p className="lead">Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services.</p>
              <ul class="clients">
                <li><a href=""><img src={moto} alt="Google" className="invert" /></a></li>
                <li><a href=""><img src={tele} alt="Google" className="bright" /></a></li>
                <li><a href=""><img src={tmobile} alt="Apple" className="bright" /></a></li>
                <li><a href=""><img src={erec} alt="Microsoft" className="bright" /></a></li>
              </ul>

              <ul class="clients">
                <li><a href=""><img src={version} alt="Google" className="invert" /></a></li>
                <li><a href=""><img src={att} alt="Google" className="bright" /></a></li>

                <li><a href=""><img src={united} alt="Microsoft" className="invert" /></a></li>
              </ul>

            </div>
            {/* <div className="row align-items-center py-5 pos-20">

     
              <div className="col-lg-5 col-12 hero pt-5 animate-element-5"><h2 className="display-5 lh-1 mb-3 animate-text-2">Our Clients</h2>
                <p className="lead">Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services.</p>
              </div>

              <div className="col-lg-6 offset-lg-1 col-12">

                <div className="flex-container-client align-items-center animate-element-4ws">
                  <div className="flex-items-client" ><img src={tmobile} className="img-fluid circle greycel" /></div>
                  <div className="flex-items-client" ><img src={erec} className="img-fluid circle greycel" /></div>
                  <div className="flex-items-client" ><img src={tele} className="img-fluid circle greycel" /></div>
                  <div className="flex-items-client" ><img src={moto} className="img-fluid circle greycel" /></div>
                </div>
              </div>

            </div>
          </div>

        </section> */}


        <section className="sec15  mb-5">
          <div class="Section_top">

            <div className="container">
              <div className="row py-lg-5">
                <div className="col-lg-9 offset-lg-2 col-md-7 mx-auto text-white">
                  <h2 className="animate-text-2 text-center">Revolutionizing Connectivity,</h2>
                  <h2 className="display-5 mb-3 animate-text-2 text-center"> Elevating Possibilities</h2>
                  <p className="text-center mt-3 lead">Procal's diverse solutions are revolutionizing the way we interact with technology, empowering people to navigate a new era of seamless connectivity and limitless possibilities.</p>


                </div>

                <div className="col-lg-10 offset-lg-1 col-md-7 mt-5">

                  <div className="slider-container mb6">
                    <Slider {...settings}>
                      <div>
                        <div className="connectivity  mx-auto">
                          <h2 className="display-5 lh-1 mb-3 text-center">Cybersecurity Solutions Overview</h2>
                          <p className="text-center mt-5">Procal’s cybersecurity solutions redefine the landscape of digital security, harnessing state-of-the-art technologies to fortify your organization against evolving cyber threats. We offer a holistic range of services, from governance risk and compliance to data privacy and protection, enabling businesses to navigate the complex cybersecurity terrain with confidence. </p>

                          <p className="text-center learnmore"><Link to="/solutions/cyber-security">Learn More</Link></p>
                        </div>
                      </div>
                      <div>
                        <div className="connectivity  mx-auto">
                          <h2 className="display-5 lh-1 mb-3 text-center">Telecom Solutions</h2>
                          <p className="text-center mt-5">Procal’s telecom solutions pioneer the future of connectivity, leveraging cutting-edge technologies like 5G to enhance network performance and enable transformative communication experiences. Our comprehensive solutions empower businesses to thrive in the digital era, driving efficiency, productivity, and seamless connectivity across industries.</p>

                          <p className="text-center learnmore"> <Link to="/solutions/telecom">Learn More</Link></p>
                        </div>
                      </div>
                      <div>
                        <div className="connectivity  mx-auto">
                          <h2 className="display-5 lh-1 mb-3 text-center">Emerging Technology</h2>
                          <p className="text-center mt-5">Procal’s emerging technology solutions propels businesses into the forefront of innovation, harnessing emerging technologies to drive efficiency, automation, and transformative experiences. From immersive XR/AR/VR solutions to cutting-edge robotics and the limitless potential of CV2X and drones, we empower organizations to embrace the future and unlock new realms of success. </p>

                          <p className="text-center learnmore"><Link to="/solutions/emerging-technologies">Learn More</Link></p>
                        </div>
                      </div>
                      <div>
                        <div className="connectivity  mx-auto">
                          <h2 className="display-5 lh-1 mb-3 text-center">Automation Testing</h2>
                          <p className="text-center mt-5">Procal’s automation solutions provide businesses with a comprehensive suite of cutting-edge tools and technologies, empowering them to automate and optimize their operations. Streamlining networks, enhancing testing capabilities, developing custom scripts, and certifying devices, our solutions drive unparalleled efficiency, accuracy, and productivity throughout organizations’ workflows. Experience the transformative power of automation with Procal.</p>

                          <p className="text-center learnmore"><Link to="/solutions/robot-processing-automation/">Learn More</Link></p>
                        </div>
                      </div>
                      <div>
                        <div className="connectivity  mx-auto">
                          <h2 className="display-5 lh-1 mb-3 text-center">Data Analytics Insights</h2>
                          <p className="text-center mt-5">Procal’s data analytics solutions combine advanced technologies and deep expertise to extract valuable insights from data. By leveraging the power of AI/ML, big data analytics, computer vision, and predictive modelling we enable organizations to uncover hidden patterns, make data-driven decisions, and drive transformative outcomes for sustainable growth and competitive advantage.</p>

                          <p className="text-center learnmore"><Link to="/solutions/data-analytics-and-insights">Learn More</Link></p>
                        </div>
                      </div>

                    </Slider>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </section>
        {/* section 12 end  */}
        {/* embrace section start */}
        <section className="text-center container sec10 py-5 p-5 pb-5">
          <div className="row py-lg-5">
            <div className="col-lg-12 col-md-8 mx-auto">
              <h2 className="display-5 lh-1 mb-3 animate-text-2">Embrace the Extraordinary</h2>
              <p className="text-center">Our dynamic products are designed to optimize performance through enhanced testing, monitoring, and analytics capabilities.</p>


            </div>
          </div>
          <div className="album bg-body-tertiary">
            <div className="container pb-5 dive-cobalt">
              <div className="row">


                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-50" >
                  <div className="col-bg">

                    <div className="box02 bg-coral-medium list-video mobiletopp">
                      <img src={sixg} className="card-img1" alt="Snap Automation" />

                      <div className="card-img-overlay">
                        <h5 className="card-title text-center mobiletopp">Snap Automation</h5>
                        <p className="card-text">Snap is an innovative product developed to revolutionize application testing and performance.</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-50" >
                  <div className="col-bg">

                    <div className="box02 bg-coral-medium list-video mobiletopp">
                      <img src={spot} className="card-img1" alt="Spot Finder" />

                      <div className="card-img-overlay">
                        <h5 className="card-title text-center mobiletopp">Spot Finder</h5>
                        <p className="card-text">Spot Finder is an innovative solution developed by Procal to address the challenges faced by customers in installing Customer Premises Equipment</p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-50" >
                  <div className="col-bg">

                    <div className="box02 bg-coral-medium list-video mobiletopp">
                      <img src={art} className="card-img1" alt="Artemis" />

                      <div className="card-img-overlay">
                        <h5 className="card-title text-center mobiletopp">Artemis</h5>
                        <p className="card-text">Artemis is Procal's cutting-edge XR Network Performance Diagnostics solution, specifically designed for Extended Reality (XR) applications</p>
                      </div>
                    </div>

                  </div>
                </div>


              </div>


            </div>
          </div>
        </section>


        {/* embrace section end */}


        <section className="px-4 py-5 p-5 pb-5 sec11">
          <div className="container-fluid">

            <div className="row align-items-center mt-5">

 
              {/* <div className="col-lg-4 offset-lg-3 col-12 hero iso pt-5"><img src={iso} className="img-fluid1 samesizeimg center-block" /></div> */}
              <div className="col-lg-12 offset-lg-0 col-12 hero pt-5 mt-2 text-center mb-5">
                <h2 className="display-5 fw-boldw lh-1 animate-text-2 text-white certification-margin">Certifications</h2>


              </div>


              <div className="certification mt-5">
                <div className="certification-items"><img src={iso1} className="scale7" alt="ISO 9001" /></div>
                <div className="certification-items"><img src={iso2} className="scale7" alt="ISI27001" /></div>
                <div className="certification-items"><img src={iso3} className="scale7" alt="SOC2 TYPE2" /></div>
              </div>

            </div>
          </div>
        </section>

        {/* whats new section */}
        <section className="container sec10 py-5 p-5 pb-5">
          <div className="row py-lg-5">
            <div className="col-lg-12 col-md-8 mx-auto">


              <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">What’s New at Procal ?</h2>
              <p className="text-center">Explore our projects, unique insights, and areas of expertise to delve into our world of innovation.</p>


            </div>


          </div>

          {/* slicky slider */}
          <div className="slider-container">
            <Slider {...settings2}>
              <div>
                <div className="row dive-cobalt align-items-center">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="col-bg">

                      <div className="box03 bg-coral-medium list-video">
                        <img src={whatsnew} className="card-img1" alt="Banking in a New Era – Digitization at Its Peak" />


                      </div>

                    </div>
                  </div>


                  <div className="col-lg-6 col-md-6 col-12 align-items-center">

                    <h4>Banking in a New Era – Digitization at Its Peak</h4>
                    <h6 className="greytxt"> February 15, 2024</h6>
                    <p>In an era marked by rapid technological advancements and shifting consumer preferences, In an era marked by rapid technological advancements and shifting consumer preferences, In an era marked by rapid technological advancements and shifting consumer preferences, In an era marked by rapid technological advancements and shifting consumer preferences,</p>
                    <p className="greytxt"> <Link to="/DigitalBanking">READ MORE</Link></p>
                  </div>


                </div>
              </div>
              <div>
                <div className="row dive-cobalt align-items-center">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="col-bg">

                      <div className="box03 bg-coral-medium list-video">
                        <img src={cutting} className="card-img1" alt="Securing the Fort: Advanced Cybersecurity Measures in Banking" />


                      </div>

                    </div>
                  </div>


                  <div className="col-lg-6 col-md-6 col-12 align-items-center">

                    <h4>Securing the Fort: Advanced Cybersecurity Measures in Banking</h4>
                    <h6 className="greytxt"> February 15, 2024</h6>
                    <p>With banking operations increasingly reliant on digital platforms, protecting sensitive financial data from cyber threats has become a paramount concern for both financial institutions and their customers. In this article, we delve into the cutting-edge technologies and strategies employed by banks to fortify their defenses against cyberattacks.</p>
                    <p className="greytxt"> <Link to="/DigitalBanking">READ MORE</Link></p>
                  </div>
                </div>
              </div>
              <div>
                <div className="row dive-cobalt align-items-center">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="col-bg">

                      <div className="box03 bg-coral-medium list-video">
                        <img src={cloud} className="card-img1" alt="Embracing AI & Automation for Cloud Solutions: A Technological Imperative" />


                      </div>

                    </div>
                  </div>


                  <div className="col-lg-6 col-md-6 col-12 align-items-center">

                    <h4>Embracing AI & Automation for Cloud Solutions: A Technological Imperative</h4>
                    <h6 className="greytxt"> February 15, 2024</h6>
                    <p>In the vast expanse of technological evolution, the collaboration between Artificial Intelligence (AI) and automation has become a defining force, intricately weaving its influence into the very fabric of our digital existence. Beyond being mere buzzwords, AI and automation represent a convergence of innovation and efficiency that goes far beyond the surface, fundamentally altering the way we interact with technology and, consequently, transforming the societal landscape. This extended exploration delves deeper into the true nature of AI and automation, unraveling their profound impact on technology and the societal paradigm.</p>
                    <p className="greytxt"> <Link to="/solutions/automation-testing">READ MORE</Link></p>
                  </div>
                </div>
              </div>
              <div>
                <div className="row dive-cobalt align-items-center">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="col-bg">

                      <div className="box03 bg-coral-medium list-video">
                        <img src={banking} className="card-img1" alt="Exploring Cutting-Edge Technologies in 5G SA Device Testing" />


                      </div>

                    </div>
                  </div>


                  <div className="col-lg-6 col-md-6 col-12 align-items-center">

                    <h4>Exploring Cutting-Edge Technologies in 5G SA Device Testing</h4>
                    <h6 className="greytxt"> February 15, 2024</h6>
                    <p>In the fast-paced evolution of 5G technology, where progress is marked by innovation, staying at the forefront requires a proactive embrace of cutting-edge technologies. Today, we delve into the intricate world of 5G Standalone (5G SA) device testing, peeling back the layers to uncover the transformative impact of advanced technologies that are actively shaping the future of connectivity.</p>
                    <p className="greytxt"> <Link to="/solutions/5g/">READ MORE</Link></p>
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          {/* slicky slider end */}



        </section>


        {/* whats new section end */}



        {/* hear from you section end */}

      </main>

      <contact />
      <Footer />
    </div>
  )
}

export default Home
