import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import Book from './book';
import fea2 from './../../images/fea_2.png'
import fea4 from './../../images/fea_4.png'


import './../solutions/mobile.css'

import banking from './../../images/fea_3.png'
import cutting from './../../images/fea_1.png'
import mockupall from './../../images/mob-os.jpg'


import MobileExpert from './../solutions/mobexpert'
// apple ani end
function Mobile() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.out', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: "center center",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)
      .from('.image-box0 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box0",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)


      .from('.image-box1 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box1",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box2 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box2",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0)

      .from('.image-box3 img', {
        yPercent:25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box3",
          start: "top center",
          scrub:2,

        },   ease: 'power.inOut',
      }, 0);



  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });




  return (
    <>

<Helmet>
<title>Mobile Operating Systems | Mobile Device Management Solutions</title>
<meta name="description" content="we specialize in developing Software Development Kits (SDKs), Mobile Device Management (MDM) solutions, and Application Programming Interfaces (APIs), ensuring comprehensive mobile development options for our clients." />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Mobile Operating Systems | Mobile Device Management Solutions" />
	<meta property="og:description" content="we specialize in developing Software Development Kits (SDKs), Mobile Device Management (MDM) solutions, and Application Programming Interfaces (APIs), ensuring comprehensive mobile development options for our clients." />
	<meta property="og:url" content="https://www.procaltech.com/solutions/mobile-os/" />
	<meta property="og:site_name" content="Procal Technologies" />
  <link rel="canonical" href="https://www.procaltech.com/solutions/mobile-os/" />

            </Helmet>
      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div>  */}



              <div class="section-head__title flex-basis-666">
                <div class="image-box">
                  {/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
                </div>
                <h1 class="title-outline">
                Mobile 
                </h1>
          

                <h2 class="title-outline">
                OS
                </h2>
                <span class="title--outline green">
                OS
                </span>
                <span class="title--outline green">
                OS
                </span>
                <span class="title--outline green">
                OS
                </span>
                <span class="title--outline green">
                OS
                </span>
                <span class="title--outline green">
                OS
                </span>
                <span class="title--outline green">
                OS
                </span>
              </div>
              <div className='sidetext'>
                <div class="image-box imgprop">
                  <img src={mockupall } className='img-fluid' />

                </div>
              </div>

            </section>
          </div>





        </div>


      </section>

      <section className="container-fluid iot-sec4 ptop  pb-5 erer best-text hero">
        <div class="container">

          <div class="row d-flex align-items-center">



            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5"></h2>
              
                <h1 class="display-5 lh-1 mb-3 star">Mobile OS</h1>
                <p>A mobile operating system (mobile OS) serves as the software platform that manages both the hardware and software resources of a mobile device, such as smartphones, tablets, smart watches, or other devices. It provides essential services like security, memory management, and multitasking, enabling applications to run smoothly on the device.</p>


    


              </div>
            </div>

            <div class="col-md-5 col-lg-5 offset-lg-2 image-box0">
              <img src={banking} className='img-fluid' />
            </div>
          </div>

          <div class="row d-flex align-items-center mt-30">
          <div class="col-md-7 col-lg-5 image-box1">    <img src={cutting} className='img-fluid' /></div>
          <div class="col-md-5 col-lg-5 offset-lg-2">
            {/* <h4>At Procal, <span className='bluefont'>we offer</span></h4> */}

            <h1 class="display-5 lh-1 mb-3 star">At Procal, We Offer</h1>
                <p>At Procal, we offer a groundbreaking development experience that encompasses exceptional connectivity speeds, extended battery life, and cutting-edge AI capabilities. Leveraging the strength and reliability of 5G networks, our developers can seamlessly integrate components without worrying about performance degradation. We specialize in developing Software Development Kits (SDKs), Mobile Device Management (MDM) solutions, and Application Programming Interfaces (APIs), ensuring comprehensive mobile development options for our clients.</p></div>
      
          </div>

          <div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
  <div class="txt-block right-column wwhite-color wow fadeInLeft" >



  <h1 class="display-5 lh-1 mb-3 star">Our Turnkey</h1>
    <p>Our  solutions prioritize delivering comfortable mobile experiences, which include robust methods for evaluating system performance and collecting diagnostic information. Procal conducts rigorous testing of modems and network equipment to ensure optimal performance across metrics such as signal strength, data speed, latency, and network compatibility. We pay meticulous attention to optimizing speed and connectivity between devices and cellular networks to ensure seamless internet connectivity.</p>



  </div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
  <img src={fea4} className='img-fluid' />
</div>
</div>

<div class="row d-flex align-items-center mt-30">
          <div class="col-md-7 col-lg-5 image-box3">    <img src={fea2} className='img-fluid' /></div>
          <div class="col-md-5 col-lg-5 offset-lg-2">
     
            <h1 class="display-5 lh-1 mb-3 star">When Designing</h1>
                <p>When designing customer solutions, Procal always considers mobile operating systems as a crucial component of our telecommunications solutions. Our forward-thinking approach has earned us a reputation for delivering holistic and innovative solutions that cater to our customers' present needs and budget constraints while accounting for future requirements, accessibility, scalability, and industry trends.</p>
                
                </div>
      
          </div>

        </div>
      </section>

      {/* whats new section */}
      <section className="container">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">


            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


          </div>


        </div>




      </section>



      <MobileExpert />



      <Book/>

      <Footer />
    </>
  )
}

export default Mobile
