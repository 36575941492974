import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import Book from './book';
import fea5 from './../../images/ai-robo2.jpg'
import fea6 from './../../images/abst-111-sml.jpg'
import abst2 from './../../images/abst-222.jpg'
import abst3 from './../../images/abst-333.jpg'
import abst4 from './../../images/abst-444.jpg'
import Slider from "react-slick";
import './../solutions/mobile.css'


import cutting from './../../images/ai-robo.jpg.png'

import indusrobo from './../../images/industrial-robotics-sml.jpg'
import mockupall from './../../images/robotics.jpg'


// apple ani end
function Robotics() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.out', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: "center center",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Industrial Robotic Automation Solutions | USA, UK, UAE" />
	<meta property="og:description" content="By harnessing the power of 5G, automation, artificial intelligence, and cutting-edge technologies, robotics plays a pivotal role in driving innovation, improving efficiency, and transforming industries" />
	<meta property="og:url" content="https://www.procaltech.com/solutions/robotics/" />
	<meta property="og:site_name" content="Procal Technologies" />
	<link rel="canonical" href="https://www.procaltech.com/solutions/robotics/" />


</Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}



<div class="section-head__title flex-basis-666">
<div class="image-box">
{/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
</div>



<h1 class="title-outline">
Robotics
</h1>
<span class="title--outline green">
Robotics
</span>
<span class="title--outline green">
Robotics
</span>
<span class="title--outline green">
Robotics
</span>
<span class="title--outline green">
Robotics
</span>
<span class="title--outline green">
Robotics
</span>
<span class="title--outline green">
Robotics
</span>
</div>
<div className='sidetext'>
<div class="image-box imgprop">
<img src={mockupall} className='img-fluid' />

</div>
</div>

</section>
</div>





</div>


</section>

<section className="container-fluid iot-sec4 ptop  pb-5 erer best-text">
<div class="container">

<div class="row d-flex align-items-center">



<div class="col-md-5 col-lg-5">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5"></h2>
<h4>Robotics Driving Innovation</h4>
<p>When it comes to Industrial 4.0, robotics driving innovation and rapidly advancing field, revolving around the design, development, and implementation of intelligent machines capable of performing tasks autonomously or in collaboration with humans. Robotics has emerged as a critical enabler across various sectors, from manufacturing and healthcare to logistics and beyond. By harnessing the power of 5G, automation, artificial intelligence, and cutting-edge technologies, robotics plays a pivotal role in driving innovation, improving efficiency, and transforming industries. With 5G, industrial robots can move freely within the network coverage of the whole factory and surrounding campus. Moreover, a 5G network can accommodate many different wireless devices connected to the same network with low latency and high bandwidth. As robots become more sophisticated, versatile, and capable, they are revolutionizing workflows, streamlining processes, enhancing safety, and augmenting human potential. The robotics industry represents the forefront of technological advancements, offering boundless opportunities for businesses to unlock new levels of productivity, competitiveness, and growth in the digital era.</p>





</div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box0">
<img src={fea5} className='img-fluid' />
</div>
</div>

<div class="row d-flex align-items-center mt-30">
<div class="col-md-7 col-lg-5 image-box1">    <img src={abst2} className='img-fluid' /></div>
<div class="col-md-5 col-lg-5 offset-lg-2">
<h4>Software   <span className='bluefont'>Solution </span></h4>
<p>Procal offers a wide range of software solution capabilities that harness cutting-edge robotic technologies. Our expertise encompasses various areas, including Robotic Process Automation (RPA), industrial robotics, AI and machine learning, vision systems and perception, and human-robot collaboration. Through RPA, we streamline operations and boost productivity by automating repetitive tasks. In the realm of industrial robotics, we excel in both collaborative and autonomous robots, successfully optimizing processes and enhancing safety across manufacturing, logistics, and warehouse environments.</p></div>

</div>

<div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >

<h4>Machine  <span className='bluefont'>Learning</span></h4>

<p>Our advanced robotics solutions leverage AI and machine learning, enabling robots to adapt, learn, and make intelligent decisions in complex and evolving environments. By integrating state-of-the-art vision systems and perception technologies, our robots possess the ability to sense and interpret their surroundings. This empowers them to navigate autonomously, identify objects, and seamlessly interact with the environment.</p>



</div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
<img src={fea6} className='img-fluid' />
</div>
</div>
<div class="row d-flex align-items-center mt-30">
<div class="col-md-7 col-lg-5 image-box1">    <img src={abst3} className='img-fluid' /></div>
<div class="col-md-5 col-lg-5 offset-lg-2">
<h4>Human <span className='bluefont'>Robot </span></h4>
<p>Procal's commitment to human-robot collaboration is exemplified in our expertise in developing solutions that harmoniously integrate human expertise with robotic capabilities. Through intuitive interfaces and robust safety measures, we unlock new levels of efficiency and productivity by leveraging the strengths of both humans and robots. Additionally, our utilization of Robot Operating Systems (ROS) and Computer-Aided Design (CAD) technologies ensures efficient programming, system integration, and simulation for seamless deployment of robotics solutions.</p></div>

</div>
<div class="row d-flex align-items-center mt-30">



<div class="col-md-5 col-lg-5">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >

<h4>Experience <span className='bluefont'>Innovation</span></h4>

<p>Experience the innovation, efficiency, and productivity that our comprehensive robotics capabilities bring to your business. Partner with Procal and unlock the transformative potential of robotics in your industry.</p>



</div>
</div>

<div class="col-md-5 col-lg-5 offset-lg-2 image-box2">
<img src={abst4} className='img-fluid' />
</div>
</div>


</div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">
<Slider {...settings2}>


<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={indusrobo} className="card-img1" alt="Robotics" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Robot Processing Automation (RPA)</h4>

<p>Procal specializes in Robotic Process Automation (RPA), automating repetitive and rule-based tasks using software robots. Our RPA solutions streamline operations, boost productivity, and optimize workflow efficiency. By freeing up human resources from mundane tasks, businesses can focus on high-value activities. With increased accuracy, time savings, and improved efficiency, our RPA expertise enables clients to achieve greater operational effectiveness and maximize their productivity potential.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Robotics" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Industrial Robotics</h4>

<p>Procal's proficiency spans industrial robotics, encompassing both collaborative robots (cobots) and autonomous robots. With expertise in deploying robotic systems across manufacturing, logistics, and warehouse environments, we revolutionize operations and drive business success. By optimizing processes and enhancing safety, our industrial robotics solutions unlock higher levels of productivity, flexibility, and cost-effectiveness. Clients benefit from streamlined operations, increased efficiency, and the ability to adapt to evolving industry demands, positioning them at the forefront of innovation and competitiveness.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Robotics" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>AI and Machine Learning</h4>

<p>Procal leverages artificial intelligence (AI) and machine learning algorithms to develop advanced robotics solutions. Our expertise empowers robots to adapt, learn, and make intelligent decisions in complex and evolving environments. By harnessing AI and machine learning, our robotics solutions handle sophisticated tasks, enhance performance, and drive innovation. This expertise enables our clients to achieve higher levels of automation, efficiency, and productivity, propelling them to stay ahead in a rapidly evolving technological landscape.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Robotics" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Vision Systems and Perception</h4>

<p>Procal excels in utilizing state-of-the-art vision systems and perception technologies, empowering robots to accurately sense and interpret their surroundings. This advanced capability enables robots to autonomously navigate, identify objects, and seamlessly interact with the environment. By integrating cutting-edge vision systems and perception, we enhance the safety, precision, and efficiency of robotic operations, providing our clients with a valuable asset that optimizes their processes and unlocks new levels of performance.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Robotics" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Human Robot Collaboration</h4>

<p>Procal excels in developing collaborative solutions that foster harmonious human-robot interaction. Our expertise lies in creating intuitive interfaces and implementing robust safety measures, seamlessly integrating human expertise with robotic capabilities. By leveraging our specialization in human-robot collaboration, businesses can harness the combined strengths of humans and robots, unlocking unprecedented efficiency and productivity. Our focus on intuitive interfaces and safety measures ensures a seamless integration that optimizes workflow and enables businesses to maximize the potential of their human-robot teams.</p>

</div>
</div>
</div>

</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}



<Book/>
<Footer />
</>
)
}

export default Robotics
