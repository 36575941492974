import React from 'react';
import Menu from './../../common/Menu';
import { Helmet } from "react-helmet";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import girl from './../../images/quality.png'
import Book from '././../solutions/book';
import fea2 from './../../images/expert-analysis.jpg'

import cloud from './../../images/cloud-solution.jpg'

import cutting from './../../images/skull.jpg'
import Slider from "react-slick";

import Footer from './../../common/Footer'
import './../fintech/finser.css'


// apple ani end
function Regulatory() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<title>Regulatory Response & Remediation Solutions| Procal Technologies</title>
<meta name="description" content="Through our comprehensive and tailored approach, we provide the expertise, clarity, and support needed to achieve swift and effective regulatory remediation." />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Regulatory Response & Remediation Solutions| Procal Technologies" />
	<meta property="og:description" content="Through our comprehensive and tailored approach, we provide the expertise, clarity, and support needed to achieve swift and effective regulatory remediation." />
	<meta property="og:url" content="https://www.procaltech.com/solutions/regulatory-remediation/" />
	<meta property="og:site_name" content="Procal Technologies" />
  <link rel="canonical" href="https://www.procaltech.com/solutions/regulatory-remediation/" />
</Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
Regulatory 
</h1>

<h2 class="title-outline">
Remediation
</h2>
<span class="title--outline green">
Remediation
</span>
<span class="title--outline green">
Remediation
</span>
<span class="title--outline green">
Remediation
</span>
<span class="title--outline green">
Remediation
</span>
<span class="title--outline green">
Remediation
</span>
<span class="title--outline green">
Remediation
</span>
</div>
</div>
<div className='col-lg-5'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={girl} className='img-fluid' />     
                </div>
              </div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center1">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Expert Analysis</h2>
                <p>Thorough Examination: Navigating compliance issues can be complex and time-
consuming. Our experienced team is here to assist by thoroughly analyzing any control
deficiencies or instances of non-compliance identified during examinations. We leverage
our deep expertise to pinpoint the exact problems that need to be addressed, allowing you
to focus on your core operations without the burden of deciphering compliance issues
yourself.</p>
<p>Comprehensive Review: We conduct a comprehensive review of your existing
compliance framework, scrutinizing policies, procedures, and controls. This meticulous
approach ensures that all potential areas of non-compliance are identified and addressed,
leaving no stone unturned.</p>

<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Clear Action Plan</h2>
<ul className='serlist'>
<li>Detailed Roadmap: We provide more than just analysis; we develop a clear, actionable
plan to bring you back into compliance with FFIEC regulations. This plan outlines
specific steps and measures needed to address the identified issues, ensuring you know
exactly what needs to be done to achieve compliance.</li>
<li>Customized Solutions: Our action plan is tailored to your organization’s unique needs
and circumstances. We take into account your specific operational context, regulatory
requirements, and business objectives to create a bespoke remediation strategy.</li>
</ul>

<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Swift Resolution</h2>
<ul className='serlist'>
<li>Urgent Compliance Restoration: Recognizing the urgency of regulatory compliance,
we guide you through a streamlined implementation process designed to resolve issues
quickly and efficiently. Our approach minimizes disruption to your operations, helping
you swiftly get back on track and maintain compliance with regulatory standards.</li>
<li>Proactive Support: We provide ongoing support throughout the remediation process,
ensuring that any emerging issues are promptly addressed. Our proactive approach helps
prevent future non-compliance and keeps your organization ahead of regulatory changes.</li>
</ul>

<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Streamlined Implementation</h2>
<ul className='serlist'>
<li>Efficient Execution: To minimize disruption and expedite the remediation process, we
guide you through the implementation phase. We ensure all corrective actions are
executed efficiently and effectively, leveraging best practices and proven methodologies.</li>
<li>Continuous Improvement: Beyond immediate remediation, we focus on establishing a
culture of continuous improvement within your organization. This includes regular
compliance reviews, training programs, and updates to policies and procedures to ensure
sustained compliance.</li>
</ul>


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5 mt-5">Achieve and Maintain Compliance</h2>
<ul className='serlist'>
<li>Avoid Penalties: Avoid penalties and regain peace of mind with our proven regulatory
remediation services. Our expert guidance helps you navigate complex regulatory
landscapes and achieve full compliance with FFIEC regulations.</li>
<li>Long-Term Resilience: We don&#39;t just help you get back on track quickly and efficiently;
we also empower your organization to maintain long-term resilience. By building robust
compliance frameworks and fostering a proactive compliance culture, we ensure that
your organization is well-prepared for future regulatory challenges.</li>
</ul>




              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className='img-fluid'  />
            </div>

      
          </div>
        </div>
      </section>
<section className="container-rom container-fin ali-fxl">
<div className="container-d1s">
<div className="container-rom container-2e2">
<div className="container-d1s">
<div className="wrapper-ti2">
<div className="column-o8o column-fin">
<section className="container-rom container-coh ani-lcm fa-6jh">
<div className="container-d1s" style={{ backgroundColor:'#1b1b42',padding:'28px',borderRadius:'25px',color:'#fff' }}>
<h3 className="gb-headline-6nz white-color  ">Through our comprehensive and tailored approach, we provide the expertise, clarity, and support
needed to achieve swift and effective regulatory remediation. Let us help you safeguard your
organization’s integrity and ensure ongoing compliance with regulatory standards.</h3>


</div>
</section>
</div>
</div>
</div>
</div>
</div>
</section>







{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Regulartory Remediation" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Precise Diagnostics </h4>

<p> Procal's expert team conducts a meticulous examination of your compliance framework. We analyze control deficiencies and non-compliance instances, leveraging our deep expertise to pinpoint exact issues. Our comprehensive review scrutinizes policies, procedures, and controls, ensuring no potential area of non-compliance is overlooked. </p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Regulartory Remediation" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Tailored Strategies </h4>

<p> We develop customized action plans that address your unique compliance challenges. Our detailed roadmap outlines specific steps to restore compliance with FFIEC regulations, taking into account your operational context and business objectives. This bespoke approach ensures efficient and effective remediation tailored to your organization's needs. </p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Regulartory Remediation" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Swift Implementation</h4>

<p>Our team guides you through a streamlined implementation process, minimizing operational disruption while swiftly resolving compliance issues. We provide proactive support throughout the remediation journey, addressing emerging issues promptly. Our focus extends beyond immediate fixes to fostering a culture of continuous improvement within your organization. </p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Regulartory Remediation" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Long-Term  Resilience </h4>

<p> Procal's services go beyond avoiding penalties; we empower your organization to maintain long-term regulatory resilience. By establishing robust compliance frameworks and nurturing a proactive compliance culture, we prepare you for future regulatory challenges. Our approach ensures ongoing compliance, safeguarding your organization's integrity in an ever-evolving regulatory landscape.</p>

</div>
</div>
</div>





</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


<Book/>

<Footer />
</>
)
}

export default Regulatory
