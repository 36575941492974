import React from 'react';
import Menu from '../../common/Menu';
import girl from './../../images/comcasts.jpg'
import Footer from '../../common/Footer'
import './../campaign/camp.css'

function Campaign () {


  
return (
  <>
  <Menu/>

  <>
  {/* hero22 section */}
  <section className="hero22 d-flex flex-column justify-content-center">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h1>Streamlined Financial Management</h1>
          <p>
          Our dedicated Account Office team is here to streamline your financial operations, ensuring precision and efficiency in managing your accounts. Whether you're looking for support in daily financial tracking, audit preparation, or reconciliation, we bring expertise to ensure your processes are seamless and compliant.
          </p>
          <a href="index.html" className="btn1">
            Know More
          </a>
        </div>
      </div>
    </div>
  </section>



  <main>
  <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light">
    <div className="col-md-9 p-lg-10 mx-auto my-5">
      <h1 className="display-4 fw-normal">Efficient Account Operations</h1>
      <p className="lead fw-normal">
      With our tailored solutions, we help you focus on growth while we handle the intricate details of financial management. Our professionals are committed to delivering results that enhance your business’s profitability and operational efficiency.
      </p>
      <a className="btn btn-danger" href="#">
        Know More
      </a>
    </div>
    <div className="product-device shadow-sm d-none d-md-block" />
    <div className="product-device product-device-2 shadow-sm d-none d-md-block" />
  </div>
 
</main>


<section className='pt-5'>

<div>
  <div className="contact-form-wrapper d-flex justify-content-center">
    <form action="#" className="contact-form">
      <h2 className="title-contact text-center">Contact us</h2>
      <p className="description">
        Feel free to contact us if you need any assistance, any help or another
        question.
      </p>
      <div>
        <input
          type="text"
          className="form-control rounded border-white mb-3 form-input"
          id="name"
          placeholder="Name"
          required=""
        />
      </div>
      <div>
        <input
          type="email"
          className="form-control rounded border-white mb-3 form-input"
          placeholder="Email"
          required=""
        />
      </div>
      <div>
        <textarea
          id="message"
          className="form-control rounded border-white mb-3 form-text-area"
          rows={5}
          cols={30}
          placeholder="Message"
          required=""
          defaultValue={""}
        />
      </div>
      <div className="submit-button-wrapper">
        <input type="submit" defaultValue="Send" />
      </div>
    </form>
  </div>
</div>

</section>

</>

  <Footer/>


  </>
)
}

export default Campaign
