import React from 'react';
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import './snap.css';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Footer from '../common/Footer';
import Menu from '../common/Menu';

import { Helmet } from 'react-helmet';


function Artemis() {


  // Ref for the image container
  const bgImageRef = useRef(null);
  const secImageRef = useRef(null);
  // On component mount
  useEffect(() => {
    // Register ScrollTrigger plugin
    gsap.registerPlugin(ScrollTrigger);

    // Get the image container
    const bgImage = bgImageRef.current;
    const secimg = secImageRef.current;
    // Animation using GSAP
    gsap.fromTo(
      bgImage,
      {
        clipPath: "circle(5% at 77% 40%)",
      },
      {
        clipPath: "circle(75% at 50% 50%)",
        ease: "none",
        scrollTrigger: {
          trigger: bgImage,
          scrub: 1,
          start: "top center",
          end: "top center-=200",
        },
      }
    );
    gsap.fromTo(
      secimg,
      {
        clipPath: "circle(5% at 77% 40%)",
      },
      {
        clipPath: "circle(75% at 50% 50%)",
        ease: "none",
        scrollTrigger: {
          trigger: secimg,
          scrub: 1,
          start: "top center",
          end: "top center-=200",
        },
      }
    );
    // Clean up function
    return () => {
      // Remove ScrollTrigger from GSAP to avoid memory leaks
      ScrollTrigger.getAll().forEach(trigger => {
        trigger.kill();
      });
    };
  }, []); // Empty dependency array ensures the effect runs only once on component mount


  return (
    <>
      <Helmet>
      <title>Artemis: Seamless XR Experiences on 5G Networks</title>
      <meta name="description" content="Artemis is Procal's cutting-edge XR Network Performance Diagnostics solution, specifically designed for Extended Reality (XR) applications operating over 5G networks. This innovative platform offers real-time monitoring and comprehensive performance insights, empowering stakeholders to proactively optimize network and device performance, resulting in enhanced user experiences and unparalleled XR application reliability." />

      <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Artemis: Seamless XR Experiences on 5G Networks | Procal" />
        <meta property="og:description" content="Artemis is Procal's cutting-edge XR Network Performance Diagnostics solution, specifically designed for Extended Reality (XR) applications operating over 5G networks. This innovative platform offers real-time monitoring and comprehensive performance insights, empowering stakeholders to proactively optimize network and device performance, resulting in enhanced user experiences and unparalleled XR application reliability." />
        <meta property="og:url" content="https://www.procaltech.com/products/artemis/" />
        <link rel="canonical" href="https://www.procaltech.com/products/artemis/" />
        <meta property="og:site_name" content="Procal Technologies" />
    </Helmet>
      <Menu />
      <div className="img-container" ref={bgImageRef}>
        <div id="watch">
          <div id="watch_text"><span class="section_title">Artemis: Seamless XR Experiences on 5G Networks</span>
            <p class="lead 1sub_text mt-3">Artemis is Procal's cutting-edge XR Network Performance Diagnostics solution, specifically designed for Extended Reality (XR) applications operating over 5G networks. This innovative platform offers real-time monitoring and comprehensive performance insights, empowering stakeholders to proactively optimize network and device performance, resulting in enhanced user experiences and unparalleled XR application reliability.</p>
            {/* <img src="stickers.png" width="300px" /> */}
          </div>
        </div>
      </div>
      {/* <div id="create">
       <div id="create_text"><span class="section_title">Artemis: Seamless XR Experiences on 5G Networks</span>
  
    </div>





</div> */}


     

      <section className='px-4 art-key py-5 pb100'>
      <div className='secpattern'>

      <div className='container-sanp'>
            <div className='flex-container-snap1 p-5 text-center text-white'>

              <div><h2>Key Features</h2>
           

              </div>

            </div>

          </div>
          <div className='container-sanp' >

            <div class="cards-sap ">
              <span>
                <div class="snap-card red">
                  <p class="tip mt-3">Real-time Performance Monitoring</p>
                  <p>Artemis offers a state-of-the-art real-time monitoring system that collects, processes, and analyzes critical data to assess XR application performance over 5G networks, ensuring continuous and proactive insights.</p>
                </div>
                <div class="snap-card blue">
                  <p class="tip mt-3">Comprehensive KPI Tracking</p>
                  <p>The platform tracks a wide range of network and device related KPIs, providing stakeholders with comprehensive performance data.</p>
                </div>
                <div class="snap-card green">
                  <p class="tip mt-3">Instant Alerts and Notifications</p>
                  <p>Artemis's alerting mechanism promptly notifies relevant teams of performance degradation or anomalies, enabling them to take immediate actions and ensure uninterrupted XR experiences.</p>
                </div>

                <div class="snap-card red">
                  <p class="tip mt-3">User-friendly Web Portal</p>
                  <p>Artemis features a user-friendly web-based portal, giving stakeholders easy access to performance insights and KPI data, empowering data-driven decision making for network upgrades and optimizations.</p>
                </div>
                <div class="snap-card blue">
                  <p class="tip mt-3">Dedicated Android Application</p>
                  <p>Procal's dedicated Android application allows for on-device monitoring of XR application performance, offering real-time insights for improved user experiences.</p>
                </div>
                <div class="snap-card green">
                  <p class="tip mt-3">Low Latency and Low Footprint </p>
                  <p>Artemis prioritizes low latency in data transmission and is designed with a small memory and processing footprint on XR devices, ensuring optimal XR application performance without compromising device resources.</p>
                </div>
              </span>
            </div>

          </div>
        </div>
      </section>

      <section className='px-4 spot-sec9 py-5 pb100 vh100'>
        <div className='container-sanp'>
          <div className='flex-container-snap1 p-5 text-center1 text-white'>

            <div className='width34'><h2 className='bigfont'>Seamless XR Experiences</h2>
              <h5 class="mt-5 linheight25">Artemis ensures smooth and immersive XR experiences by detecting and addressing potential performance issues in real-time, leading to reduced latency and enhanced end-user satisfaction.</h5>

            </div>

          </div>

        </div>

      </section>

      <section className='px-4 spot-sec10 py-5 pb100 vh100'>
        <div className='container-sanp'>
          <div className='flex-container-snap1 p-5 text-center1 text-white'>

            <div className='width34'><h2 className='bigfont'>Proactive Issue Resolution</h2>
              <h5 class="mt-5 linheight25">With its integrated monitoring and alerting mechanism, Artemis empowers stakeholders to resolve network and device-related issues proactively, minimizing downtime and service disruptions.</h5>

            </div>

          </div>

        </div>

      </section>
      <section className='px-4 spot-sec11 py-5 pb100 vh100'>
        <div className='container-sanp'>
          <div className='flex-container-snap1 p-5 text-center1 text-white'>

            <div className='width34'><h2 className='bigfont'>Optimized Network Utilization</h2>
              <h5 class="mt-5 linheight25">By tracking essential network KPIs, Artemis ensures efficient utilization of 5G networks, optimizing data transmission and minimizing packet loss, resulting in enhanced application performance.</h5>

            </div>

          </div>

        </div>

      </section>
      <section className='px-4 spot-sec12 py-5 pb100 vh100'>
        <div className='container-sanp'>
          <div className='flex-container-snap1 p-5 text-center1 text-white'>

            <div className='width34'><h2 className='bigfont'>Improved Device Performance</h2>
              <h5 class="mt-5 linheight25">Artemis monitors critical device KPIs, helping identify potential hardware or software issues, leading to better device performance and extended device longevity.</h5>

            </div>

          </div>

        </div>

      </section>
      {/* <Contact /> */}
      <Footer />
    </>
  )
}

export default Artemis
