import React from 'react'
import WorldMap from "react-svg-worldmap";
import  { CountryContext } from "react-svg-worldmap";
import './../subpages/map.css'

import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// import { SplitText } from 'gsap/SplitText';
// import { ScrollSmoother } from 'gsap/ScrollSmoother';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

function Map() {


  const data = [
    { country: "de", value: 'Germany'  }, // germany
    { country: "tr", value: 'Turkey'  }, // Turkey
    { country: "ie", value: 'Ireland'  }, // Ireland
    { country: "in", value: 'india' }, // india
    { country: "ae", value: 'UAE' }, // Uae
    { country: "us", value: 'united state' }, // united states

  ];


  return (
    <>
   {/* <section className='map'>

    <div className='container-fluid'>

      <div className='row'>
        <div className='col-12 col-lg-6 col-sm-2'>
        

        <WorldMap
        color="GREEN"
        // title="Our Location"
        value-suffix="people"
        size="xlg"
        data={data}
      />
        </div>
<div className='col-12 col-lg-12'>

<div className='maptext'><h2>Our Location</h2></div>
</div>

      </div>
    </div>

   </section> */}


        <section>
        <div id="main" class="container loading">
  <div class="art-board d-lg-block d-none" >
    {/* <div class="asset asset1"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698865081/floating/Asset1.png" alt="" /></div>
    <div class="asset asset2"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698865081/floating/Asset2.png" alt="" /></div>
    <div class="asset asset4"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset4.png" alt="" /></div> */}
    {/* <div class="asset asset5"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset5.png" alt="" /></div> */}
    <div class="asset asset6"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset6.png" alt="" /></div>
    {/* <div class="asset asset7"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset7.png" alt="" /></div> */}
    <div class="asset asset8"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset8.png" alt="" /></div>
    <div class="asset asset9"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset9.png" alt="" /></div>
    <div class="asset asset10"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698874357/floating/Asset10.png" alt="" /></div>
    {/* <div class="asset asset11"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698874658/floating/Asset11.png" alt="" /></div> */}
    <div class="asset asset12"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset12.png" alt="" /></div>
    <div class="asset asset13"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset13.png" alt="" /></div>
    <div className='hero-head'>
    <h1 class="title ctrl-break fontweight600">DISCOVER<br/><span className='fontsize30'> THE PROCAL DIFFERENCE</span></h1>
<p className='titlepara'><span>Procal’s dedicated team of SME’s offer a diverse range of cutting-edge solutions tailored to meet your unique requirements. Our areas of expertise span over telecom, emerging tech, automation, and data insights.</span></p>
</div>
    {/* <div class="asset asset3"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698870354/floating/Asset3.png" alt="" /></div> */}
    <div class="asset asset14"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698875726/floating/Asset14.png" alt="" /></div>
    <div class="asset asset15"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698875916/floating/Asset15.png" alt="" /></div>
    <div class="asset asset16"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698876068/floating/Asset16.png" alt="" /></div>
    <div class="asset asset17"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698876356/floating/Asset17.png" alt="" /></div>
    <div class="asset asset18"><img src="https://res.cloudinary.com/jasuaje/image/upload/v1698876424/floating/Asset18.png" alt="" /></div>

  </div>

<div className='mobheader d-block d-sm-none'>
  <div className='hero-head'>
    <h1 class="title ctrl-break fontweight600">DISCOVER<br/><span className='fontsize30'> THE PROCAL DIFFERENCE</span></h1>
<p className='titlepara'><span>Procal’s dedicated team of SME’s offer a diverse range of cutting-edge solutions tailored to meet your unique requirements. Our areas of expertise span over telecom, emerging tech, automation, and data insights.</span></p>
</div>
</div>
</div>
        </section>
    </>
  )
}

export default Map
