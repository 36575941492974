import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Book from './book';

import Footer from './../../common/Footer'
import girl from './../../images/indus-3.jpg'
import car from './../../images/chimney.svg'
import mobile from './../../images/resource-augmentation.jpg'
import fea2 from './../../images/industries-center.jpg'
import bricks from './../../images/indus-2.jpg'
import dell from './../../images/dell-logo.png'
import salesforce from './../../images/salesforce-logo.png'
import googlecloud from './../../images/google-platform-logo.png'
import ibm from './../../images/ibm-cloud.png'
import aws from './../../images/aws-logo.png'
import pralex1 from './../../images/indus-pralex1.jpg'
import Slider from "react-slick";
import './../solutions/robot-processing.css'
import './../solutions/ourindustries.css'

import { useLocation } from 'react-router-dom';
import telecomtower from './../../images/building-light.jpg'
import cloud from './../../images/cloud-solution.jpg'
import healthcare from './../../images/healthcare.jpg'

import govt from './../../images/suitcase.jpg'
import supply from './../../images/cosumer-product.jpg'
import retail from './../../images/life-sc.jpg'

import { Helmet } from 'react-helmet';

// apple ani end
function OurIndustries() {



  // anchor page code start
  
  
  
  
  
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);
  // anchor page code end
const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="What We Do | Capabilities, Industries, and Partners | Procal" />
	<meta property="og:description" content="Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services." />
	<meta property="og:url" content="https://www.procaltech.com/ourindustries" />
  <link rel="canonical" href="https://www.procaltech.com/ourindustries" />
	<meta property="og:site_name" content="Procal Technologies" />
</Helmet>
<Menu />


<div className="img-container outline-box" >
<div className='overlayer'></div>
<div id="industries">
<div className='headingtop white'>

<h1 className='whiteheading'>Our Industries</h1>
<p className='text-white lead'>Our subject matter experts possess extensive industry expertise <br />across diverse sectors, allowing us to cater to a wide variety of clients needs.</p>
</div>
</div>
</div>


{/* 
<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">



<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
Our
</h1>

<h1 class="title-outline">
Industries
</h1>
<span class="title--outline green">
Industries
</span>
<span class="title--outline green">
Industries
</span>
<span class="title--outline green">
Industries
</span>
<span class="title--outline green">
Industries
</span>
<span class="title--outline green">
Industries
</span>
<span class="title--outline green">
Industries
</span>
</div>
</div>
<div className='col-lg-5'>
<div className='sidetext'>
<div class="image-box">
<img src={girl} className='img-fluid' />     
</div>
</div></div>


</section>
</div>





</div>


</section> */}

<section className="container-fludi iot-sec4 indus-sub pt50 pb-5 erer">
<div class="container">

<div class="row d-flex align-items-center1">


<div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
<img
ref={imageRef}
src={cloud}
alt="Your Image"
className="img-fluid"
/>
</div>

<div class="col-md-8 col-lg-8">
<div class="txt-block right-column wwhite-color wow fadeInLeft" >
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Our Industries</h2>
<p > Procal empowers various industries, such as telecommunications, government, healthcare, manufacturing, and retail, through tailored solutions and services. We enable growth and efficiency by providing innovative technology solutions, operational optimization, and exceptional customer experiences. With expertise spanning over multiple sectors, Procal drives advancements, fosters innovation, and ensures seamless integration for its clients in dynamic and competitive landscapes.</p>




</div>

<div class="row">
<div class="col-md-4 col-lg-4 cborder color_1">
<div class="image-box">
<img src={car} className='img-fluid' />
</div>
</div>
<div class="col-md-8 col-lg-8 cborder color">

<p >Procal is a leading provider of specialized solutions for companies in the retail and consumer goods sector, catering to their distinct requirements and industry-specific demands. Our expertise spans over digital transformation, operational optimization, and customer experience enhancement. We collaborate closely with retail and consumer goods organizations to deliver solutions such as e-commerce platform development, inventory management systems, and customer analytics. Procal helps clients transform their digital presence, streamline operations, and deliver personalized customer experiences. By leveraging our innovative solutions, retailers and consumer goods companies can drive growth, improve operational efficiency, and stay ahead in the competitive marketplace.</p>
</div>

</div>
</div>

<div class="col-md-4 col-lg-4 image-box">
<img src={fea2} className='img-fluid' alt='operation efficience' />
</div>

<div class="col-md-12 col-lg-12">



</div>
</div>
</div>
</section>

<section className="container-indus">

<div class="">
<div class="pt-5">

<div class="col-md-10 col-lg-10 offset-lg-1 ">
<div class="row">
<div class="col-md-12 col-lg-12">
<div class="row">
<div class="col-md-4 col-lg-4 cborder color_1 order-lg-1 order-2">
<div class="image-box">
<img src={bricks} className='img-fluid ' />
</div>
</div>
<div class="col-md-8 col-lg-8 col-12 cborder color order-lg-2 order-1 mobpl">
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 ">Operational efficiency</h2>
<p>Procal is a premier provider of tailored solutions for the retail and consumer goods sectors, addressing their unique needs and industry challenges. Our expertise includes digital transformation, operational efficiency, and enhancing customer experiences. We partner with retail and consumer goods organizations to offer services such as e-commerce platform development, inventory management systems, and customer analytics. Procal empowers clients to enhance their digital presence, optimize operations, and create personalized customer interactions. By utilizing our innovative solutions, retailers and consumer goods companies can fuel growth, boost efficiency, and maintain a competitive edge in the market.</p>
</div>

<div class="col-md-8 col-lg-8 col-12 cborder color_3 mobpl">
<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 ">Drives their Success</h2>
<p>At Procal, we understand the importance of flexibility when it comes to engaging with our clients. Recognizing that each organization has unique needs and goals, we take a tailored approach in delivering solutions that are specifically designed to address their challenges. We offer a variety of impactful engagement models, ensuring that our clients receive the right level of support and services that align with their requirements. Whether it's through full scale product development, impactful resource augmentation, or deploying our own proprietary products, our goal is to provide the most effective and customized approach that drives their success.</p>



</div>

<div class="col-md-4 col-lg-4 cborder color_2">
<div class="image-box">
<img src={girl} className='img-fluid' />
</div>
</div>

</div>
</div>
<div className='clearfix'></div>

</div>
</div>
<div class="col-md-10 col-lg-10 offset-lg-1 pb-5">


<div class="col-md-12 col-lg-12 ">
<div class="row">
<div class="col-md-4 col-lg-4 cborder color_2 col-12  order-lg-1 order-2">
<div class="1image-box">
<img src={mobile} className='1img-fluid' />
</div>
</div>
<div class="col-md-6 col-lg-6 offset-lg-1 col-12 cborder color_3 pb-5 order-lg-2 order-1 mobpl">
<p className='mt-3'><span className='titleN'>Resource Augmentation</span><br />
Procal is a leading provider of specialized staff augmentation services. With a proven track record of delivering highly niche skilled professionals to augment existing teams, we leverage our network of professionals to drive innovation and success.</p>
<p ><span className='titleN'>End to End Product Development</span><br />
Procal offers end to end comprehensive product development, leveraging our own internal resources and processes. After aligning with the client on scope and deliverables, we independently manage projects/products to deliver quality results based on customer needs.</p>
<p className='pb-5 mb-5 mt-3'><span className='titleN'>Proprietary Products</span><br />
Procal offers a suite of cutting-edge proprietary products, exclusively licensed to clients, enabling them to unlock powerful solutions. Our innovative suite includes Orion, a network/device testing and optimization tool, and Snap, a remote mobile testing tool that aids in automated device testing needs. Our built in API’s seamlessly integrate with popular tools like Appium.</p>


</div>


</div>
</div>



</div>



</div>
</div>


</section>

<section className='pt100'>


  {/* Section 2: Life Sciences */}
  <div id='life'></div>
  <section className="section-2 parallax">
    <div className="container-7">
		<div className='row'>
			<div className='col-lg-5 offset-lg-1'>
      <h2>Life Sciences</h2>
      <p>The life sciences sector, including pharmaceuticals, biotechnology, and medical devices, faces unique challenges such as regulatory compliance, data integrity, and the need for advanced technology integration. Procal Technologies  provides tailored solutions to help life sciences companies strengthen their regulatory posture, ensure operational resilience, and enhance product lifecycle management through automation and modernization. Our focus on cybersecurity ensures protection of sensitive clinical data and intellectual property, enabling clients to innovate confidently while adhering to stringent industry standards.

</p>
</div>
</div>
    </div>
  </section>
  {/* Section 1: Banking and Financial Services */}
  <div id='banking'></div>
  <section className="section-1 parallax">
    <div className="container-7">
	<div className='row'>
	<div className='col-lg-6 offset-lg-6'>
      <h2>Banking and Financial Services</h2>
      <p>The banking and financial services industry is experiencing rapid transformation driven by digital innovation, evolving customer expectations, and stringent regulatory requirements. At Procal Technologies, we partner with financial institutions to deliver robust cybersecurity solutions, regulatory remediation, and operational resilience strategies. Our expertise in technology modernization and automation helps banks and financial firms streamline operations, enhance customer experiences, and ensure compliance with ever-changing regulations. From FFIEC Cybersecurity Assessment to controls design and implementation, we empower our clients to thrive in a fast-paced, highly-regulated environment.</p>

    </div>
	</div>
	</div>
  </section>
  {/* Section 4: Industrial */}
  <div id='industrial'></div>
  <section className="section-4 parallax">
    <div className="container-7">
	<div className='row'>
	<div className='col-lg-5 offset-lg-1'>
      <h2>Industrial</h2>
      <p>The industrial sector is embracing Industry 4.0, driven by automation, data analytics, and IoT, while also contending with supply chain vulnerabilities, regulatory scrutiny, and cybersecurity threats. Procal Technologies helps industrial firms modernize their technology landscapes, strengthen operational resilience, and optimize controls design and implementation. Our cybersecurity solutions protect connected infrastructure from threats, while our automation expertise helps companies improve operational efficiency, safety, and compliance. We partner with industrial leaders to ensure they are equipped to navigate today’s challenges and embrace the future of smart manufacturing.</p>
	  </div>
	  </div>
    </div>
  </section>
  {/* Section 3: Telecom and Media */}
  <div id='telecom'></div>
  <section className="section-3 parallax">
    <div className="container-7">
	<div className='row'>
	<div className='col-lg-6 offset-lg-6'>
      <h2>Telecom and Media</h2>
      <p>Telecom and media companies are at the forefront of a connected world, but they face increasing pressure from digital disruption, customer demands, and cybersecurity threats. Procal Technologies delivers cutting-edge solutions for technology modernization and operational efficiency that help businesses stay competitive. Our cybersecurity frameworks safeguard against evolving threats, while our automation and regulatory remediation services ensure operational excellence. We empower telecom and media companies to manage digital transformation with confidence, offering innovative tools that keep operations secure, scalable, and compliant.</p>
	  </div>
	  </div>
    </div>
  </section>

  <div id='consumer'></div>
    {/* Section 6: Insurance */}
    <section className="section-6 parallax">
    <div className="container-7">
	<div className='row'>
	<div className='col-lg-5 offset-lg-1'>
      <h2>Insurance</h2>
      <p>Insurance companies are facing increased regulatory scrutiny, changing customer expectations, and the need for digital transformation. Procal Technologies helps insurance firms tackle these challenges by providing regulatory remediation, operational resilience strategies, and cutting-edge technology modernization. Our cybersecurity solutions protect critical customer and business data, while our automation services help streamline underwriting, claims processing, and risk management. We empower insurance companies to deliver secure, compliant, and efficient services in an ever-evolving industry landscape.</p>
   
	  </div>
	</div>
    </div>
  </section>
  {/* Section 5: Consumer Tech */}
  <section className="section-5 parallax">
    <div className="container-7">
	<div className='row'>
	<div className='col-lg-6 offset-lg-6'>
      <h2>Consumer Tech</h2>
      <p>The consumer tech industry is at the epicenter of innovation, with companies striving to create cutting-edge products while navigating fast-evolving market trends and cybersecurity risks. Procal Technologies provides end-to-end solutions that help consumer tech companies enhance their cybersecurity posture, streamline operations through automation, and ensure compliance with regulatory requirements. From safeguarding intellectual property to optimizing product development cycles, we enable consumer tech firms to accelerate innovation securely and efficiently.</p>

    </div>
	</div>
	</div>
  </section>
  <div id='insurance'></div>

  <div id='software'></div>
  {/* Section 7: Software and High-Tech */}
  <section className="section-7 parallax">
    <div className="container-7">
	<div className='row'>
	<div className='col-lg-5 offset-lg-1'>
      <h2>Software and High-Tech</h2>
      <p>The software and high-tech industry is a hotbed of innovation, constantly pushing boundaries to create the next generation of digital solutions. Procal Technologies partners with software and tech companies to deliver robust cybersecurity frameworks, accelerate technology modernization, and design automated, scalable processes. Whether you're developing AI solutions, cloud services, or digital platforms, our expertise in operational resilience and controls implementation ensures that your business remains secure, agile, and compliant in the face of rapid technological advancement.</p>
    
    </div>
	</div>
	</div>
  </section>

  </section>

{/* whats new section */}
<a name="expertise"></a>
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>



{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={telecomtower} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Telecommunications</h4>

<p>Procal offers a comprehensive range of tailored services and solutions designed to support telecommunication service providers, network operators, and related organizations. Our expertise spans over network design, deployment, and optimization, infrastructure management, software development, cybersecurity, and technical support. With Procal's assistance, clients can enhance network performance, expand coverage, improve service quality, implement cutting-edge technologies, and ensure seamless connectivity for their customers. We also provide strategic consulting, regulatory compliance guidance, and innovative solutions to help telecommunication companies navigate the dynamic industry landscape. Procal is dedicated to enabling reliable and efficient communication services, fostering innovation, and driving the growth and advancement of the telecommunications sector.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={govt} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Government</h4>

<p>Procal collaborates with government agencies at all levels to drive digital transformation, improve operational efficiency, and enhance citizen services. Our technology consulting and solutions empower governments to streamline processes, implement robust cybersecurity measures, leverage data analytics for evidence-based decision-making, and develop citizen-centric platforms. From digital government services to smart city initiatives, Procal's expertise helps government entities navigate complex challenges, ensure data privacy and security, and deliver effective and transparent services to their constituents.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={healthcare} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Healthcare and Life Sciences</h4>

<p>Procal is at the forefront of exceptional services in the healthcare and life sciences sector. Our company offers a comprehensive suite of specialized solutions designed to meet the unique needs of healthcare providers, pharmaceutical companies, research institutions, and other industry players. We excel in delivering innovative technology solutions from healthcare analytics to telehealth platforms. With Procal's expertise, clients can enhance patient care, optimize clinical processes, accelerate research and development, and navigate the complex landscape of the healthcare industry. We are committed to driving advancements in healthcare, empowering organizations to achieve their strategic objectives, improve operational efficiency, and deliver exceptional outcomes to patients and stakeholders.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={supply} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Manufacturing and Supply Chain</h4>

<p>Procal is a trusted provider of comprehensive services tailored to meet the specific needs of the manufacturing and supply chain sector. Our expertise and solutions are designed to optimize operations, enhance efficiency, and drive supply chain excellence. We work closely with manufacturers and supply chain organizations to offer a wide range of services, including process automation, supply chain visibility, inventory management, demand forecasting, and supplier collaboration tools. Through our innovative approaches and industry insights, Procal helps clients streamline processes, reduce costs, improve productivity, and ensure seamless integration across their manufacturing and supply chain operations. With Procal's partnership, organizations gain a competitive edge, adapt to evolving market demands, and achieve sustainable growth in the dynamic manufacturing and supply chain landscape.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={retail} className="card-img1" alt="..." />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Retail and Consumer Goods</h4>

<p>Procal is a leading provider of specialized solutions for companies in the retail and consumer goods sector, catering to their distinct requirements and industry-specific demands. Our expertise spans over digital transformation, operational optimization, and customer experience enhancement. We collaborate closely with retail and consumer goods organizations to deliver solutions such as e-commerce platform development, inventory management systems, and customer analytics. Procal helps clients transform their digital presence, streamline operations, and deliver personalized customer experiences. By leveraging our innovative solutions, retailers and consumer goods companies can drive growth, improve operational efficiency, and stay ahead in the competitive marketplace.</p>

</div>
</div>
</div>



</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}


{/* our clint section start */}

<section class="features3 cid-qKT6knwV2G  sectionZ text-center py-5" id="clients2-2p">




<div class="container mb-4">
<div class="media-container-row">
<div class="col-12 align-center">
<h2 class="mbr-section-title pb-2 mbr-fonts-style display-2">
Our Partnerships
</h2>
<h3 class="mbr-section-subtitle mbr-light mbr-fonts-style display-7">
Discover our prestigious network of industry-leading partners, empowering Procal to deliver exceptional solutions and services through collaborative innovation and cutting-edge technologies.
</h3>
</div>
</div>
</div>
<div class="container">
<div class="row align-center justify-content-center">


<div class="p-3 col-12 col-md-6 col-lg-3">
<div class="client-wrapper">
<div class="wrap-img">
<img src={ibm} className="img-responsive clients-img" alt="..." />
</div>

</div>
</div>
<div class="p-3 col-12 col-md-6 col-lg-3">
<div class="client-wrapper">
<div class="wrap-img">
<img src={aws} className="img-responsive clients-img" alt="..." />
</div>
<div class="card-box">


</div>
</div>
</div>
<div class="p-3 col-12 col-md-6 col-lg-3">
<div class="client-wrapper">
<div class="wrap-img">
<img src={googlecloud} className="img-responsive clients-img" alt="..." />
</div>

</div>
</div>

<div class="p-3 col-12 col-md-6 col-lg-3">
<div class="client-wrapper">
<div class="wrap-img">
<img src={salesforce} className="img-responsive clients-img" alt="..." />
</div>

</div>
</div>

<div class="p-3 col-12 col-md-6 col-lg-3">
<div class="client-wrapper">
<div class="wrap-img">
<img src={dell} className="img-responsive clients-img" alt="..." />
</div>

</div>
</div>
</div>
</div>
</section>

{/* our client section end */}
<Book/>
<Footer />
</>
)
}

export default OurIndustries
