import React from 'react';
import Menu from '../../common/Menu';
import girl from './../../images/streamlining.jpg'
import Footer from '../../common/Footer'
import './../casestudy/case.css'

function Streamlining () {


  
return (
  <>
  <Menu/>



  <div className="container-case section-zqn">

  {/* Left Side (Image and Heading) */}
  <div className="left-section">

    <img
      src={girl}
      alt="Team Working"
    />
  </div>
  {/* Right Side (Text Content and Button) */}
  <div className="right-section">
    <p>Case Study</p>
    <h2>Streamlining Commodity Trades with Advanced Automation</h2>
    <p className="description-case">
    When Kinder Morgan, Inc. realized the limitations of their manual, Excel-based trading protocol, they turned to Procal for an innovative solution that would seamlessly execute commodity trades while preserving the practical aspects of their existing process. However, implementing changes, upgrades, and automation requires careful timing, considering the costs and learning curve associated with such endeavors. Kinder Morgan's adherence to the Financial Information eXchange (FIX) protocol standards, known as the industry benchmark, is crucial for maintaining compatibility with prevailing practices, enhancing efficiency, and establishing trust in the market. 
    </p>
    <p>Leveraging Cloud Technology for Real-time Adaptability: Comcast’s Operational Transformation</p>


<div>
<svg width="51" height="46" viewBox="0 0 51 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.256 40.981 1.2 27.313a5.05 5.05 0 0 1-.7-2.563v-3.802c0-.944.264-1.868.76-2.667L9.97 4.284a5.01 5.01 0 0 1 1.568-1.583l3.044-1.93A4.954 4.954 0 0 1 17.235 0h16.903c.939 0 1.858.267 2.653.771l3.007 1.907c.658.417 1.21.983 1.612 1.654l8.373 13.975c.47.783.717 1.68.717 2.595v3.8a5.06 5.06 0 0 1-.743 2.639l-8.48 13.83c-.316.516-.723.97-1.2 1.34l-3.16 2.448A4.958 4.958 0 0 1 33.88 46H17.32a4.96 4.96 0 0 1-2.782-.855l-3.779-2.563a5.006 5.006 0 0 1-1.502-1.6ZM34.138 2H17.236c-.56 0-1.108.159-1.583.46L12.61 4.39c-.38.24-.701.565-.94.95L2.96 19.338l-1.685-1.049 1.685 1.049a3.04 3.04 0 0 0-.459 1.61v3.802c0 .545.146 1.08.422 1.547l8.057 13.669c.227.385.535.713.901.961l3.78 2.563c.491.333 1.069.51 1.659.51h16.56c.654 0 1.291-.218 1.813-.621l3.158-2.448c.286-.222.53-.494.721-.805l8.48-13.83c.292-.478.448-1.03.448-1.593v-3.801c0-.553-.15-1.095-.433-1.567L39.694 5.36a3.001 3.001 0 0 0-.967-.993L35.72 2.46A2.954 2.954 0 0 0 34.138 2Z" fill="#0058DD"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M32.707 22.793 26 29.5a1 1 0 0 1-1.414-1.414l4.293-4.293h-9.586a1 1 0 1 1 0-2h9.586L24.586 17.5A1 1 0 0 1 26 16.086l6.707 6.707Z" fill="#0058DD"></path> </svg> <span>Previous Project</span></div>
  </div>
</div>


<div class="container container-case-1 right-section">
        <div class="row">
            <div class="col-md-6 ">
       
                <h2 className="gb-headline-6nzf">Challenge</h2>
                <p className="col-oe2f description-case" style={{ fontSize: 16 }}>Kinder Morgan relied on manually developed Excel spreadsheets, a common practice that posed a significant risk of human error and lacked compliance with FIX standards. Achieving compliance with these industry-recognized standards was vital to enable seamless interoperability and effective communication among diverse trading systems, brokers, exchanges, and other market participants. Recognizing the need for an upgrade, Procal aimed to enhance the system by incorporating value-added enhancements and upgrades. Transitioning from manual to automated processes reduced the risk of errors substantially, enhancing the reliability of Kinder Morgan’s operations. Additionally, automation implementation ensured smooth network communications, streamlining interactions and facilitating efficient trade execution.
                </p>

              
            </div>
            <div class="col-md-6">
           
<h2 className="gb-headline-6nzf">Achievements and Impact</h2>
<p className="col-oe2f description-case" style={{ fontSize: 16 }}>Integrating the new FIX platform facilitated seamless network correspondence between Kinder Morgan and the International Currency Exchange (ICE). This cutting-edge network infrastructure enhanced the efficiency of their trade mechanism and ensured faster updates for stakeholders and analysts. Moreover, adhering to industry-standard activity enabled Kinder Morgan to achieve compliance without reconfiguring information for submission. Consequently, their automated trade platform has emerged as the industry benchmark, solidifying Kinder Morgan’s position as a recognized leader in the field</p>


            </div>

            <div class="col-md-12 mt-5 casebox">     

            <h2 className="gb-headline-6nzf">Procal's Solution</h2>
                <p className="col-oe2f description-case" style={{ fontSize: 16 }}>
                Procal created an advanced end to end trade and communication system that operates fully automated, effectively replacing the previous manual handling of Kinder Morgan’s operations. This transformation significantly decreased the probability of errors by removing human input as the cornerstone for each trade and update. Leveraging cutting-edge technology, sophisticated algorithms, and automation frameworks, Procal engineered a robust system that minimized reliance on manual processes, introducing higher accuracy and efficiency. Automation development and integration empowered Kinder Morgan to execute trades and provide updates with enhanced precision and reliability, ultimately optimizing their overall business operations.
                </p>
   

</div>
        </div>
    </div>
{/* <section className="container-rom container-case-1 ali-fxl">

  


</section> */}
  <Footer/>


  </>
)
}

export default Streamlining
