import React from 'react';
import Menu from '../../common/Menu';
import  { useEffect } from 'react';
import Footer from '../../common/Footer'
import './telecom.css';
import tone from './../../images/tone.png'
import sme from './../../images/smes.png'
import emerg from './../../images/emreg-tech.avif'
import auto from './../../images/automation-left.jpg'
import girl from './../../images/data-leads-icons.png'
import iphone from './../../images/iphone-mock.png'
import telcomeleads from './../../images/data-insights-leads.jpg'
import Location from '../../common/contact/Location';
import cybersml from './../../images/lock.jpg'
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Map from '../../subpages/Map';
import Book from './book';
function DataLeads() {


    
  
return (
<>
<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Cyber Security Services in USA, UK, UAE | IT Security Solutions" />
	<meta property="og:description" content="Procal offers cybersecurity consulting services &amp; solutions in USA, UK, UAE, to embed security across your ecosystem to reduce risk in the future by securing today" />
	<meta property="og:url" content="https://www.procaltech.com/solutions/cybersecurity-leads/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>


<Menu />



<section className='1telecom-sec-6 pt100 pb-5 tw-bg-gradient-to-r tw-from-slate-100 tw-to-slate-200' id='cybersecurity'>
<div className="hero-container " id="hero-sec">
<div className='container'>

</div>
  <div className="container-fluid ">
    <div className="row d-flex">
      <div className="col-12  offset-lg-1 col-lg-4 align-middle">
        <div className="px-2 py-2">
          <img
            src={telcomeleads}
            className="img-fluid"
            alt="..."
          />
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="px-5 py-5">
          <div className="px-2 py-2 align-middle">
        
            <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Welcome to Procal</h2>
            <p>
            With over 15 years of experience, Procal Technologies has been at the forefront of data analytics and insights, empowering businesses with cutting-edge solutions. Our team of experts is dedicated to turning complex data into actionable intelligence, helping you make informed decisions that drive success.
            </p>
          </div>
          {/* <div className="px-2 py-2">
            <button type="button" className="btn btn-outline-primary">
              Checkout Our Courses
            </button>
          </div> */}
        </div>
      </div>
    </div>


  </div>
  {/* main container */}
  <div className="main-container ">
  <div className="card-container " id="team">
    <div className="container px-3 py-5">
      <div className="row center mx-4 my-4 text-black text-center">
        <h2></h2>
        <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Data Analytics and Insights Services</h2>
        <p>At Procal Technologies, we offer a comprehensive suite of Data Analytics and Insights solutions designed to meet your unique needs</p>
      </div>
      <div className="container">

  <div className="row mb-5">
    <div className="col-md-3">
    <div className="dark:tw-bg-gray-950">
  <div className="tw-max-w-md tw-rounded-3xl tw-p-px tw-bg-gradient-to-b tw-from-blue-300 tw-to-pink-300 dark:tw-from-blue-800 dark:tw-to-purple-800 tw-mb-10">
    <div className="tw-rounded-[calc(1.5rem-1px)] tw-p-10 tw-bg-white dark:tw-bg-gray-900 img33">
    <img
          src={girl}
          className="card-img-top"
          alt="..."
        />
    <h5 className="card-title mt-3">AI/ML Insights</h5>
          <p className="card-text">
          In today’s data-driven world, businesses are seeking innovative ways to leverage artificial intelligence (AI) and machine learning (ML) to unlock valuable insights from their data.
          </p>
 
          <Link to="/solutions/ai-ml-insights/" className="btn btn-sm btn-primary">  Read More.</Link>
 
    </div>
  </div>
</div>

    </div>

    <div className="col-md-3">
    <div className="dark:tw-bg-gray-950">
  <div className="tw-max-w-md tw-rounded-3xl tw-p-px tw-bg-gradient-to-b tw-from-blue-300 tw-to-pink-300 dark:tw-from-blue-800 dark:tw-to-purple-800 tw-mb-10">
    <div className="tw-rounded-[calc(1.5rem-1px)] tw-p-10 tw-bg-white dark:tw-bg-gray-900v img33">
    <img
          src={girl}
          className="card-img-top"
          alt="..."
        />
    <h5 className="card-title mt-3">Network Analytics</h5>
          <p className="card-text">
          Procal stands at the forefront as a distinguished provider of advanced network analytics solutions, offering businesses profound insights into their network performance..
          </p>
 
          <Link to="/solutions/network-analytics/" className="btn btn-sm btn-primary">  Read More.</Link>
 
    </div>
  </div>
</div>

    </div>


    <div className="col-md-3">
    <div className="dark:tw-bg-gray-950 min13">
  <div className="tw-max-w-md tw-rounded-3xl tw-p-px tw-bg-gradient-to-b tw-from-blue-300 tw-to-pink-300 dark:tw-from-blue-800 dark:tw-to-purple-800 tw-mb-10">
    <div className="tw-rounded-[calc(1.5rem-1px)] tw-p-10 tw-bg-white dark:tw-bg-gray-900 img33">
    <img
          src={girl}
          className="card-img-top"
          alt="..."
        />
    <h5 className="card-title mt-3">Sport Analytics</h5>
          <p className="card-text">
          Procal is a leading provider of cutting-edge sports analytics solutions, catering to professional and collegiate teams and leagues across the globe, including the NBA, NFL, and MLB.
          </p>
 
          <Link to="/solutions/sport-analytics/" className="btn btn-sm btn-primary">  Read More.</Link>
 
    </div>
  </div>
</div>

    </div>
    <div className="col-md-3">
    <div className="dark:tw-bg-gray-950 min13">
  <div className="tw-max-w-md tw-rounded-3xl tw-p-px tw-bg-gradient-to-b tw-from-blue-300 tw-to-pink-300 dark:tw-from-blue-800 dark:tw-to-purple-800 tw-mb-10">
    <div className="tw-rounded-[calc(1.5rem-1px)] tw-p-10 tw-bg-white dark:tw-bg-gray-900 img33">
    <img
          src={girl}
          className="card-img-top"
          alt="..."
        />
    <h5 className="card-title mt-3">Big Data Analytics</h5>
          <p className="card-text">
          In today’s data-driven world, harnessing the power of big data has become essential for businesses seeking to gain valuable insights and make informed decisions.
          </p>
 
          <Link to="/solutions/big-data-analytics/" className="btn btn-sm btn-primary">  Read More.</Link>
 
    </div>
  </div>
</div>

    </div>
  </div>



</div>

    </div>
  </div>
  </div>
  {/*  Card container  */}

  {/* <div class="aside-container">
  <div class="container-fluid">
 <aside>
<p>The Epcot center is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events.</p>
</aside>
</div>
</div> */}
  {/*  testimonals container  */}

  {/* overlay box */}
  {/*   <div class="mt-5 mx-1">
  <div class="card bg-black text-white ">
  <div class="card-body px-4 py-4">
    
    </div>
   </div>
  </div> */}
  {/*  banner container  */}
  <div className="banner-container mt-5 mb-5" id="featured">
    <div className="container-fluid px-4 py-4">
      <div className="card border-none shadow-none">
      <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Why Choose Us</h2>
        <div className="card-body">
          {/*     <h5 class="card-title">Special Teachers for Students</h5>
    <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
    <a href="#" class="btn org-btn center">Learn More</a> */}
          <div className="conatiner">
            <div className="row d-flex justify-content-around">
              <div className="col ">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Expertise and Experience</div>
                  <div className="card-body">
              
                    <p className="card-text">
                    With over 15 years in the industry, our team has the knowledge and experience to tackle any data challenge.
                    </p>
                    {/*     <a href="#" class="btn btn-outline-primary center">View our curriculum</a> */}
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Proven Track Record</div>
                  <div className="card-body">
 
                    <p className="card-text">
                    Trusted by numerous clients across various industries, we consistently deliver exceptional results.


                    </p>
                  </div>
                </div>
              </div>
              <div className="col ">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Customized Solutions</div>
                  <div className="card-body">

                    <p className="card-text">
                    Our services are tailored to meet the specific needs of your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card text-black move-up mb-3 min13">
                  <div className="card-header">Cutting-Edge Technology</div>
                  <div className="card-body">

                    <p className="card-text">
                    We use the latest tools and technologies to deliver superior analytics solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


{/* tailwind html */}
<div class="tw-bg-white tw-py-24 sm:tw-py-32">
  <div class="tw-mx-auto tw-max-w-7xl tw-px-6 lg:tw-px-8">
    <div class="tw-mx-auto tw-max-w-2xl lg:tw-mx-0">
      <h2 class="tw-text-pretty tw-text-4xl tw-font-semibold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">Industry Expertise</h2>
      <p class="tw-mt-2 tw-text-lg/8 tw-text-gray-600">With extensive experience across various industries, we understand the unique cybersecurity challenges each sector faces. Trust us to safeguard your organization’s digital assets, regardless of your industry.</p>
    </div>
    <div class="tw-mx-auto tw-mt-10 tw-grid tw-max-w-2xl tw-grid-cols-1 tw-gap-x-8 tw-gap-y-11 tw-border-t tw-border-gray-200 tw-pt-10 sm:tw-mt-16 sm:tw-pt-16 lg:tw-mx-0 lg:tw-max-w-none lg:tw-grid-cols-4">
      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-medium tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm">Healthcare</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Procal is at the forefront of exceptional services in the healthcare and life sciences sector. Our company offers a comprehensive suite...</p>
        </div>
    
      </article>
      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-medium tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm tw-text-blue-800">Finance</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Procal is a trusted provider of comprehensive services tailored to meet the specific needs of the manufacturing and supply chain sector...</p>
        </div>
    
      </article>

      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-medium tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm">E-Commerce</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Procal is a leading provider of specialized solutions for companies in the retail and consumer goods sector, catering to their distinct requirements...</p>
        </div>
    
      </article>

      <article class="tw-flex tw-max-w-xl tw-flex-col tw-items-start tw-justify-between">
        <div class="tw-flex tw-items-center tw-gap-x-3 tw-text-xs">

          <a href="#" class="tw-relative tw-z-10 tw-rounded-full tw-bg-blue-50 tw-px-3 tw-py-1.5 tw-font-large tw-text-gray-600 hover:tw-bg-gray-100 tw-text-sm">Government</a>
        </div>
        <div class="tw-group tw-relative">
   
          <p class="tw-mt-5 tw-line-clamp-3 tw-text-sm/6 tw-text-gray-600">Procal collaborates with government agencies at all levels to drive digital transformation, improve operational efficiency, and enhance citizen services...</p>
        </div>
    
      </article>

    </div>

    
  </div>
</div>



{/* tailwind html end */}
</div>

</section>

<Book/>

<Footer />
</>
)
}

export default DataLeads
