import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css';
import './../solutions/esim.css'
import { Helmet } from "react-helmet";
import Book from './book';
const Esimexpert = () => {

  const imagesRef = useRef([]);
  const sideImagesRef = useRef([]);


  // Initialize ScrollTrigger
  useEffect(() => {
    let ctx = gsap.context(() => {

      gsap.registerPlugin(ScrollTrigger);

      gsap.set(".image", { zIndex: (i, target, targets) => targets.length - i });
      gsap.set(".side-image", { zIndex: (i, target, targets) => targets.length - i });

      const images = imagesRef.current;
      images.forEach((image, i) => {
        const nextImage = image.nextElementSibling;

        const imageTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: "section.showcase",
            start: () => "top -" + (window.innerHeight * i),
            end: () => "+=" + window.innerHeight,
            scrub: true,
            invalidateOnRefresh: true,
          }
        });

        imageTimeline.fromTo(image, { height: "100%" }, { height: "0%", ease: "none" }, 0);
        // You can add more animations here if needed
      });

      const sideImages = sideImagesRef.current;
      sideImages.forEach((image, i) => {
        const prevImage = image.previousElementSibling;

        const imageTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: "section.showcase",
            start: () => "top -" + (window.innerHeight * i),
            end: () => "+=" + window.innerHeight,
            scrub: true,
            invalidateOnRefresh: true,
          }
        });

        imageTimeline.fromTo(image, { height: "0%", scale: 1.00 }, { height: "100%", scale: 1.125, ease: "none" }, 0)
          .fromTo(prevImage, { scale: 1.125 }, { scale: 1.25, ease: "none" }, 0);
      });

      ScrollTrigger.create({
        trigger: "section.showcase",
        start: () => "top top",
        end: () => "+=" + ((images.length) * window.innerHeight),
        pin: '.image-wrap',
        anticipatePin: 1,
        invalidateOnRefresh: true,
      });

      ScrollTrigger.create({
        trigger: "section.showcase",
        start: () => "top top",
        end: () => "+=" + ((sideImages.length) * window.innerHeight),
        pin: '.side-image-wrap',
        anticipatePin: 1,
        invalidateOnRefresh: true,
      });
    });
    return () => ctx.revert();
  }, []); // Empty dependency array to run effect only once


  return (

    <>

<Helmet>
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="eSim Technology Solutions | Customised to your needs | Procal" />
	<meta property="og:description" content="Our team of seasoned professionals leverages deep industry knowledge to provide scalable and secure eSIM services, ensuring your business stays ahead in the fast-evolving digital landscape." />
	<meta property="og:url" content="https://www.procaltech.com/solutions/esim/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>

      <div className="scroller">

        <div id='loc'>
          <section className="showcase">
            <div className="text-wrap">
              <div className="text-wrapper">
                <div className="textL blue">
                  <h4>eUICC Development</h4>

                  <p>With extensive expertise in eUICC (embedded Universal Integrated Circuit Card) development, our team excels in crafting and managing eUICC technology. We specialize in profile management, secure provisioning, and leveraging the full potential of eUICCs to enhance connectivity experiences. Remote SIM Provisioning: With expertise in remote management of eSIMs, Procal enables seamless over-the-air provisioning, activation, and updates of subscriber profiles. By eliminating the need for physical SIM card replacements, we streamline the process and enhance convenience for users. Our skilled team ensures secure and efficient remote management of eSIMs, allowing for hassle-free network connectivity and simplified profile updates, all while maintaining robust security measures.</p>
                </div>
                <div className="textL red">
                  <h4>App Development and Integration</h4>

                  <p>Procal excels in crafting bespoke software applications that harness the power of eSIM technology. Our solutions unlock enhanced user experiences, enabling seamless network switching and streamlined connectivity management. With our expertise, we empower businesses to optimize mobile connectivity, ensuring efficient and reliable operations. Trust Procal to develop tailored software applications that leverage eSIM technology to its fullest potential, revolutionizing the way you connect and engage with your customers.</p>
                </div>
                <div className="textL green"><h4>Device Management</h4>

                  <p>Procal excels in managing and configuring eSIM-enabled devices, ensuring flawless integration and optimal performance across diverse platforms and operating systems. With our expertise, we guarantee a seamless user experience, enabling hassle-free activation, provisioning, and management of eSIMs on a wide range of devices. Procal delivers unparalleled compatibility and efficiency in leveraging the power of eSIM technology for your devices.</p></div>

                <div className="textL purple"><h4>Interoperability and Compliance</h4>

                  <p>With a strong grasp of industry standards, including GSMA specifications, our eSIM solutions experts ensure seamless integration within the global mobile ecosystem. Our deep understanding enables us to ensure compatibility and compliance with regulatory requirements, facilitating interoperability with various networks and devices. By staying up-to-date with evolving standards, we guarantee that our solutions align with industry best practices, enabling smooth and reliable eSIM deployments.</p></div>

                <div className="textL green"><h4>Innovation</h4>

                  <p>With IoT devices penetrating market place beyond mobile phones, Procal’s innovation team provides insights on next-gen technology like iSIM (Integrated SIM). We partner with our customers in crafting innovative out of box solutions and proof of concepts that suits the future needs of end customers (enterprise or consumer) whether its laptops, sensors, smartwatches, or VR headsets, device performance could be enhanced with integrated SIM solution on modem SOC gaining real estate space on devices.</p></div>
              </div>
            </div>

            <div className="image-wrap">
              <div className="image uic" ></div>
              <div className="image uic" ref={el => imagesRef.current.push(el)}></div>
              <div className="image green" ref={el => imagesRef.current.push(el)}></div>
              <div className="image orange" ref={el => imagesRef.current.push(el)}></div>
              <div className="image purple" ref={el => imagesRef.current.push(el)}></div>
              <div className="image green" ref={el => imagesRef.current.push(el)}></div>
            </div>



          </section>
        </div>

      </div>

    </>
  )
}

export default Esimexpert;