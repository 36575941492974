import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

function Category() {
    const categoryModel = {
        name: '',
        tags: [],
    }
    const [category, setCategory] = useState([]);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('')
    const handleChange = (event) => {
        const name = event.target.name;
        if (name === 'name') {
            categoryModel.name = event.target.value;
        } if (name === 'tags') {
            categoryModel.tags = event.target.value.split(',');
        }
    }
    const handleCreate = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/addcategory`, categoryModel).then(res => {
            loadCategory();
            setMessage(res.data.message);
            setError(false);
        })
    }
    const loadCategory = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/allCategory`).then(res => {
            setCategory([...res.data]);
        })
    }
    const handleDelete = (ca) => {
        const payload = {
            params: {
                id: ca?._id
            }
        }
        axios.delete('https://88y25twng1.execute-api.us-east-1.amazonaws.com/removecategory', payload).then(res => {
            loadCategory();
            setMessage(res.data.message);
            setError(false);
        })
    }
    useEffect(() => {
        loadCategory()
    }, [])

    return (
        <div className='container-fluid p-0'>
            {/* <div className="bg-light pt-2 ps-5" style={{ height: '60px' }}>
                <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
            </div> */}
            <div className="row ">
                <div className="col-10 shadow-sm">
                    {/* <div className="mx-auto mb-5" style={{width: '200px'}}>
                    <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
                    </div> */}
                    {message && !error && (<div className='alert alert-success' role="alert">{message}</div>)}
                    <h5 className="card-title fw-bold pb-3">Add Category</h5>
                    <form >
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Category Name</label>
                            <input onChange={handleChange} name='name' type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Tags</label>
                            <input type="text" onChange={handleChange} name='tags' className="form-control" id="exampleInputPassword1" />
                        </div>
                        <button type="button" onClick={handleCreate} className="btn btn-primary col-3">Submit</button>
                    </form>
                    <div className="card mt-5">
                        <div className="card-header fw-bold">
                            All Category
                        </div>
                        <ul className="list-group list-group-flush">
                            {category.map(ca => (
                                <li key={ca._id} className="list-group-item d-flex justify-content-between align-items-center">{ca.name} <span className="badge bg-primary rounded-pill" style={{cursor:'pointer'}} onClick={() => handleDelete(ca)}><FontAwesomeIcon icon={faTrash} /></span></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="col"></div>
            </div>

        </div>
    )
}

export default Category