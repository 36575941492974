import React from 'react';
import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import './snap.css';
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Slider from "react-slick";

import Footer from '../common/Footer';
import Menu from '../common/Menu';

import { Helmet } from 'react-helmet';

function Announcement() {



  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.in', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)
      .from('.image-box0 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box0",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)


      .from('.image-box1 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box1",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)

      .from('.image-box2 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box2",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)

      .from('.image-box3 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box3",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0);



  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });


  return (
    <>
      <Helmet>
        <title>Artemis: Seamless XR Experiences on 5G Networks</title>
        <meta name="description" content="Procal opened offices in Dubai, UAE to invest in Dubai's technology adaption" />

        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="What We Do | Capabilities, Industries, and Partners | Procal" />
	<meta property="og:description" content="Procal opened offices in Dubai, UAE to invest in Dubai's technology adaption" />
	<meta property="og:url" content="https://www.procaltech.com/announcement" />
  <link rel="canonical" href="https://www.procaltech.com/announcement" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
      <Menu />
      {/* <div className="img-container" >
        <div id="dubai">
          <div id="watch_text"><span class="section_title">Procal opened offices in Dubai, UAE to invest in Dubai's technology
          adaption</span>
            <p class="lead 1sub_text mt-3">We are excited to share a major milestone in our journey. Procal is expanding! We are proud to announce the inauguration of our new office located at Dubai, UAE. This move marks a significant step forward in our mission to provide exceptional service and foster innovation.</p>
       
          </div>
        </div>
      </div> */}




      <section className='px-4 1spot-sec999 py-5 pb100 vh100'>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>

            <div>
              <div className="img-container" >
                <div id="dubai">
                  <div id="watch_text"><span class="section_title">Procal opened offices in Dubai, UAE to invest in Dubai's technology
                    adaption</span>
                    <p class="lead 1sub_text mt-3">We are excited to share a major milestone in our journey. Procal is expanding! We are proud to announce the inauguration of our new office located at Dubai, UAE. This move marks a significant step forward in our mission to provide exceptional service and foster innovation.</p>
                    {/* <img src="stickers.png" width="300px" /> */}
                    <a class="btn btn-primary" href="/spot">Read More</a>
                  </div>
                </div>
              </div>

            </div>

            <div>
              <div className="img-container" >
                <div id="newlocation">
                  <div id="watch_text"><span class="section_title">Coming Soon to announce new loation keep watch this space</span>
                    <p>       <a class="btn btn-primary mt-5" href="/spot">Read More</a></p>
                    {/* <img src="stickers.png" width="300px" /> */}
                  </div>

                </div>
              </div>
            </div>

          </Slider>
        </div>

        {/* slicky slider end */}


      </section>

      {/* <Contact /> */}
      <Footer />
    </>
  )
}

export default Announcement
