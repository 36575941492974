import React, { useState, useEffect } from "react";
import "./../solutions/modal.css";
import Footer from "../../common/Footer";
import Menu from "../../common/Menu";
import { useParams } from "react-router-dom";
import axios from "axios";
const Applyform = () => {
  const { id } = useParams();
  // form validation start
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    file: null,
    checkbox: false,
  });
  const [jobs, setJobs] = useState();

  const [errors, setErrors] = useState({});
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    }));

    // Enable the submit button if the checkbox is checked
    if (name === "checkbox") {
      setIsSubmitEnabled(checked);
    }
  };
  const validate = () => {
    let errors = {};

    if (!formData.firstName) {
      errors.firstName = "First name is required";
    }
    if (!formData.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }
    if (!formData.file) {
      errors.file = "File upload is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        jobId: id,
        fname: formData.firstName,
        lname: formData.lastName,
        email: formData.email,
        resume: "",
      };
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/applyJob?nocache=true`,
          payload
        )
        .then((res) => {
          if (res?.status === 200) {
            console.log("Form submitted successfully");
          }
        });
      // Handle form submission logic here
    }
  };
  // form validation end

  const getAllJobs = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/allJobs?nocache=true`)
      .then((res) => {
        if (res?.status === 200) {
          const sjob = res.data.find((d) => d.jobId === id.toString());
          setJobs(sjob);
        }
      });
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  const getByTitle = (j) => {
    return `${j?.title} jobs in ${j?.locations.map((m) => m.name).join(",")}`;
  };

  return (
    <>
      <Menu />
      <div className="form-container">
        <span className="big-circle" />
        <img src="img/shape.png" className="square" alt="" />
        <div className="apply-form">
          <div className="contact-info-apply">
            <h4 className="lead">
              {getByTitle(jobs)}
              {/* Data Scientist Jobs in United States – Procal Technolgie */}
            </h4>
            <h5>Job #:{jobs?.jobId}</h5>
            <h6 className="mt-5">
              Job Title: <b>{jobs?.title}</b>{" "}
            </h6>

            <ul className="applylist">
              <li>No. of Positions: {jobs?.position}</li>
              <li>
                Location: {`${jobs?.locations.map((m) => m.name).join(",")}`}
              </li>
              <li>Experience: {jobs?.experiance} years</li>
              <li>Working Model: {jobs?.model}</li>
            </ul>
            <h4 className="mt-5">Responsibilities</h4>
            <p dangerouslySetInnerHTML={{ __html: jobs?.responsibilities }}></p>
          </div>
          <div className="contact-form-apply">
            <span className="circle one" />
            <span className="circle two" />
            <div className="contact-info-apply mt-3 white-color">
              <p>
                <b>APPLY FOR </b>
              </p>
              <h4>{getByTitle(jobs)}</h4>

              <p>{`${jobs?.locations.map((m) => m.name).join(",")}`}</p>
            </div>
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="input-container">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="input"
                />
                <label htmlFor="">FirstName</label>
                <span>FirstName</span>

                {errors.firstName && <p>{errors.firstName}</p>}
              </div>
              <div className="input-container">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="input"
                />
                <label htmlFor="">LastName</label>
                <span>LastName</span>
                {errors.lastName && <p>{errors.lastName}</p>}
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input"
                />
                <label htmlFor="">Email address</label>
                <span>Email</span>
                {errors.email && <p>{errors.email}</p>}
              </div>

              <div class="file-drop-area">
                <span class="choose-file-button">Choose files</span>
                <span class="file-message">or drag and drop files here</span>
                <input
                  class="file-input"
                  type="file"
                  name="file"
                  onChange={handleChange}
                />
                <div> {errors.file && <p>{errors.file}</p>}</div>
              </div>

              <div class="form-check justify-content-left mb-3 display-checkbox mt-5">
                <input
                  class="form-check-input me-2"
                  type="checkbox"
                  name="checkbox"
                  checked={formData.checkbox}
                  onChange={handleChange}
                />

                <label
                  class="form-check-label white-color fontsize12"
                  for="form4Example4"
                >
                  I consent to PROCAL Systems, INC. processing my personal
                  information as set out in the Privacy Policy and Cookie Policy
                  and that, given the global nature of PROCAL’s business, such
                  processing may take place outside of my home jurisdiction.
                </label>
              </div>
              {/* <div className="input-container textarea">
          <textarea name="message" className="input" defaultValue={""} />
          <label htmlFor="">Message</label>
          <span>Message</span>
        </div> */}
              <input
                type="submit"
                defaultValue="Send"
                className="btn btn-danger apply"
                disabled={!isSubmitEnabled}
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Applyform;
