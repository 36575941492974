import React from "react";
import 'bootstrap/dist/js/bootstrap.bundle';
import './App.css';
// import { _horizontal } from "gsap/Observer";
import Router from "./common/Router";
import JSXDemo from "./localjs";


function App() {


  return (
    <>

      <Router />
      {/* <JSXDemo/> */}
      {/* <Solutions/> */}

      {/* <article className='topheader'>
</article>
<div className="header_solution">
    <h1 className="title" data-splitting><span className="title_paralax">Parallax effect</span><span className="stroke">on
            gsap</span></h1>
    <div className="header__img">
        <img src={globemum} />
    </div>

</div> */}
      
    </>
  );
}

export default App;
