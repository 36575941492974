import React from 'react';
import Menu from '../../common/Menu';
import { useEffect } from 'react';
import Footer from '../../common/Footer'
import './solution.css';
import tone from './../../images/tone.png'
import sme from './../../images/smes.png'
import emerg from './../../images/emreg-tech.avif'
import emergsml from './../../images/emer-tech-sml.jpg'
import auto from './../../images/automation-left.jpg'
import iphone from './../../images/iphone-mock.png'
import cybersml from './../../images/lock.jpg'
import telcometone from './../../images/automation-sml.jpg'
import fintect from './../../images/fintech-solution.jpg'
import Location from '../../common/contact/Location';
import Map from '../../subpages/Map';
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import { gsap } from "gsap";
import { Helmet } from "react-helmet";
import { useGSAP } from "@gsap/react";
import Book from './book';
import { ScrollTrigger } from "gsap/ScrollTrigger";
function Solution() {




return (
<>
<Helmet>
<title>IT Services and Solutions for Professional Services | Procal Technologies</title>
<meta name="description" content="We design, develop, implement, manage and optimize access to systems and information to answer your business processing, application and infrastructure needs." />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="IT Services and Solutions for Professional Services | Procal" />
	<meta property="og:description" content="We design, develop, implement, manage and optimize access to systems and information to answer your business processing, application and infrastructure needs." />
	<meta property="og:url" content="https://www.procaltech.com/solution" />
  <link rel="canonical" href="https://www.procaltech.com/solution" />
	<meta property="og:site_name" content="Procal Technologies" />
  </Helmet>
<Menu />
<Map />

<section className='telecom-sec-2 pt100w' id='telecom'>
<div class="container">
<div class="row d-flex align-items-center">
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight" >
<img src={tone} alt="video-preview" class="img-fluid" />
</div>
</div>
<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >Telecom Solutions</h2>
<h5>At Procal, we provide holistic telecom solutions that encompass voice, data, and network services. With our expertise in designing, implementing, and managing state-of-the-art communication systems, we cater to the ever-changing requirements of businesses. Our comprehensive range of services ensures that your organization stays connected and operates efficiently in today's dynamic telecommunications landscape.
</h5>

<button class="pill" type="button" ><Link to="/solutions/5g/">5G Solutions</Link></button>
<button class="pill pill--selected4" type="button"><Link to="/solutions/slicing/">Slicing</Link></button>
<button class="pill pill--selected2" type="button"><Link to="/solutions/iot/">IoT</Link></button>
<button class="pill pill--selected3" type="button">
<Link to="/solutions/esim/">eSIM</Link></button>
<button class="pill" type="button"><Link to="/solutions/private-networks/">Private Networks</Link></button>     <br />
<button class="pill pill--selected4" type="button"><Link to="/solutions/mobile-os/">Mobile OS</Link></button>
<button class="pill pill--selected3" type="button">
<Link to="/solutions/cloud/">Cloud</Link></button>
<button class="pill pill--selected1" type="button">

<Link to="/solutions/smart-city/">Smart City</Link></button>


</div>
</div>


</div>
</div>
</section>
<section className='telecom-sec-3 pt100 pb-5' id='emerging'>
<div class="container">
<div class="row d-flex align-items-center">

<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >Emerging Technologies</h2>
<h5>At Procal, we push the boundaries of technology to drive transformative change and enhance various aspects of industries and human experiences. We aim to leverage cutting-edge technology to revolutionize the way we perceive, interact, and operate in our surroundings. Our solutions collectively embody innovation, connectivity, and the pursuit of new frontiers in technology-driven solutions.
</h5>
<button class="pill" type="button"><Link to="/solutions/xr-ar-vr/">XR/AR/VR</Link></button>     <br />
<button class="pill pill--selected4" type="button">
<Link to="/solutions/robotics/">Robotics</Link></button>
<button class="pill pill--selected3" type="button">
<Link to="/solutions/automotive/">Automotive</Link></button>
<button class="pill pill--selected1" type="button">
<Link to="/solutions/drones/">Drones</Link></button>

</div>
</div>
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight" >
<img src={emergsml} alt="video-preview" class="img-fluid" />
</div>
</div>

</div>
</div>
</section>


<section className='telecom-sec-4 pt100 pb-5' id='data-insight'>
<div class="container">
<div class="row d-flex align-items-center">
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight" >
<img src={auto} alt="video-preview" class="img-fluid" />
</div>
</div>
<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >Data Analytics and Insights</h2>
<h5>At Procal, we aim to harness the power of data to uncover patterns, generate actionable insights, and make informed decisions. Our cutting-edge solutions leverage AI/ML techniques to collectively embrace the transformative potential of data, enabling businesses to enhance their operations, gain a competitive edge, and unlock valuable opportunities for growth and optimization.
</h5>
<button class="pill" type="button"><Link to="/solutions/ai-ml-insights/">AI/ML Insights</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/network-analytics/">Network Analytics</Link></button>
<button class="pill pill--selected3" type="button">
<Link to="/solutions/big-data-analytics/">Big Data Analytics</Link></button>
{/* <button class="pill pill--selected1" type="button">
Computer Vision</button> */}

<button class="pill pill--selected3" type="button">   <Link to="/solutions/sport-analytics/">Sport Analytics </Link>    </button>               </div>
</div>


</div>
</div>
</section>




<section className='telecom-sec-5 pt100 pb-5' id='automation'>
<div className='container'>
</div>
<div class="container">
<div class="row d-flex align-items-center">
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight imgadjust1"  >
<img src={telcometone} alt="video-preview" class="img-fluid " />
</div>
</div>
<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >Automation Testing</h2>
<h5>At Procal, we excel in crafting and deploying automation solutions that enhance efficiency, productivity, and labor optimization. Our expertise lies in harnessing cutting-edge technology to seamlessly integrate intelligent systems and robotic automation, redefining the way processes are streamlined and executed. By leveraging our advanced solutions, you can unlock a world where manual labor is minimized, productivity soars, and efficiency reaches new heights.
</h5>
<button class="pill" type="button"><Link to="/solutions/automation-and-remote-testing/">Test Automation and Remote Testing</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/robot-processing-automation/">Robot Processing Automation</Link></button>
</div>
</div>
</div>
</div>
</section>

<section className='telecom-sec-6 pt100 pb-5' id='cybersecurity'>

<div class="container">
<div class="row d-flex align-items-center">
<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >
Cybersecurity Solution Overview</h2>
<h5>Procal's cybersecurity solutions redefine the landscape of digital security, harnessing state-of-the-art technologies to fortify your organization against evolving cyber threats. We offer a holistic range of services, from governance risk and compliance to data privacy and protection, enabling businesses to navigate the complex cybersecurity terrain with confidence. With Procal's cybersecurity solutions, your digital assets are shielded, compliance is assured, and your organization is primed to excel in an increasingly digital and secure world.
</h5>
<button class="pill" type="button"><Link to="/vulnerability">Vulnerability Management</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/managed">Managed Security Services</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/threat-Intelligence">Threat Intelligence</Link></button>
<button class="pill" type="button"><Link to="/end-point">End Point Security</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/application">Application Security</Link></button>
<button class="pill" type="button"><Link to="/server">Server Security</Link></button>

</div>
</div>
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight imgadjust"  >
<img src={cybersml} alt="video-preview" class="img-fluid" />
</div>
</div>

</div>
</div>
</section>


<section id="content-1" class="telecom-sec-7 pt100 pb-5">
<div class="container" id='finantech'>
<div class="row d-flex align-items-center">


<div class="col-md-6 col-lg-6 order-last order-md-2">
<div class="txt-block left-column wow fadeInRight white-color" >


<h2 class="h2-xs" >Finantech Solutions</h2>

<h5>At Finantech, we are committed to delivering comprehensive and innovative solutions tailored to meet the complex needs of today’s financial institutions. Our expert team combines industry knowledge with cutting-edge technology to help your organization thrive in an ever-evolving landscape. Explore our suite of services designed to enhance your operational efficiency, security, and regulatory compliance.
</h5>
<button class="pill" type="button"> <Link to="/solutions/building-operational-resilience/">Building Operational Resilience</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/ffiec-assessment/">FFIEC Assessment</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/financial-services/">Financial Services</Link></button>
<button class="pill" type="button"> <Link to="/solutions/regulatory-remediation/">Regulatory Remediation</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/robust-controls-design/">Robust Controls Design</Link></button>
<button class="pill" type="button"> <Link to="/solutions/technology-modernization-and-automation/">Technology Modernization and Automation</Link></button>


</div>
</div>

<div className="col-md-5 col-lg-6">
    <div className="rel img-block left-column video-preview wow fadeInRight imgadjust1">
      <img
        src={fintect}
        alt="video-preview"
        className="img-fluid "
      />
    </div>
  </div>



</div>



</div>
</section>
<Book/>
<Footer />
</>
)
}

export default Solution
