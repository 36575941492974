import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Editor from "./Editor";
function Jobs() {

  const jobform = {
    title: "",
    position: "",
    exp: "",
    model: "",
    responsibilities: "",
    skillId: [],
    locationId: [],
  };
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [jobdata, setJobdata] = useState(jobform);
  const [skills, setSkills] = useState([]);
  const [locations, setLocations] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [editorLoaded, setEditorLoaded] = useState(false);

  /* const handleChange = (event) => {
    const name = event.target.name;
    if (name === "name") {
      categoryModel.name = event.target.value;
    }
    if (name === "tags") {
      categoryModel.tags = event.target.value.split(",");
    }
  }; */
  const handleCreate = () => {
    console.log(jobdata, 'jobdata')
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/addJob`, jobdata)
      .then((res) => {
        getJobs();
        setMessage(res.data.message);
        setError(false);
      });
  };
  const getSkills = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/skills`).then((res) => {
      setSkills(res?.data.map(sk => ({value: sk.name, label: sk.name, id:sk.skillId})));
    });
  };
  const getJobs = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/alljobs`).then((res) => {
      setJobs([...res.data]);
    });
  };
  const getLocations = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/location`).then((res) => {
      setLocations(res?.data.map(sk => ({value: sk.name, label: sk.name, id:sk.locationId})));
    });
  };

  const handleChangeSkills = (e) => {
    jobdata.skillId = e?.map(sk => sk.id);
    setJobdata(jobdata);
  }
  const handleChangeLocation = (e) => {
    jobdata.locationId = e?.map(sk => sk.id);
    setJobdata(jobdata);
  }

  const handleDelete = (ca) => {
    const payload = {
      params: {
        id: ca?._id,
      },
    };
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/removejob`,
        payload
      )
      .then((res) => {
        getJobs();
        setMessage(res.data.message);
        setError(false);
      });
  };
  
  useEffect(() => {
    getSkills();
    getLocations();
    getJobs();
    setEditorLoaded(true);
  }, []);


  return (
    <div className="container-fluid p-0">
      {/* <div className="bg-light pt-2 ps-5" style={{ height: '60px' }}>
                <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
            </div> */}
      <div className="row ">
        <div className="col-10 shadow-sm">
          {/* <div className="mx-auto mb-5" style={{width: '200px'}}>
                    <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
                    </div> */}
          {message && !error && (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          )}
          <h5 className="card-title fw-bold pb-3">Jobs Position</h5>
          <form>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Title
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Title"
                onChange={(e) => setJobdata({...jobdata, title: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Position
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Position"
                onChange={(e) => setJobdata({...jobdata, position: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Experiance
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Experiance"
                onChange={(e) => setJobdata({...jobdata, exp: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Model
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Model"
                onChange={(e) => setJobdata({...jobdata, model: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Skills
              </label>

              <Select
                isMulti
                name="colors"
                options={skills}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => handleChangeSkills(e)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputPassword1" className="form-label">
                Locations
              </label>
              <Select
                isMulti
                name="colors"
                options={locations}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => handleChangeLocation(e)}
              />
            </div>
            <div className="mb-3">
              <Editor
                name="description"
                onChange={(data) => {
                  jobdata.responsibilities = data;
                  setJobdata({...jobdata })
                }}
                editorLoaded={editorLoaded}
              />
            </div>
            <button
              type="button"
              onClick={handleCreate}
              className="btn btn-primary col-3"
            >
              Submit
            </button>
          </form>
          <div className="card mt-5">
            <div className="card-header fw-bold">All Category</div>
            <ul className="list-group list-group-flush">
              {jobs.map((ca) => (
                <li
                  key={ca._id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  {ca.title}
                  <span
                    className="badge bg-primary rounded-pill"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(ca)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
}

export default Jobs;
