import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css'
import createGlobe from "cobe";
import { useSpring } from 'react-spring';
import { useGSAP } from "@gsap/react";
import ireland from './../../images/ireland-city.png'
import germany from './../../images/germany-city.png'
import turkey from './../../images/turkey-city.png'
import fea2 from './../../images/free-consult.png'
import youtube from './../../images/youtube-logo.png'
import linkedin from './../../images/linkeding-logo.png'
import twitter from './../../images/x-logo.png'
import cloud from './../../images/cloud-solution.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCoffee } from '@fortawesome/free-solid-svg-icons'
import {faXTwitter } from '@fortawesome/free-solid-svg-icons'


import newyork from './../../images/new-york.png'
import dubai from './../../images/dubai-city.png'

import 'bootstrap/dist/css/bootstrap.min.css';
const PageNotFound = () => {


  const imageRef = useRef(null);
  const ref = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const canvasRef = useRef(null);
  const pointerInteracting = useRef(null);
  const pointerInteractionMovement = useRef(0);
  const [{ r }, api] = useSpring(() => ({
    r: 0,
    config: {
      mass: 1,
      tension: 280,
      friction: 40,
      precision: 0.001,
    },
  }));

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.in', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: ".z-index-fyv",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)

      .from('.image-box-center img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box-center",
          start: "top center",
          scrub: 1,

        },
        ease: 'power.inOut',
      }, 0)
      .from('.image-box0 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box0",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)


      .from('.image-box1 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box1",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)

      .from('.image-box2 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box2",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0)

      .from('.image-box3 img', {
        yPercent: 25,
        ease: "none",
        scrollTrigger: {
          trigger: ".image-box3",
          start: "top center",
          scrub: 2,

        }, ease: 'power.inOut',
      }, 0);



  });


 


  return (

    <>

<div className="content-m12">
  <h1 className='not'>page not found</h1>
  <div className="ins-hhg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 350 350"
      enableBackground="new 0 0 350 350"
    >
      <path
        d="M284 85.5c-2.4-2-5.1-4.4-12.5-6.3v-.1c10.6-27.2 3.3-38-6.9-46.5-7.4-6.2-20.3-7.4-30.4-5.1-10.9-20.2-35.7-27.3-59.2-27.5-23.5.2-48.3 7.3-59.3 27.4-10.1-2.3-23-1-30.4 5.1-10.2 8.5-17.5 19.3-6.9 46.5v.1c-7.4 1.9-10 4.4-12.4 6.4-5.3 4.1-9.8 10.5-9.8 20 .5 15.1 12.5 129.6 12.5 129.6 0 .3.3.6.6.6.2 0 .3-.1.4-.2l10.8-10.1v16.3c0 .4.3.6.6.6.1 0 .3 0 .4-.2l18.3-15.8v9.5c0 .4.3.6.6.6.2 0 .3-.1.4-.2l10.3-9.9 7.1-6.8c.4 36.5-1 80.9-7.3 122.2-1 6.7 2.7 8.3 6 8.3 1.8 0 3.4-.5 4.2-.9 10.1-5.3 18.5-26.4 24-49.9 4.6-19.5 9.7-44.9 14.5-75.8 2.2 1.1 8.7 3.1 15.2 3.1s12.9-2 15.2-3.1c4.7 30.8 9.9 56.2 14.5 75.8 5.5 23.5 13.9 44.6 24 49.9.8.4 2.5.9 4.2.9 3.3 0 7.1-1.6 6-8.3-6.3-41.2-7.7-85.6-7.3-122.2l7.1 6.8 10.3 9.9c.1.1.3.2.4.2.3 0 .6-.3.6-.6v-9.5l18.3 15.8c.1.1.3.2.4.2.3 0 .6-.3.6-.6v-16.3l10.8 10.1c.1.1.3.2.4.2.3 0 .6-.2.6-.6 0 0 12-114.4 12.5-129.6.4-9.5-4.1-15.9-9.4-20z"
        fill="#564740"
      />
      <path
        d="M178.7 167.9l-3.7-3.5-3.7 3.5v.1l-4.3 4.3c2.5 2.6 5.2 4 8 4s5.5-1.4 8-4l-4.3-4.4z"
        fill="#483A34"
      />
      <polygon
        points="186.8,179.9 163.2,179.9 162.2,187.7 187.8,187.7"
        fill="#AAD9BD"
      />
      <path
        d="M158.9 180.4s-3.1 22.7-4.2 30.6c-9 61.3-21.7 125.7-36.3 132-1.1.5-2.1-.5-2-1.6 1.8-9.7 5.9-35.3 7.2-58.2 1-17 1.4-47.8 1.5-79.6l8.8-8.4c.7-.7 1.8-.4 1.8.7v7.9l23.2-23.4z"
        fill="#F6E3D6"
      />
      <path
        d="M135.6 203.9v-7.9c0-1.1-1.1-1.4-1.8-.7l-5.5 5.2c-.1 2.7-1.3 51-1.3 61 0 27.8-7.2 71.4-6.5 71.4 6.3 0 15.1-94.5 18-132.1l-2.9 3.1z"
        fill="#FAF1EA"
      />
      <path
        d="M191.1 180.4s3.1 22.7 4.2 30.6c9 61.3 21.7 125.7 36.3 132 1.1.5 2.1-.5 2-1.6-1.8-9.7-5.9-35.3-7.2-58.2-1-17-1.4-47.8-1.5-79.6l-8.8-8.4c-.7-.7-1.8-.4-1.8.7v7.9l-23.2-23.4z"
        fill="#F6E3D6"
      />
      <path
        d="M266.6 83.2c-5.1-.8-20.7-.6-20.9-.6l19.5-4.5c14.9-31.7-7.9-46.8-21.7-46.8-4.7 0-17 1.7-18.4 1.7 0 0 2.3-.3 2.9-.9 0-5.9-16.5-25.4-53-25.4-36.4 0-53 19.5-53 25.4.6.6 2.9.9 2.9.9-1.4 0-13.7-1.7-18.4-1.7-13.7 0-36.6 15-21.7 46.8l19.5 4.5c-.1 0-15.8-.1-20.9.6-7 1.1-20.7 8.6-20.7 21.9 0 6.2 2.6 24.5 2.6 24.5h219.3s2.6-18.3 2.6-24.5c.1-13.3-13.6-20.8-20.6-21.9z"
        fill="#63C199"
      />
      <path
        d="M257.9 43.4c-3.3-2.4-7.7-3.4-13.6-3.4 0 0-2.6.1-4.1.3-14.1 1.8-19.1 9.4-32.7 9.4-6.9 0-3.2-16.4-3.2-16.4s17.4.4 17.4-.8c0-5.3-20.5-20.6-45.3-20.6h-3c-24.8 0-45.3 15.3-45.3 20.6 0 1.2 17.4.8 17.4.8s3.8 16.4-3.2 16.4c-13.6 0-18.6-7.6-32.7-9.4-1.5-.2-4.1-.3-4.1-.3-5.9 0-10.3 1-13.6 3.4-4.6 3.4-14 16.1 2.4 37l9.8 2.2h141.4l9.8-2.2c16.6-21 7.2-33.7 2.6-37z"
        fill="#C5E4D0"
      />
      <polygon points="104.3,82.6 104.3,82.6 104.3,82.6" fill="#A8E1C2" />
      <path
        d="M107.6 53.6c-1 0-1.9.3-2.7.9.3.3.4.6.4 1 0 .8-.6 1.4-1.4 1.4-.3 0-.6-.1-.9-.3-.5.9-.7 2-.7 3.1 0 3.4 2.4 6.1 5.4 6.1 3 0 5.4-2.7 5.4-6.1-.1-3.4-2.5-6.1-5.5-6.1z"
        fill="#483A34"
      />
      <path
        d="M106.5 55.5c0-.7-.3-1.4-.8-1.8-.5-.5-1.1-.8-1.8-.8-1.4 0-2.6 1.2-2.6 2.6 0 .8.4 1.6 1 2.1.4.3 1 .5 1.6.5 1.4 0 2.6-1.2 2.6-2.6z"
        fill="#FBF8F5"
      />
      <polygon points="170.7,94.1 164.5,88.3 170.8,94.2" fill="#564740" />
      <polygon points="123.3,205.3 123.3,205.3 123.3,205.3" fill="#AAD9BD" />
      <polygon points="128.4,200.4 125.1,203.6 128.4,200.4" fill="#A8E1C2" />
      <polygon points="133.8,195.2 128.4,200.4 128.4,200.4" fill="#A8E1C2" />
      <polygon points="128.4,200.4 128.4,200.4 128.4,200.4" fill="#A8E1C2" />
      <polygon points="65.3,129.6 65.3,129.6 65.3,129.6" fill="#8CD7AF" />
      <polygon points="171.1,94.5 173.7,96.9 170.8,94.2" fill="#564740" />
      <polygon points="211.2,205.3 211.2,205.3 211.2,205.3" fill="#AAD9BD" />
      <polygon points="206,200.4 206,200.4 206,200.4" fill="#A8E1C2" />
      <polygon points="180.9,108.4 180.9,108.4 180.9,108.4" fill="#8CD7AF" />
      <polygon points="245.7,82.6 245.7,82.6 245.7,82.6" fill="#A8E1C2" />
      <path
        d="M244.3 53.6c-1 0-1.9.3-2.7.9.3.3.4.6.4 1 0 .8-.6 1.4-1.4 1.4-.3 0-.6-.1-.9-.3-.5.9-.7 2-.7 3.1 0 3.4 2.4 6.1 5.4 6.1 3 0 5.4-2.7 5.4-6.1-.1-3.4-2.5-6.1-5.5-6.1z"
        fill="#483A34"
      />
      <path
        d="M243.2 55.5c0-.7-.3-1.4-.8-1.8-.5-.5-1.1-.8-1.8-.8-1.4 0-2.6 1.2-2.6 2.6 0 .8.4 1.6 1 2.1.4.3 1 .5 1.6.5 1.4 0 2.6-1.2 2.6-2.6z"
        fill="#FBF8F5"
      />
      <polygon points="179.3,94.1 185.5,88.3 179.2,94.2" fill="#564740" />
      <path
        d="M214.4 203.9v-7.9c0-1.1 1.1-1.4 1.8-.7l5.5 5.2c.1 2.7 1.3 51 1.3 61 0 27.8 7.2 71.4 6.5 71.4-6.3 0-15.1-94.5-18-132.1l2.9 3.1z"
        fill="#FAF1EA"
      />
      <polygon points="226.7,205.3 226.7,205.3 226.7,205.3" fill="#AAD9BD" />
      <polygon points="221.6,200.4 224.9,203.6 221.6,200.4" fill="#A8E1C2" />
      <polygon points="216.2,195.2 221.6,200.4 221.6,200.4" fill="#A8E1C2" />
      <polygon points="221.6,200.4 221.6,200.4 221.6,200.4" fill="#A8E1C2" />
      <polygon points="284.7,129.6 284.7,129.6 284.7,129.6" fill="#8CD7AF" />
      <polygon points="178.9,94.5 176.3,96.9 179.2,94.2" fill="#564740" />
      <path
        d="M248.9 101.7c-21.5-9.3-53.6-12.6-63.4-13.4l-6.3 5.9-.3.3-2.6 2.4c-.3.3-.8.5-1.3.5s-.9-.2-1.3-.5l-2.6-2.4-.3-.3-6.3-5.9c-9.8.8-41.9 4.1-63.4 13.4-23.1 10-35.8 28.7-35.8 27.9l1.1 11.9v-.1c34.7-39.7 104.8 26.4 104.8 26.4l3.7-3.5 3.7 3.5s70.1-66.1 104.8-26.4v.1l1.1-11.9c.2.8-12.5-17.9-35.6-27.9z"
        fill="#AAD9BD"
      />
      <path
        d="M171.3 167.9l-4.7-48.4c-5 6.5-14 12.3-28.8 12.3-23 0-36.6-30.1-71.3 9.6l7.1 77.1.5 3.7 9.8-10 1.2-67.3 1.5-1 .5 84.4 11.6-10 5.3-4.6c.4-19.7 1-53.6 1-53.6l1.6-1.1.3 62.2 9.5-9.1.6-.6 5.7-5.5c.3-16 .6-31.2.6-31.2l1.6-1.4.2 30.4 3.3-3.2 5.5-5.2c.3-.3.6-.4.9-.4.5 0 .9.4.9 1.1v7.9l35.6-36.1z"
        fill="#C5E4D0"
      />
      <path
        d="M201.7 76c-.3-.1-.6-.2-.9-.2h-51.6c-.3 0-.7.1-.9.2-.7.4-1.1 1.2-.7 1.9l.3.4 22.8 21.4.1.1 2.9 2.7c.3.3.8.5 1.3.5s.9-.2 1.3-.5l2.9-2.7.1-.1 22.8-21.4c.1-.1.2-.2.3-.4.4-.7.1-1.6-.7-1.9z"
        fill="#564740"
      />
      <path
        d="M178.7 167.9l4.7-48.4c5 6.5 14 12.3 28.8 12.3 23 0 36.6-30.1 71.3 9.6l-7.1 77.1-.5 3.7-9.8-10-1.2-67.3-1.5-1-.5 84.4-11.6-10-5.3-4.6c-.4-19.7-1-53.6-1-53.6l-1.6-1.1-.3 62.2-9.5-9.1-.6-.6-5.7-5.5c-.3-16-.6-31.2-.6-31.2l-1.6-1.4-.2 30.4-3.3-3.2-5.5-5.2c-.3-.3-.6-.4-.9-.4-.5 0-.9.4-.9 1.1v7.9l-35.6-36.1z"
        fill="#C5E4D0"
      />
      <path
        d="M271.5 63.2c0-2 0-3.9-.1-5.8v21.7c.1-16.3.1-15.5.1-15.9z"
        fill="#564740"
      />
      <path
        d="M141.8 55.9c11.4 0 4.6-18.7 4.6-18.7s.9 7.7-7.9 7.7c-17 0-17-5.9-30.6-5.9-11.2 0-14.8 3.6-14.8 3.6 25.8-6.2 32.2 13.3 48.7 13.3z"
        fill="#AAD9BD"
      />
      <path d="M191.1 180.4" fill="#F5E0D3" />
      <path
        d="M187.7 187l-6.2-6.2c-2.2.3-4.3.4-6.5.4s-4.4-.2-6.5-.4l-6.2 6.2-3.8 27.8c4.6 3.8 10.1 5.4 16.3 5.4h.4c6.3 0 11.7-1.6 16.3-5.4l-3.8-27.8z"
        fill="#C5E4D0"
      />
      <path
        d="M186.1 175.4l-3.1-3.2c-2.5 2.6-5.2 4-8 4s-5.5-1.4-8-4l-3.1 3.2-.6 4.5c.7.2 1.3.3 2 .4 3.2.6 6.4.9 9.7.9 3.3 0 6.6-.3 9.7-.9.7-.1 1.4-.3 2-.4l-.6-4.5z"
        fill="#63C199"
      />
      <path
        d="M208.2 55.9c-11.4 0-4.6-18.7-4.6-18.7s-.9 7.7 7.9 7.7c17 0 17-5.9 30.6-5.9 11.2 0 14.8 3.6 14.8 3.6-25.8-6.2-32.2 13.3-48.7 13.3z"
        fill="#AAD9BD"
      />
    </svg>
  </div>
</div>



    </>
  )
}

export default PageNotFound;