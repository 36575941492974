import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";

function JobLocation() {
  const [location, setLocation] = useState('');
  const [locations, setLocations] = useState([]);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const handleCreate = () => {
    const payload = {
        name: location
    }
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/addlocation`, payload)
      .then((res) => {
        loadLocation();
        setMessage(res.data.message);
        setError(false);
      });
  };
  const loadLocation = () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/location`).then((res) => {
        setLocations([...res.data]);
    });
  };
  const handleDelete = (ca) => {
    const payload = {
      params: {
        id: ca?._id,
      },
    };
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/removelocation`,
        payload
      )
      .then((res) => {
        loadLocation();
        setMessage(res.data.message);
        setError(false);
      });
  };
  useEffect(() => {
    loadLocation();
  }, []);

  return (
    <div className="container-fluid p-0">
      {/* <div className="bg-light pt-2 ps-5" style={{ height: '60px' }}>
                <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
            </div> */}
      <div className="row ">
        <div className="col-10 shadow-sm">
          {/* <div className="mx-auto mb-5" style={{width: '200px'}}>
                    <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
                    </div> */}
          {message && !error && (
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          )}
          <h5 className="card-title fw-bold pb-3">Job Loction</h5>
          <form>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                Location Name
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Location"
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            <button
              type="button"
              onClick={handleCreate}
              className="btn btn-primary col-3"
            >
              Submit
            </button>
          </form>
          <div className="card mt-5">
            <div className="card-header fw-bold">All Category</div>
            <ul className="list-group list-group-flush">
              {locations.map((loc) => (
                <li
                  key={loc._id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  {loc.name}{" "}
                  <span
                    className="badge bg-primary rounded-pill"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(loc)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
}

export default JobLocation;
