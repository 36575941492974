import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css';
import './../solutions/esim.css'
import Book from './book';
const SmartcityExpert = () => {

const imagesRef = useRef([]);
const sideImagesRef = useRef([]);


// Initialize ScrollTrigger
useEffect(() => {
let ctx = gsap.context(() => {

gsap.registerPlugin(ScrollTrigger);

gsap.set(".image", { zIndex: (i, target, targets) => targets.length - i });
gsap.set(".side-image", { zIndex: (i, target, targets) => targets.length - i });

const images = imagesRef.current;
images.forEach((image, i) => {
const nextImage = image.nextElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "100%" }, { height: "0%", ease: "none" }, 0);
// You can add more animations here if needed
});

const sideImages = sideImagesRef.current;
sideImages.forEach((image, i) => {
const prevImage = image.previousElementSibling;

const imageTimeline = gsap.timeline({
scrollTrigger: {
trigger: "section.showcase",
start: () => "top -" + (window.innerHeight * i),
end: () => "+=" + window.innerHeight,
scrub: true,
invalidateOnRefresh: true,
}
});

imageTimeline.fromTo(image, { height: "0%", scale: 1.00 }, { height: "100%", scale: 1.125, ease: "none" }, 0)
.fromTo(prevImage, { scale: 1.125 }, { scale: 1.25, ease: "none" }, 0);
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((images.length) * window.innerHeight),
pin: '.image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});

ScrollTrigger.create({
trigger: "section.showcase",
start: () => "top top",
end: () => "+=" + ((sideImages.length) * window.innerHeight),
pin: '.side-image-wrap',
anticipatePin: 1,
invalidateOnRefresh: true,
});
});
return () => ctx.revert();
}, []); // Empty dependency array to run effect only once


return (

<>



<div className="scroller">

<div id='loc'>
<section className="showcase">
<div className="text-wrap">
<div className="text-wrapper">



<div className="textL blue">
<h4>Infrastructure and Transport</h4>

<p>Procal excels in delivering intelligent solutions to enhance urban mobility and optimize transportation systems. Leveraging advanced technologies like real-time data analytics, IoT sensors, and smart traffic management systems, we enable cities to reduce congestion, improve public transit efficiency, and enhance overall transportation infrastructure. Our expertise lies in designing and implementing scalable solutions that promote sustainable transportation, seamless connectivity, and a smoother flow of people and goods throughout the city.</p>
</div>
<div className="textL c">
<h4>Traffic Management</h4>

<p>Procal specializes in providing advanced traffic management solutions as part of our smart city offering. Leveraging state-of-the-art technology and data analytics, we help cities optimize traffic flow, reduce congestion, and enhance overall transportation efficiency. Our expertise includes intelligent traffic signal systems, real-time traffic monitoring, predictive traffic modeling, and dynamic routing solutions. By implementing our traffic management solutions, cities can experience smoother traffic operations, shorter travel times, improved road safety, and a more sustainable transportation network.</p>
</div>
<div className="textL green"><h4>AI Traffic Management Support</h4>

<p>By leveraging advanced algorithms and machine learning techniques, Procal’s energy management solutions optimize energy distribution, consumption, and storage. It enables real-time monitoring, predictive analytics, and demand response mechanisms, resulting in reduced energy costs, improved grid reliability, and enhanced sustainability. Our AI-driven solution empowers cities to make informed decisions, optimize resource allocation, and create a greener and more efficient energy ecosystem.</p></div>

<div className="textL purple"><h4>Smart Parking</h4>

<p>Procal specializes in smart parking solutions, revolutionizing urban mobility and parking management. Leveraging IoT sensors, real-time data analytics, and mobile applications, our smart parking solutions offer real-time parking availability information, enabling drivers to locate and reserve parking spaces conveniently. With our solution, cities can optimize parking space utilization, reduce traffic congestion, and enhance the overall parking experience for citizens. Our smart parking expertise contributes to creating more efficient and sustainable urban environments.</p></div>




</div>
</div>

<div className="image-wrap">
<div className="image uicc" ref={el => imagesRef.current.push(el)}></div>
<div className="image uicc" ref={el => imagesRef.current.push(el)}></div>
<div className="image uicc" ref={el => imagesRef.current.push(el)}></div>
<div className="image uicc" ref={el => imagesRef.current.push(el)}></div>


</div>



</section>
</div>

</div>

</>
)
}

export default SmartcityExpert;