import React from 'react';
import  {useEffect} from "react";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import './testimonial.css';
import Slider from "react-slick";
import Footer from '../common/Footer';
import Menu from '../common/Menu';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import fivegtext from './../images/5g-text-3d.png'
function Testimonial() {


  

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);


    var tl = gsap.timeline({repeat:'1'})

    tl.to('.gsap-square, .gsap-circle', 1.5, {
      y: 10,
      ease: "quad"
    }).to('.gsap-square, .gsap-circle', 1.5, {
      y:-10,
      ease:"quad"
    });
// Define animation to animate each letter of the text
const animateText = (element) => {
  const text = element.textContent.trim();
  element.textContent = ''; // Clear the text content

  text.split('').forEach((char, index) => {
    const letter = document.createElement('span');
    letter.textContent = char === ' ' ? '\u00A0' : char; // Add non-breaking space for regular space
    letter.style.opacity = 0;
    letter.style.display = 'inline-block';
    gsap.to(letter, {
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out',
      delay: index * 0.1, // Add delay for each letter
    });
    element.appendChild(letter);
  });
};
// Set up ScrollTriggers for text animations
    const animateElements = document.querySelectorAll('.animate-text-2');
    animateElements.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: 'top bottom-=100',
        end: 'top center',
        onEnter: () => animateText(element),
        once: false // Animation will happen only once
      });
    });

        // Define animation to move element from left to right
        const moveLeftToRight = (element) => {
          gsap.to(element, {
            x: '-15%',
            opacity: 1,
            duration: 1,
            ease: 'power2.inOut',
          });
        };
    

  }, []);

  // slick slider code start
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    };
    
    const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
    };
    // slick slider end

  return (
    <>
    <Menu/>
    <section class="bg-testimon px-4 py-5 p-5 pb-5">
  <div class="container">
    <div class="row align-items-center">
    <>
  <div className="outerdiv">
    <div className="innerdiv">
      {/* div1 */}
      <div className="div1 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img
              src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-daniel.jpg"
              alt=""
            />
          </div>
          <div className="detbox">
            <p className="name">– Manger, T-Mobile</p>
            {/* <p className="designation">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review mt-3">
          <h4>
          "Procal continues to exceed our expectations in every way. Their team quickly grasps and adapts to our unique requirements, delivering high quality results time and time again."
          </h4>
    
        </div>
      </div>
      {/* div2 */}
      <div className="div2 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img
              src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jonathan.jpg"
              alt=""
            />
          </div>
          <div className="detbox">
            <p className="name">– Director, Ericsson</p>
            {/* <p className="designation">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review mt-3">
          <h6>"Procal sets the bar high when it comes to customer service. From the beginning, they've been attentive and responsive, always making us feel like a valued client.”</h6>
  
        </div>
      </div>
      {/* div3 */}
      <div className="div3 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img
              src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-kira.jpg"
              alt=""
            />
          </div>
          <div className="detbox">
            <p className="name dark">– Project Manager, AT&T</p>
            {/* <p className="designation dark">Verified Graduate</p> */}
          </div>
        </div>
        <div className="review dark"><h6>“I work with the Procal team on a variety of projects and can confidently say they possess remarkable expertise and a high level of professionalism. I have complete trust in their abilities to deliver top notch results quickly and effectively.”</h6>

        </div>
      </div>
      {/* div4 */}
      <div className="div4 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img
              src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-jeanette.jpg"
              alt=""
            />
          </div>
          <div className="detbox">
            <p className="name dark">– Director, Verizon</p>
    
          </div>
        </div>
        <div className="review dark">
          <h6>"Procal has been an integral part of my team’s growth and success. Their product, Snap Automation, has transformed our application testing processes, helping us bring a higher quality product to market faster.”</h6>
   
        </div>
      </div>
      {/* div5 */}
      <div className="div5 eachdiv">
        <div className="userdetails">
          <div className="imgbox">
            <img
              src="https://raw.githubusercontent.com/RahulSahOfficial/testimonials_grid_section/5532c958b7d3c9b910a216b198fdd21c73112d84/images/image-patrick.jpg"
              alt=""
            />
          </div>
          <div className="detbox">
            <p className="name">Patrick Abrams</p>
            <p className="designation">Verified Graduate</p>
          </div>
        </div>
        <div className="review">
        <h6>"Procal sets the bar high when it comes to customer service. From the beginning, they've been attentive and responsive, always making us feel like a valued client.”</h6>
    
        </div>
      </div>
    </div>
  </div>

</>

    </div>
  </div>
</section>

    <section class="bg-section px-4 py-5 p-5 pb-5">
  <div class="container">
 
{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center gradient-insight py-5">


<div className="col-lg-7 offset-lg-1 col-md-10 col-12 align-items-center py-5">

<h4>Insights</h4>

<div className='rem2'>Revolutionizing Connectivity in Stadiums and Large Venues: Private Networks are a Game Changer</div>
<Link to="/artemis" class="btn btn-primary mt-5">Read More</Link>
</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center gradient-insight py-5">



<div className="col-lg-7 offset-lg-1 col-md-10 col-12 align-items-center py-5">

<h4>Insights</h4>

<div className='rem2'>Data Engineering in the Era of Gen AI: Shaping the Future of Analytics</div>
<Link to="/artemis" class="btn btn-primary mt-5">Read More</Link>
</div>
</div>
</div>


</Slider>
</div>

{/* slicky slider end */}



  </div>
</section>


<section class="bg-section-art mt-5 px-4 py-5 p-5 pb-5" >
  <div class="container">
    <div class="row align-items-center">

    <div class="col-md-5  py-5">
        <img src={fivegtext} className='img-fluid'/>
      </div>
      <div class="col-md-7  py-5">
      <h1 class="hero-head color-white"> <span class="ctrl-break-blue  fontweight600">Artemis</span> 
      <span class="ctrl-break1 d-block ctrl-break-blue"> Seamless XR Experiences on</span>
       <span class="ctrl-break-blue  last fontweight600"> 5G Networks</span>
       </h1>

        <p className='text-white'>Artemis is Procal's cutting-edge XR Network Performance Diagnostics solution, specifically designed for Extended Reality (XR) applications operating over 5G networks. This innovative platform offers real-time monitoring and comprehensive performance insights, empowering stakeholders to proactively optimize network and device performance, resulting in enhanced user experiences and unparalleled XR application reliability.</p>
        <Link to="/artemis" class="btn btn-primary">Read More</Link>
      </div>
  
    </div>
  </div>
</section>


   

      <Footer />
    </>
  )
}

export default Testimonial
