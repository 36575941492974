import React from 'react';

import { useEffect, useRef, StrictMode, useState } from "react";
import { gsap } from "gsap";
import './digtalbanking.css';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import butterfly from './../images/butterfly.png'
import cloud from './../images/consult.png'
import fea22 from './../images/digitral-trans-1.jpg'
import Footer from '../common/Footer';
import Menu from '../common/Menu';

import { Helmet } from 'react-helmet';

import { useGSAP } from "@gsap/react";


function DigitalTransformation() {

  // counter animation

  const CountUpAnimation = ({
    iconComponent,
    initialValue,
    targetValue,
    text,
  }) => {
    const [count, setCount] = useState(initialValue);
    const duration = 4000; // 4 seconds

    useEffect(() => {
      let startValue = initialValue;
      const interval = Math.floor(
        duration / (targetValue - initialValue));

      const counter = setInterval(() => {
        startValue += 1;
        setCount(startValue);
        if (startValue >= targetValue) {
          clearInterval(counter);
        }
      }, interval);

      return () => {
        clearInterval(counter);
      };
    }, [targetValue, initialValue]);

    return (
      <div className="container">
        <div className="icon">{iconComponent}</div>
        <span className="num">{count}</span>
        <span className="text">{text}</span>
      </div>
    );
  };

  // counter animation tend

  const imageRef = useRef(null);
  const ref = useRef(null);
  
  useGSAP(() => {
  
  gsap.registerPlugin(ScrollTrigger);
  
  const targets = document.querySelectorAll('.list li');
  
  gsap.set('.list', { autoAlpha: 1 });
  
  const timeline = gsap.timeline({
  defaults: {
  overwrite: 'auto',
  ease: 'power1',
  },
  scrollTrigger: {
  end: '+=300%',
  pin: true,
  pinSpacing: true,
  scrub: true,
  start: 'top top',
  trigger: '.list',
  },
  });
  
  const stagger = 0.8;
  const duration = 1;
  
  gsap.set('.list', { autoAlpha: 1 });
  
  timeline.set(targets, { transformOrigin: 'center bottom' });
  
  timeline
  .from(targets, {
  duration: duration,
  opacity: 0,
  rotationX: 0,
  rotationY: 0,
  scale: 1,
  stagger: stagger,
  yPercent: 90,
  })
  .to(
  targets,
  {
  duration: duration,
  opacity: 0,
  rotationX: 20,
  rotationY: 2,
  scale: 0.75,
  stagger: stagger,
  y: -100,
  },
  stagger
  );
  
  // Animation paragraph
  
  const element = ref.current;
  
  const pl = gsap.timeline({
  scrollTrigger: {
  trigger: element,
  start: 'top bottom-=20%',
  end: 'bottom top-=20%',
  scrub: true,
  },
  });
  
  pl.from(element, {
  opacity: 0,
  x: -100,
  
  duration: 1,
  ease: 'power2.out',
  });
  
  
  // Animation for the image reveal effect

  
  
  
  const tl = gsap.timeline({
  scrollTrigger: {
  trigger: '.section-head-outline',
  start: 'top top',
  end: 'bottom top',
  scrub: 0.2,
  },
  ease: 'power.inOut',
  });
  
  tl.to('.title-outline', {
  yPercent: 100,
  })
  .to('.title--hello', {
  yPercent: 100,
  }, 0)
  .to('.title--outline', {
  yPercent: (i) => (i + 1) * 5 + 100,
  }, 0)
  .from('.image-box img', {
  yPercent:25,
  ease: "none",
  scrollTrigger: {
  trigger: ".outline-box",
  start: "top center",
  scrub: 1,
  
  },
  ease: 'power.inOut',
  }, 0)
  .from('.image-box0 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box0",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0)
  
  
  .from('.image-box1 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box1",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0)
  
  .from('.image-box2 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box2",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0)
  
  .from('.image-box3 img', {
  yPercent: 25,
  ease: "none",
  scrollTrigger: {
  trigger: ".image-box3",
  start: "top center",
  scrub: 2,
  
  }, ease: 'power.inOut',
  }, 0);
  
  
  
  });
  
  // slick slider code start
  const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
  };
  
  const settings2 = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {
  
  });
  return (
    <>

<Helmet>
      <title>Fusing Mastery with Cutting-Edge Technology | Snap Procal</title>
      <meta name="description" content="Fusing Mastery with Cutting-Edge Technology
Led by a team of seasoned experts, we amalgamate years of industry experience with the latest advancements in technology. Our skilled professionals harness the potential of AI, machine learning, blockchain, and other cutting-edge tools to create robust, scalable, and future-proof software solutions that exceed expectations." />
    </Helmet>
      <Menu />


      <section className=" iot-sec4 ptop75  pb-5 erer trans-solution">
      <div className="container-trans">

    <div className="left-content-trans">

  

      <h2 class="digifont">Digital Transformation Solutions - Transform Your Business Today!</h2>
                {/* <h2 class="hea-218"></h2> */}
        
                <p className='mt-5'>
      Digital Transformation Solutions - Transform Your Business Today!
      Transform your business with Procal Technologies' comprehensive digital solutions. Fill out the form below to start your journey towards enhanced efficiency, innovation, and growth.
      </p>


    </div>
    <div className="contact-form-trans trans">
     <img src={cloud}/>
    </div>
  </div>
  &nbsp;
      </section>
      <section class="intro-digital">

        <div className='row'>
          <div className='col-12 col=lg-12 col-sm-12 center-align'>




            <div className='intro-item'>
              <CountUpAnimation

                initialValue={0}
                targetValue={5}
                text="+"
              /><br />
            Countries<br/>USA | Germany | India | Turkey | Mexico | Dubai</div>


            <div className='intro-item'>
              <CountUpAnimation

                initialValue={0}
                targetValue={15}
                text="+"
              /><br />
             Years in the<br/>Industry</div>
            <div className='intro-item'>  <CountUpAnimation

              initialValue={0}
              targetValue={45}
              text="+"
            /><br />
             Fortune<br/>500 Cleints</div>
          </div>
        </div>
      </section>
  
      <section className=" iot-sec4 zptop 2pb-5 erer digitalbank-sec3">
        <div class="container">


          <div className='row ptop22'>
          <div>
    <section class="switch-osn switch-dip">
        <div class="sta-r86 max-4tv">
            <div class="sta-r86">
            <h2 class="digifont">Welcome to Procal</h2>

                <p>At Procal Technologies, we are dedicated to providing top-notch Digital Transformation Solutions tailored to your business needs. With years of experience and a team of experts.</p>
            </div>
        </div>
        <div class="sta-r86 image-box">
            <figure class="image-y2v"><span id="style-mlF4J" class="style-mlF4J"><span id="style-IxVOk" class="style-IxVOk"></span><img src={fea22} id="style-qfoSG" class="curve" /></span></figure>
        </div>
    </section>
</div>



          </div>

          <div className='row 22'>
          <div>
    <section class="switch-8wm switch-v5t">
        <div class="sta-my7 max-av6">
            <div class="sta-my7">
            <h2 class="digifont">Open to fintechs</h2>
               
                <h4><strong>Tap into the best partners</strong></h4>
                <p>Backbase Digital Banking comes pre-integrated with best-in-class fintech partners so you can instantly revolutionize your customer experience.</p>
                <p>From remote deposit capture to account aggregation, get access to a vibrant ecosystem within the safety of the Backbase Engagement Banking Platform.</p>
            </div>
        </div>
        <div class="sta-my7 image-box">
            <figure class="image-mqh minuz25"><span id="style-WCgKs" class="style-WCgKs"><span id="style-MYTwe" class="style-MYTwe"></span><img src="https://www.backbase.com/_next/image?url=https%3A%2F%2Fapi-site.backbase.com%2Fimages%2FbbProduct%2Fdigital-banking-open-to-fintechs.png&amp;w=3840&amp;q=75" id="style-dwbko" class="style-dwbko" /></span></figure>
        </div>
    </section>
</div>
          </div>
        </div>
      </section>





      {/* <Contact /> */}
      <Footer />
    </>
  )
}

export default DigitalTransformation
