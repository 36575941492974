import React from 'react';
import Menu from '../../common/Menu';
import  { useEffect } from 'react';
import Footer from '../../common/Footer'
import './telecom.css';
import { Helmet } from "react-helmet";
import tone from './../../images/tone.png'
import sme from './../../images/smes.png'
import emerg from './../../images/emreg-tech.avif'
import auto from './../../images/automation-left.jpg'
import iphone from './../../images/iphone-mock.png'
import telcometone from './../../images/telecom-tone.png'
import Location from '../../common/contact/Location';
import cybersml from './../../images/lock.jpg'
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import Book from './book';

import Map from '../../subpages/Map';
function Automation() {


    
  
return (
<>

<Helmet>
        <title>AI and machine learning  | Procal</title>
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Automation Testing" />
	<meta property="og:description" content="At Procal, we excel in crafting and deploying automation solutions that enhance efficiency, productivity, and labor optimization. Our expertise lies in harnessing cutting-edge technology to seamlessly integrate intelligent systems and robotic automation, redefining the way processes are streamlined and executed. By leveraging our advanced solutions, you can unlock a world where manual labor is minimized, productivity soars, and efficiency reaches new heights.s" />
	<meta property="og:url" content="https://www.procaltech.com/solutions/automation-testing/" />
    <link rel="canonical" href="https://www.procaltech.com/solutions/automation-testing/" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
<Menu />



<section className='telecom-sec-5 pt100 pb-5'>
    <div className='container'>


    </div>
<div class="container">
<div class="row d-flex align-items-center">
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight imgadjust"  >
<img src={telcometone} alt="video-preview" class="img-fluid1 " />
</div>
</div>
<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >Automation Testing</h2>
<h5>At Procal, we excel in crafting and deploying automation solutions that enhance efficiency, productivity, and labor optimization. Our expertise lies in harnessing cutting-edge technology to seamlessly integrate intelligent systems and robotic automation, redefining the way processes are streamlined and executed. By leveraging our advanced solutions, you can unlock a world where manual labor is minimized, productivity soars, and efficiency reaches new heights.s
</h5>


</div>
</div>


</div>
</div>
</section>

<Book/>
<Footer />
</>
)
}

export default Automation
