import React, { useState, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css'
import { useSpring } from 'react-spring';
import { useGSAP } from "@gsap/react";

import { Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
const ThankYou = () => {






 


  return (

    <>
          <div>
              <div className="img-container" >
                <div id="thankyoubg">
                  <div id="thankyoupage"><span class="section_title">Thank You</span>
                    <p class="lead 1sub_text mt-3"><strong>Please check your email</strong> for further instructions.</p>
                    {/* <img src="stickers.png" width="300px" /> */}
                    <a class="btn btn-primary" href="/contact-us">Go to Contact Page</a>
                  </div>
                </div>
              </div>

            </div>



    </>
  )
}

export default ThankYou;