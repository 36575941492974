import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Slider from "react-slick";
import Footer from './../../common/Footer'
import { Helmet } from "react-helmet";
import girleps from './../../images/cujo.gif'
import Book from './book';
import './../solutions/iot.css'

import cloud from './../../images/cloud-solution.jpg'

import retail from './../../images/life-sc.jpg'
import mockupall from './../../images/mockup-all.avif'
import telecomtower from './../../images/building-light.jpg'

import healthcare from './../../images/healthcare.jpg'

import govt from './../../images/suitcase.jpg'
import supply from './../../images/cosumer-product.jpg'
// apple ani end
function Iot() {
  const imageRef = useRef(null);
  const ref = useRef(null);

  useGSAP(() => {

    gsap.registerPlugin(ScrollTrigger);

    const targets = document.querySelectorAll('.list li');

    gsap.set('.list', { autoAlpha: 1 });

    const timeline = gsap.timeline({
      defaults: {
        overwrite: 'auto',
        ease: 'power1',
      },
      scrollTrigger: {
        end: '+=300%',
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: 'top top',
        trigger: '.list',
      },
    });

    const stagger = 0.8;
    const duration = 1;

    gsap.set('.list', { autoAlpha: 1 });

    timeline.set(targets, { transformOrigin: 'center bottom' });

    timeline
      .from(targets, {
        duration: duration,
        opacity: 0,
        rotationX: 0,
        rotationY: 0,
        scale: 1,
        stagger: stagger,
        yPercent: 90,
      })
      .to(
        targets,
        {
          duration: duration,
          opacity: 0,
          rotationX: 20,
          rotationY: 2,
          scale: 0.75,
          stagger: stagger,
          y: -100,
        },
        stagger
      );

    // Animation paragraph

    const element = ref.current;

    const pl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: 'top bottom-=20%',
        end: 'bottom top-=20%',
        scrub: true,
      },
    });

    pl.from(element, {
      opacity: 0,
      x: -100,

      duration: 1,
      ease: 'power2.out',
    });


    // Animation for the image reveal effect
    gsap.from(imageRef.current, {
      clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
      duration: 0.5, // Adjust duration
      ease: 'power4.out', // Adjust easing
      scrollTrigger: {
        trigger: '.erer',
        start: "top bottom",
        end: "center center",
        scrub: true,
        onUpdate: self => {
          const progress = self.progress;
          const points = [
            `0% 0%`,
            `${progress * 100}% 0%`,
            `${progress * 100}% 100%`,
            `0% 100%`
          ];
          gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
        },
      },
    });




    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
          trigger: ".outline-box",
          start: "top center",
          scrub: 1,

        }
      }, 0);

  });

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {


  });




  return (
    <>
    <Helmet>
    <title>Internet of Things (IoT) Services & Solutions | USA, UK, UAE</title>
    <meta name="description" content="We are dedicated to empowering enterprises with its comprehensive enterprise IoT (Internet of Things) solution, enabling enhanced connectivity and driving transformation across manufacturing, operations, and production" />
    <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Internet of Things (IoT) Services &amp; Solutions | USA, UK, UAE" />
	<meta property="og:description" content="We are dedicated to empowering enterprises with its comprehensive enterprise IoT (Internet of Things) solution, enabling enhanced connectivity and driving transformation across manufacturing, operations, and production" />
	<meta property="og:url" content="https://www.procaltech.com/solutions/iot/" />
	<meta property="og:site_name" content="Procal Technologies" />
  <link rel="canonical" href="https://www.procaltech.com/solutions/iot/" />

  </Helmet>

      <Menu />




      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">
              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div>  */}



              <div class="section-head__title">
                <div class="image-box">
                  {/* <img   ref={imageRef} src={iot} style={{marginLeft:'150px', marginTop:'50px'}}/> */}
                </div>
                <h1 class="title-outline">
                  INTERNET
                </h1>
                <h2 class="title-outline">
                  of
                </h2>

                <h2 class="title-outline">
                  things
                </h2>
                <span class="title--outline green">
                  things
                </span>
                <span class="title--outline green">
                  things
                </span>
                <span class="title--outline green">
                  things
                </span>
                <span class="title--outline green">
                  things
                </span>
                <span class="title--outline green">
                  things
                </span>
                <span class="title--outline green">
                  things
                </span>
              </div>
              <div className='sidetext'>
                <div class="image-box imgprop">
                  <img src={girleps} className='img-fluid' />

                </div>
              </div>

            </section>
          </div>





        </div>


      </section>

      <section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft hero" >
      

                <h1 class="display-5 lh-1 mb-3 star">Our IoT solutions</h1>
                <p >Procal is dedicated to empowering enterprises with its comprehensive enterprise IoT (Internet of Things) solution, enabling enhanced connectivity and driving transformation across manufacturing, operations, and production. Our IoT solutions offer immense benefits to businesses by proactively analyzing real-time data collected from interconnected devices and sensors.
                </p>

                <p  >IoT presents a range of advantages for enterprises, including improved operational efficiencies, enhanced decision-making capabilities through increased intelligence, and access to real-time data for smarter and faster decision-making. By leveraging IoT, businesses can streamline processes, optimize resource utilization, and gain valuable insights into their operations.</p>

                <p >Procal's solutions empower businesses with end-to-end performance measurements, enabling them to identify areas for improvement and optimize operational efficiencies. Through comprehensive data analysis and actionable insights, companies gain the ability to make informed decisions and develop effective remediation plans. Procal’s expert assistance enables businesses to effectively connect, manage, and secure their IoT deployments, and enhance productivity through its provision of ultra-reliable, mission-critical 4G/5G/Wi-Fi communications. By leveraging our expertise, businesses can ensure the seamless functioning of their IoT infrastructure, while mitigating potential security threats.</p>




              </div>
            </div>

            <div class="col-md-7 col-lg-7">
              <img src={mockupall} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>

      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={telecomtower} className="card-img1" alt="IOT" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Telecommunications</h4>

<p>Procal offers a comprehensive range of tailored services and solutions designed to support telecommunication service providers, network operators, and related organizations. Our expertise spans network design, deployment, and optimization, infrastructure management, software development, cybersecurity, and technical support. With Procal's assistance, clients can enhance network performance, expand coverage, improve service quality, implement cutting-edge technologies, and ensure seamless connectivity for their customers. We also provide strategic consulting, regulatory compliance guidance, and innovative solutions to help telecommunication companies navigate the dynamic industry landscape. Procal is dedicated to enabling reliable and efficient communication services, fostering innovation, and driving the growth and advancement of the telecommunications sector.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={govt} className="card-img1" alt="IOT" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Government</h4>

<p>Procal collaborates with government agencies at all levels to drive digital transformation, improve operational efficiency, and enhance citizen services. Our technology consulting and solutions empower governments to streamline processes, implement robust cybersecurity measures, leverage data analytics for evidence-based decision-making, and develop citizen-centric platforms. From digital government services to smart city initiatives, Procal's expertise helps government entities navigate complex challenges, ensure data privacy and security, and deliver effective and transparent services to their constituents.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={healthcare} className="card-img1" alt="IOT" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Healthcare and Life Sciences</h4>

<p>Procal is at the forefront of exceptional services in the healthcare and life sciences sector. Our company offers a comprehensive suite of specialized solutions designed to meet the unique needs of healthcare providers, pharmaceutical companies, research institutions, and other industry players. We excel in delivering innovative technology solutions from healthcare analytics to telehealth platforms. With Procal's expertise, clients can enhance patient care, optimize clinical processes, accelerate research and development, and navigate the complex landscape of the healthcare industry. We are committed to driving advancements in healthcare, empowering organizations to achieve their strategic objectives, improve operational efficiency, and deliver exceptional outcomes to patients and stakeholders.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={supply} className="card-img1" alt="IOT" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Manufacturing and Supply Chain</h4>

<p>Procal is a trusted provider of comprehensive services tailored to meet the specific needs of the manufacturing and supply chain sector. Our expertise and solutions are designed to optimize operations, enhance efficiency, and drive supply chain excellence. We work closely with manufacturers and supply chain organizations to offer a wide range of services, including process automation, supply chain visibility, inventory management, demand forecasting, and supplier collaboration tools. Through our innovative approaches and industry insights, Procal helps clients streamline processes, reduce costs, improve productivity, and ensure seamless integration across their manufacturing and supply chain operations. With Procal's partnership, organizations gain a competitive edge, adapt to evolving market demands, and achieve sustainable growth in the dynamic manufacturing and supply chain landscape.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={retail} className="card-img1" alt="IOT" />


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Retail and Consumer Goods</h4>

<p>Procal is a leading provider of specialized solutions for companies in the retail and consumer goods sector, catering to their distinct requirements and industry-specific demands. Our expertise spans digital transformation, operational optimization, and customer experience enhancement. We collaborate closely with retail and consumer goods organizations to deliver solutions such as e-commerce platform development, inventory management systems, and customer analytics. Procal helps clients transform their digital presence, streamline operations, and deliver personalized customer experiences. By leveraging our innovative solutions, retailers and consumer goods companies can drive growth, improve operational efficiency, and stay ahead in the competitive marketplace.</p>

</div>
</div>
</div>



</Slider>
</div>

{/* slicky slider end */}



</section>

      {/* <section className='iot-sec4'>
  <div className='container'>
<div className='iot-container'>
<h4>IoT Strategy and Consulting</h4>

<p>Procal assists businesses in developing an IoT strategy aligned with their goals and objectives. Through consulting services, we identify relevant IoT use cases, assess infrastructure requirements, and create a comprehensive roadmap for successful implementation, ensuring businesses maximize the benefits of IoT technologies.</p>
  </div>
  </div>
</section> */}

{/* 
      <Location /> */}
      {/* whats new section end */}


      {/* <section className="px-4 py-5 p-5 pb-5 sec13">
        <div className="container-fluid">
          <div className="row py-lg-5w">
            <div className="col-lg-8 offset-lg-1 col-md-8 mx-auto text-white botm-20">
              <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center">Experience the Procal Difference</h2>
              <h3 className="text-center mt-5">We want to hear from you!</h3>

              <div className="input-container">
                <input type="text" className="input-field mt-5" placeholder="Enter your E-mail address.." />
                <button className="send-button  mt-5">&#10148;</button>
              </div>
            </div>

          </div>
        </div>

      </section> */}



      {/* <div className='light-theme'>
<section className="products" id='start'>
<div className="inner products__background">

</div>
</section>
</div> */}
<Book/>
      <Footer />
    </>
  )
}

export default Iot
