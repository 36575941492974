import React, { useEffect, useState } from "react";
import "./articles.css";
import backbutton from "./../../images/back-svg.jpg";
import Menu from "../../common/Menu";
import Footer from "../../common/Footer";
import {
  Link,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";

function Art_1() {
  const location = useLocation();
  const id = location?.state?.id;
  const [blog, setBlog] = useState({});
  const getLatestBlog = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/allPage?nocache=true`)
      .then((res) => {
        console.log(res);
        if (res?.status === 200) {
          // setAllBlog(res?.data);
          const srchStr = location.pathname.split('/')[location.pathname.split('/').length - 1];
          // const slObj = res.data.find(b => b.title.toLowerCase().includes(srchStr.split('_').join(' ')));
          const slObj = res.data.find(b => b.url === srchStr);
          if(slObj) {
            setBlog(slObj);
          }
          
        }
      });
  };
  const getBlogById = (bid) => {
    axios.post(`${process.env.REACT_APP_BASE_URL}/blog`, { id:bid }).then((res) => {
      if (res?.status === 200) {
        setBlog(res.data[0]);
      }
    });
  }
  useEffect(() => {
    if(id) {
      getBlogById(id);
    }else {
      getLatestBlog()
    }
  }, [id]);

  return (
    <>
      <Menu />
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.overView} />
      </Helmet>

      <div class="hero-section-blog ps-sm-4 pt100">
        <div class="container">
          <div class="row">
            <div class="col-sm-5">
              <div class="outer">
                <div className="inner">
                  <h1 class="display-4 fw-bold article-heading">
                    {blog.title}
                  </h1>
                  <div class="author__content ad5-text-component ad5-text-gap-2xs">
                    <div class="author author--meta">
                      <a href="#0" class="author__img-wrapper">
                        <img
                          src={blog?.author?.[0]?.profilePicture}
                          alt="Author"
                        />
                      </a>

                      <div class="author__content ad5-text-component ad5-text-gap-2xs">
                        <h4 class="ad5-text-base">
                          <a href="#0" rel="author">
                            By {blog?.author?.[0]?.name}
                          </a>
                        </h4>
                        <p class="ad5-text-sm ad5-color-contrast-medium">
                          <time>in</time> - {blog?.category?.[0]?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7" id="imgborderradius">
              <img
                decoding="async"
                src={blog.img}
                alt="bootstrap banner image imgborderradius"
              />
            </div>
          </div>
        </div>
      </div>
      <article class="ad5-padding-y-lg">
        {/* 
     <header class="ad5-container ad5-max-width-xs ad5-margin-bottom-lg">
          <div class="ad5-text-component ad5-text-center ad5-line-height-lg ad5-text-gap-md ad5-margin-bottom-md">
            <h1 className="article-heading">{blog.title}</h1>
          </div>

          <div class="ad5-flex ad5-justify-center">
            <div class="author author--meta">
              <a href="#0" class="author__img-wrapper">
                <img
                  src={blog?.author?.[0]?.profilePicture}
                  alt="Author picture"
                />
              </a>

              <div class="author__content ad5-text-component ad5-text-gap-2xs">
                <h4 class="ad5-text-base">
                  <a href="#0" rel="author">
                    By {blog?.author?.[0]?.name}
                  </a>
                </h4>
                <p class="ad5-text-sm ad5-color-contrast-medium">
                  <time>in</time> - {blog?.category?.[0]?.name}
                </p>
              </div>
            </div>
          </div>
        </header>  */}

        <div class="ad5-container ad5-max-width-adaptive-sm">
          <Link to="/blog">
            <img src={backbutton} className="img-responsive mb-5" />
          </Link>
          <div class="ad5-text-component ad5-line-height-lg ad5-text-gap-md">
            {/* <img
              src={blog.img}
              className="w-100"
              alt="Image description here"
            /> */}
            <div
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: blog?.description }}
            ></div>
            <Link to="/blog">
              <img src={backbutton} className="img-responsive mb-5" />
            </Link>
          </div>
        </div>
      </article>
      <Footer />
    </>
  );
}

export default Art_1;
