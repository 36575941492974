import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/network-ana.jpg'
import car from './../../images/network-brain.jpg'
import Book from './book';
import fea2 from './../../images/network-hook.jpg'

import Slider from "react-slick";
import './../solutions/robot-processing.css'
import './../solutions/network-analytics.css'


import cloud from './../../images/network-ana.jpg'

import cutting from './../../images/network-expert.jpg'


// apple ani end
function NetworkAnalytics() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>

<Helmet>
<title>Network Analytics Solutions | USA, UAE, UK | Procal technologies</title>
<meta name="description" content="Our highly skilled team of analysts and engineers possess exceptional expertise in collecting and analyzing data from diverse sources, including network devices, applications, and users." />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Network Analytics Solutions | USA, UAE, UK | Procal" />
	<meta property="og:description" content="Our highly skilled team of analysts and engineers possess exceptional expertise in collecting and analyzing data from diverse sources, including network devices, applications, and users." />
	<meta property="og:url" content="https://www.procaltech.com/solutions/network-analytics/" />
	<meta property="og:site_name" content="Procal Technologies" />
  <link rel="canonical" href="https://www.procaltech.com/solutions/network-analytics/" />

            </Helmet>
<Menu />




<section className='relative1  five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-7'> 
<div class="section-head__title flex-basis-666">

<h1 class="title-outline">
Network 
</h1>

<h2 class="title-outline">
Analytics
</h2>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
<span class="title--outline green">
Analytics
</span>
</div>
</div>
<div className='col-lg-5'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={girl} className='img-fluid' />     
                </div>
              </div></div>


</section>
</div>





</div>


</section>

<section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center1">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                ref={imageRef}
                src={cloud}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-8 col-lg-8">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Network Analytics</h2>
                <p>Procal stands at the forefront as a distinguished provider of advanced network analytics solutions, offering businesses profound insights into their network performance, usage, and security. Our highly skilled team of analysts and engineers possesses exceptional expertise in collecting and meticulously analyzing data from diverse sources, including network devices, applications, and users. By doing so, we deliver actionable insights to our clients, empowering them to optimize network performance and fortify their security posture.</p>

                <p>At Procal, we leverage cutting-edge machine learning algorithms to scrutinize network traffic and detect intricate patterns and anomalies that may signify performance issues or potential security threats. Our real-time visibility into network activity enables clients to swiftly identify and proactively respond to emerging issues, preventing them from escalating into significant problems that impact operations.</p>

    



              </div>
            </div>

            <div class="col-md-4 col-lg-4 image-box">
              <img src={fea2} className='img-fluid'  />
            </div>

            <div class="col-md-12 col-lg-12">



<section class="section-byh u-bg-vpx">
    <div class="container-opd u-position-yg5">
        <div class="row-ctw u-mb-4eh u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-vkd">
                <h2 class="style-dYtmF" id="style-dYtmF">
                <div class="1text-zog sidefont">scrutinize network  </div>
                    <div className='sidefont'>traffic and detect  </div>
                    <div class="1text-axz sidefont">intricate </div>
                    <div class="1text-axz sidefont">patterns  </div>
                    <div class="1text-axz sidefont">and </div>
                    <div class="1text-axz sidefont">anomalies </div>
                </h2>
            </div>
        </div>
        <div class="row-ctw u-mb-4eh u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-zzz">
                <h3 class="1des-fpq 1text-axz" style={{textAlign: 'left'}}>Analyzing Network Traffic to Identify Complex Patterns and Anomalies</h3>
            </div>
        </div>
        <div class="row-kac">
            <div class="col-zh4 col-lyc"></div>
            <div class="col-zh4 col-lyc">
                <div class="u-img-559"><img src={car} class="u-img-6k2" /></div>
            </div>
            <div class="col-zh4 col-lyc"></div>
        </div>
        
        <div class="row-ctw u-z-index-fyv">
            <div class="col-zh4 col-48i"></div>
            <div class="col-zh4 col-vkd">
            
            </div>
        </div>
    </div>
</section>



            </div>
          </div>
        </div>
      </section>
      <section className="container-rom container-network ali-fxl">
  <div className="container-d1s">
    <div className="container-rom container-2e2">
      <div className="container-d1s">
        <div className="wrapper-ti2">
          <div className="column-o8o column-fhi">
            <section className="container-rom container-coh ani-lcm fa-6jh">
              <div className="container-d1s" style={{ backgroundColor:'#898989',padding:'28px' }}>
                <h2 className="gb-headline-6nz">Our team of experts</h2>
                <p className="col-oe2" style={{ fontSize: 16 }}>
                One of our distinguishing traits at Procal is our commitment to tailoring solutions to meet each client's unique needs in a cost-effective manner. Whether clients seek to improve network performance, enhance security measures, or optimize resource utilization, our team of experts is dedicated to delivering the highest level of service and support. With our advanced network analytics solutions and unrivaled expertise, Procal emerges as the preferred partner for businesses aiming to gain comprehensive insights into their network performance and elevate their overall security posture. Our unwavering dedication to excellence sets us apart in the industry, ensuring that our clients achieve remarkable results and stay ahead in an increasingly connected world.
                </p>
         
           
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



{/* whats new section */}
<section className="container sec10 py-5 p-5 pb-5">
<div className="row py-lg-5">
<div className="col-lg-12 col-md-8 mx-auto">


<h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


</div>


</div>

{/* slicky slider */}
<div className="slider-container">

<Slider {...settings2}>



<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Network Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Network Performance Monitoring</h4>

<p>By harnessing the power of cutting-edge tools and technologies, we specialize in real-time network monitoring. Our expert team analyzes crucial metrics including latency, bandwidth utilization, packet loss, and network availability, to provide organizations with invaluable insights. These insights empower businesses to identify areas of improvement, optimize network performance, and ensure seamless operations. With Procal's robust solutions, organizations can proactively enhance their network infrastructure, deliver superior user experiences, and stay ahead in today's fast-paced digital landscape.</p>

</div>


</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Network Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Traffic Analysis</h4>

<p>With a deep and comprehensive understanding of intricate network traffic patterns, Procal emerges as a leading expert in analyzing the complex flow of data within networks. Through the application of advanced techniques such as deep packet inspection (DPI), we uncover valuable insights embedded within network packets. This proficiency enables us to precisely identify bottlenecks, optimize network resources, and fine-tune performance. The outcome is a remarkable enhancement in operational efficiency, streamlined data transmission, and the maximization of network capacity. Trust Procal to leverage our expertise and propel your network infrastructure to new heights of productivity and effectiveness.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Network Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Security Monitoring</h4>

<p>At Procal, we pride ourselves on our ability to provide comprehensive network security monitoring solutions, ensuring organizations are safeguarded against potential threats and attacks. With meticulous analysis of network traffic, we actively detect and identify suspicious activities and anomalies, enabling us to provide timely alerts and automated responses. Our solutions empower organizations to proactively mitigate security threats, preserving the integrity of critical data and ensuring the overall security of their network infrastructure. With Procal's expertise and cutting-edge solutions, organizations can rest assured knowing their network is protected from evolving cybersecurity risks.</p>

</div>
</div>
</div>
<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Network Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Network Troubleshooting</h4>

<p>Procal holds extensive expertise in diagnosing and resolving complex network issues. Through the utilization of advanced analytics, we meticulously examine network logs, conduct root cause analysis, and correlate data from various sources. This in-depth approach enables us to efficiently identify and address network problems, minimizing downtime and enhancing network reliability. Our unwavering commitment to seamless connectivity ensures uninterrupted operations for our clients, empowering them to focus on their core objectives without the burden of network disruptions.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Network Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Network Optimization</h4>

<p>Procal is a leading expert in network optimization, leveraging our core strength to revolutionize organizations' network infrastructure. With meticulous attention to detail, we identify and eliminate bottlenecks that hinder performance and efficiency. Our team of skilled professionals excels in fine-tuning network configurations, ensuring maximum throughput and optimal resource utilization. Moreover, we go beyond mere solutions by providing comprehensive guidance on industry best practices. By equipping our clients with this invaluable knowledge, they can confidently maintain a highly optimized network environment, unlocking the true potential of their networks. Procal guarantees seamless connectivity and enhanced productivity, paving the way for unparalleled success.</p>

</div>
</div>
</div>

<div>
<div className="row dive-cobalt align-items-center">
<div className="col-lg-3 offset-lg-1 col-md-3 col-12">
<div className="col-bg">

<div className="box03 bg-coral-medium list-video">
<img src={cutting} className="card-img1" alt="Network Analytics"/>


</div>

</div>
</div>


<div className="col-lg-7 col-md-7 col-12 align-items-center">

<h4>Capacity Planning</h4>

<p>At Procal, our experts can help with capacity planning and make it easier for new services to launch. Companies need to reduce their time-to-market, i.e. the time taken from the approval of a product idea to its development, pre-sales promotion, and finally, when the product is available for purchase. New products should release quickly to the market to gain first-mover advantages; creating better chances for customer loyalty; additional time to refine the product; longer product lifecycle; setting the market price of the newly launched item; and higher overall profits and return on investment (ROI). As a result, the resources required for a new or expanded service can be evaluated and then provisioned well in advance of going live, ensuring products get to market on time with our expertise.</p>

</div>
</div>
</div>

</Slider>
</div>

{/* slicky slider end */}



</section>


{/* whats new section end */}



<Book/>
<Footer />
</>
)
}

export default NetworkAnalytics
