import React from 'react';
import  {useEffect, useRef} from "react";
import { gsap } from "gsap";

import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import './products.css';
import { Helmet } from 'react-helmet';

import snap from './../images/snap.jpg'

import Footer from '../common/Footer';
import Menu from '../common/Menu';

import { ScrollTrigger } from "gsap/ScrollTrigger";

import fivegtext from './../images/5g-text-3d.png'

function Products() {


  

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);


    var tl = gsap.timeline({repeat:'1'})

    tl.to('.gsap-square, .gsap-circle', 1.5, {
      y: 10,
      ease: "quad"
    }).to('.gsap-square, .gsap-circle', 1.5, {
      y:-10,
      ease:"quad"
    });
// Define animation to animate each letter of the text
const animateText = (element) => {
  const text = element.textContent.trim();
  element.textContent = ''; // Clear the text content

  text.split('').forEach((char, index) => {
    const letter = document.createElement('span');
    letter.textContent = char === ' ' ? '\u00A0' : char; // Add non-breaking space for regular space
    letter.style.opacity = 0;
    letter.style.display = 'inline-block';
    gsap.to(letter, {
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out',
      delay: index * 0.1, // Add delay for each letter
    });
    element.appendChild(letter);
  });
};
// Set up ScrollTriggers for text animations
    const animateElements = document.querySelectorAll('.animate-text-2');
    animateElements.forEach((element) => {
      ScrollTrigger.create({
        trigger: element,
        start: 'top bottom-=100',
        end: 'top center',
        onEnter: () => animateText(element),
        once: false // Animation will happen only once
      });
    });

        // Define animation to move element from left to right
        const moveLeftToRight = (element) => {
          gsap.to(element, {
            x: '-15%',
            opacity: 1,
            duration: 1,
            ease: 'power2.inOut',
          });
        };
    

  }, []);

  return (
    <>

<Helmet>
        <title>Artemis: Seamless XR Experiences on 5G Networks</title>
        <meta name="description" content="Finantech offers a suite of services designed to enhance your company's operational resilience and cybersecurity posture. From FFIEC Cybersecurity Assessments to Regulatory Remediation, Technology Modernization, and Controls Implementation, we provide expert solutions that streamline compliance and drive strategic innovation across your enterprise." />

        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Enhancing Enterprise Security and Resilience through Tailored Financial Technology Solutions | Procal" />
	<meta property="og:description" content="Finantech offers a suite of services designed to enhance your company's operational resilience and cybersecurity posture. From FFIEC Cybersecurity Assessments to Regulatory Remediation, Technology Modernization, and Controls Implementation, we provide expert solutions that streamline compliance and drive strategic innovation across your enterprise." />
	<meta property="og:url" content="https://www.procaltech.com/products" />
  <link rel="canonical" href="https://www.procaltech.com/products" />
	<meta property="og:site_name" content="Procal Technologies" />
      </Helmet>
    <Menu/>
    <section class="bg-section px-4 py-5 p-5 pb-5">
  <div class="container">
  {/* <img src={sectionbg} className='slide-image'/> */}

  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    viewBox="0 0 750 500"
    className='slide-image'
  >
    <path
    class='windmill'
      d="M0 0h750v500H0z"
      style={{
        fill: "none",
      }}
    />
    <defs>
      <path id="a" d="M0 0h750v500H0z" />
    </defs>
    <clipPath id="b">
      <use
        xlinkHref="#a"
        style={{
          overflow: "visible",
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M153.942 455.164v14.91l10.581-7.455zM166.126 455.164v14.91l10.581-7.455zM178.31 455.164v14.91l10.581-7.455z"
        style={{
          fill: "#d2d2d2",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M0 362.639v14.91l10.581-7.455zM12.184 362.639v14.91l10.581-7.455zM24.368 362.639v14.91l10.581-7.455z"
        style={{
          fill: "#d2d2d2",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M670.221 107.277v13.53l9.602-6.765zM681.278 107.277v13.53l9.602-6.765zM692.335 107.277v13.53l9.602-6.765z"
        style={{
          fill: "#d2d2d2",
        }}
      />
    </g>
    <g class='windmill'
      style={{
        clipPath: "url(#b)",
        
      }}
    >
      <path
        d="M519.648 60.994v13.53l9.602-6.765zM530.705 60.994v13.53l9.602-6.765zM541.762 60.994v13.53l9.602-6.765z"
        
        style={{
          fill: "#d2d2d2",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M402.739 414.233v13.53l9.602-6.765zM413.796 414.233v13.53l9.602-6.765zM424.853 414.233v13.53l9.602-6.765z"
        style={{
          fill: "#d7d7d7",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M274.367 9.246v11.91l8.452-5.955zM284.099 9.246v11.91l8.452-5.955zM293.832 9.246v11.91l8.452-5.955z"
        style={{
          fill: "#dcdcdc",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M725.993 342.435v11.91l8.452-5.955zM735.726 342.435v11.91l8.452-5.955zM745.458 342.435v11.91l8.452-5.955z"
        style={{
          fill: "#d7d7d7",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M631.736 413.814v19.095l13.552-9.547zM647.341 413.814v19.095l13.551-9.547zM662.946 413.814v19.095l13.551-9.547z"
        style={{
          fill: "#d2d2d2",
        }}
      />
    </g>
    <g
      style={{
        clipPath: "url(#b)",
      }}
    >
      <path
        d="M1.89 52.974v16.145l11.458-8.073zM15.084 52.974v16.145l11.458-8.073zM28.279 52.974v16.145l11.458-8.073z"
        id='fill'

      />
    </g>
    <path
      d="M350.389 191.298h48.495l21.157-21.156h48.176l24.542-24.542h50.792"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m452.573 170.142 14.987 14.987h37.991l12.048 12.048 9.043-9.043h6.242"
      className='windmill'
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={543.592}
      cy={145.655}
      r={2.75}
      style={{
        fill: "#cbcbcb",
      }}
    />
    <circle
      cx={350.389}
      cy={191.298}
      r={2.75}
      style={{
        fill: "#cbcbcb",
      }}
    />
    <circle
      cx={534.821}
      cy={188.092}
      r={1.938}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={539.53}
      cy={188.092}
      r={1.938}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={544.238}
      cy={188.092}
      r={1.938}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M546.176 188.092h14.854"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M561.03 186.155v3.875l2.75-1.938zM564.196 186.155v3.875l2.75-1.938zM567.363 186.155v3.875l2.75-1.938z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M367.051 185.78v3M369.467 185.78v3M371.884 185.78v3M374.301 185.78v3M376.717 185.78v3M494.759 181.065v3M497.176 181.065v3M499.592 181.065v3M502.009 181.065v3M504.426 181.065v3"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M453.469 170.142a.896.896 0 1 1-1.792 0 .896.896 0 0 1 1.792 0z"
      className="full-body"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="m364.543 121.743 16.432 16.432h24.044"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M404.668 136.238v3.875l2.75-1.938zM407.835 136.238v3.875l2.75-1.938zM411.001 136.238v3.875l2.75-1.938z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M437.086 102.241v3M439.503 102.241v3M441.919 102.241v3M444.336 102.241v3M446.753 102.241v3"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M390.378 121.743h29.891l15.403-15.403h37.725"
      style={{
        fill: "none",
        stroke: "#929292",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M324.769 144.84c.75-.709 23.097-23.097 23.097-23.097h30.84"
      className="full-body"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M422.044 121.743a1.722 1.722 0 1 1-3.445.001 1.722 1.722 0 0 1 3.445-.001z"
      className="full-body"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <circle
      cx={324.769}
      cy={144.84}
      r={1.723}
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M378.769 120.808h1.87v1.87h-1.87zM381.982 120.808h1.87v1.87h-1.87zM385.196 120.808h1.87v1.87h-1.87zM388.409 120.808h1.87v1.87h-1.87z"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M471.584 104.403v3.875l2.75-1.938zM474.751 104.403v3.875l2.75-1.938zM477.918 104.403v3.875l2.75-1.938z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M489.731 57.083a6.327 6.327 0 1 1-12.654 0 6.327 6.327 0 0 1 12.654 0z"
      className="full-body"
      id='windmill'
      style={{
        fill: "none",
        stroke: "#bebebe",
        strokeWidth: 0.75,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M483.404 46.361v21.398"
      style={{
        fill: "none",
        stroke: "#bebebe",
        strokeWidth: 0.75,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m281.273 105.206 26.204-26.204h60.962"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m327.254 79.002 21.941-21.942h127.809"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M367.024 77.064v3.875l2.75-1.937zM370.191 77.064v3.875l2.75-1.937zM373.357 77.064v3.875l2.75-1.937z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M350.925 57.06a1.722 1.722 0 1 1-3.445.001 1.722 1.722 0 0 1 3.445-.001zM282.996 105.206a1.722 1.722 0 1 1-3.445.001 1.722 1.722 0 0 1 3.445-.001z"
      className="full-body"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M350.389 308.702h48.495l21.157 21.156h48.176l24.542 24.542h50.792"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m452.573 329.858 14.987-14.987h37.991l12.048-12.048 9.043 9.043h6.242"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={543.592}
      cy={354.345}
      r={2.75}
      style={{
        fill: "#cbcbcb",
      }}
    />
    <circle
      cx={350.389}
      cy={308.702}
      r={2.75}
      style={{
        fill: "#cbcbcb",
      }}
    />
    <circle
      cx={534.821}
      cy={311.908}
      r={1.938}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={539.53}
      cy={311.908}
      r={1.938}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={544.238}
      cy={311.908}
      r={1.938}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M546.176 311.908h14.854"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M561.03 313.845v-3.875l2.75 1.938zM564.196 313.845v-3.875l2.75 1.938zM567.363 313.845v-3.875l2.75 1.938z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M367.051 314.22v-3M369.467 314.22v-3M371.884 314.22v-3M374.301 314.22v-3M376.717 314.22v-3M494.759 318.935v-3M497.176 318.935v-3M499.592 318.935v-3M502.009 318.935v-3M504.426 318.935v-3"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M453.469 329.858a.896.896 0 1 0-1.792 0 .896.896 0 0 0 1.792 0z"
      className="full-body"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="m364.543 378.257 16.432-16.432h24.044"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M404.668 363.762v-3.875l2.75 1.938zM407.835 363.762v-3.875l2.75 1.938zM411.001 363.762v-3.875l2.75 1.938z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M437.086 397.759v-3M439.503 397.759v-3M441.919 397.759v-3M444.336 397.759v-3M446.753 397.759v-3"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M390.378 378.257h29.891l15.403 15.403h37.725"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M324.769 355.16c.75.709 23.097 23.097 23.097 23.097h30.84"
      className="full-body"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M422.044 378.257a1.722 1.722 0 1 0-3.445-.001 1.722 1.722 0 0 0 3.445.001z"
      className="full-body"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <circle
      cx={324.769}
      cy={355.16}
      r={1.723}
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M378.769 377.322h1.87v1.87h-1.87zM381.982 377.322h1.87v1.87h-1.87zM385.196 377.322h1.87v1.87h-1.87zM388.409 377.322h1.87v1.87h-1.87z"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M471.584 395.597v-3.875l2.75 1.938zM474.751 395.597v-3.875l2.75 1.938zM477.918 395.597v-3.875l2.75 1.938z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M489.731 442.917a6.327 6.327 0 1 0-12.654 0 6.327 6.327 0 0 0 12.654 0z"
      className="full-body"
      style={{
        fill: "none",
        stroke: "#bebebe",
        strokeWidth: 0.75,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M483.404 453.639v-21.398"
      style={{
        fill: "none",
        stroke: "#bebebe",
        strokeWidth: 0.75,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m281.273 394.794 26.204 26.204h60.962"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m327.254 420.998 21.941 21.942h127.809"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
        id='linestrka'
      d="M367.024 422.936v-3.875l2.75 1.937zM370.191 422.936v-3.875l2.75 1.937zM373.357 422.936v-3.875l2.75 1.937z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
   
      d="M350.925 442.94a1.722 1.722 0 1 0-3.445-.001 1.722 1.722 0 0 0 3.445.001zM282.996 394.794a1.722 1.722 0 1 0-3.445-.001 1.722 1.722 0 0 0 3.445.001z"
   
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
    id='linestrk'
      d="m379.625 249.991 21.54 21.54h54.603l17.317 17.317 12.997-12.997h8.971"
  
    />
    <circle
      cx={497.838}
      cy={275.791}
      r={2.785}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={504.605}
      cy={275.791}
      r={2.785}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={511.372}
      cy={275.791}
      r={2.785}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M514.157 275.791h21.349"
      style={{
        fill: "none",
        stroke: "#aeaeae",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M535.506 273.006v5.57l3.952-2.785zM540.057 273.006v5.57l3.953-2.785zM544.609 273.006v5.57l3.952-2.785z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M440.257 265.69v4.312M443.731 265.69v4.312M447.204 265.69v4.312M450.678 265.69v4.312M454.151 265.69v4.312"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="m442.665 250 17.192-17.192h43.581l13.821-13.821 10.374 10.373h7.16"
      style={{
        fill: "none",
        stroke: "#b1b1b1",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={537.016}
      cy={229.408}
      r={2.223}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={542.417}
      cy={229.408}
      r={2.223}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={547.818}
      cy={229.408}
      r={2.223}
      style={{
        fill: "none",
        stroke: "#d7d7d7",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M550.04 229.408h17.04"
      style={{
        fill: "none",
        stroke: "#aeaeae",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M567.08 231.631v-4.446l3.155 2.223zM570.713 231.631v-4.446l3.154 2.223zM574.345 231.631v-4.446l3.155 2.223z"
      style={{
        fill: "#cbcbcb",
      }}
    />
    <path
      d="M491.058 237.47v-3.442M493.831 237.47v-3.442M496.603 237.47v-3.442M499.375 237.47v-3.442M502.147 237.47v-3.442"
      style={{
        fill: "none",
        stroke: "#cbcbcb",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M360.625 249.991h238.833"
      style={{
        fill: "none",
        stroke: "#777",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M714.115 250.189c-.084 24.747-20.214 44.739-44.961 44.655-24.746-.084-44.739-20.214-44.655-44.96.084-24.747 20.214-44.74 44.96-44.655 24.747.083 44.74 20.213 44.656 44.96z"
      className="full-body"
      style={{
        fill: "#efefef",
      }}
    />
    <path
      d="M708.287 250.169c-.073 21.528-17.585 38.92-39.113 38.847-21.528-.073-38.92-17.585-38.847-39.113s17.585-38.921 39.113-38.848c21.528.074 38.92 17.585 38.847 39.114z"
      className="full-body"
      style={{
        fill: "#ddd",
      }}
    />
    <circle
    className='circleone'
      cx={669.307}
      cy={250.036}
      r={50.007}
      style={{
        fill: "none",
        stroke: "#aeaeae",
        strokeWidth: 0.5,
        strokeMiterlimit: 10,
      }}
    />
    <circle
      cx={669.307}
      cy={250.036}
      r={60.006}
      style={{
        fill: "none",
        stroke: "#bfbfbf",
        strokeWidth: 5,
        strokeMiterlimit: 10,
        strokeDasharray: "1,6",
      }}
    />
    <circle
      cx={669.307}
      cy={250.036}
      r={70.202}
      style={{
        fill: "none",
        stroke: "#aeaeae",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "0,11.9219",
      }}
    />
    <path
     id='strkz'
      d="M698.238 250.134c-.055 15.978-13.052 28.887-29.03 28.832-15.978-.055-28.887-13.051-28.832-29.03s13.052-28.887 29.03-28.833c15.978.056 28.887 13.053 28.832 29.031z"
  
      style={{
        fill: "none",
        stroke: "#aeaeae",
        strokeWidth: 0.2893,
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M691.667 250.112c-.042 12.349-10.087 22.326-22.437 22.284-12.349-.042-22.326-10.087-22.284-22.436.042-12.349 10.087-22.326 22.436-22.284 12.35.041 22.327 10.086 22.285 22.436z"
      style={{
        fill: "#c9c9c9",
      }}
    />
    <path
    id='strk'
      d="M686.079 250.093c-.032 9.263-7.566 16.746-16.829 16.715-9.263-.032-16.746-7.566-16.715-16.829s7.566-16.747 16.829-16.715c9.263.031 16.746 7.566 16.715 16.829z"
      style={{
        fill: "none",
        stroke: "#595959",
        strokeWidth: 2,
        strokeMiterlimit: 10,
        strokeDasharray: ".5,2",
      }}
    />
    <circle
      cx={669.323}
      cy={250.109}
      r={11.006}
      className="dot-center"
      style={{
        fill: "#ff5722",
      }}
    />
    <path
      d="m691.561 237.188-.638.368a24.805 24.805 0 0 1 3.349 12.479h.736a25.53 25.53 0 0 0-3.447-12.847zm-43.871 25.329a24.807 24.807 0 0 1-3.349-12.482h-.736a25.52 25.52 0 0 0 3.45 12.847l.635-.365zm9.137-34.098-.368-.638a25.886 25.886 0 0 0-9.407 9.407l.638.368a25.127 25.127 0 0 1 9.137-9.137zm12.48 46.583a24.816 24.816 0 0 1-12.482-3.349l-.366.635a25.522 25.522 0 0 0 12.847 3.45v-.736zm22.252-12.119-.635-.366a25.114 25.114 0 0 1-9.137 9.135l.368.638a25.87 25.87 0 0 0 9.404-9.407zm-9.405-35.1a25.522 25.522 0 0 0-12.847-3.45v.736c4.544 0 8.806 1.219 12.482 3.349l.365-.635zM699.891 232.38l-.877.506a34.092 34.092 0 0 1 4.603 17.15h1.012a35.11 35.11 0 0 0-4.738-17.656zm-60.292 34.809a34.096 34.096 0 0 1-4.603-17.154h-1.012c0 6.427 1.726 12.46 4.741 17.656l.874-.502zm12.557-46.861-.506-.877a35.575 35.575 0 0 0-12.928 12.928l.877.506a34.552 34.552 0 0 1 12.557-12.557zm17.151 64.018a34.107 34.107 0 0 1-17.154-4.603l-.502.873a35.075 35.075 0 0 0 17.656 4.741v-1.011zm30.581-16.654-.873-.502a34.513 34.513 0 0 1-12.557 12.554l.506.877a35.567 35.567 0 0 0 12.924-12.929zm-12.926-48.237a35.075 35.075 0 0 0-17.656-4.741v1.012a34.107 34.107 0 0 1 17.154 4.603l.502-.874z"
      style={{
        fill: "#fff",
      }}
    />
    <circle
    className='circle1'
      cx={360.625}
      cy={250}
      r={2.271}
      style={{
        fill: "#cbcbcb",
      }}
    />
    <circle
      cx={599.104}
      cy={250}
      r={2.271}
      style={{
        fill: "#cbcbcb",
      }}
    />
  </svg>

    <div class="row align-items-center">
      <div class="col-md-7 left-text">
      <h1 class="hero-head color-white"> <span class="ctrl-break fontweight600">Snap Automation</span> <span class="ctrl-break"> Testing Made Simple</span>  </h1>
   
        <p>Snap is a cloud-native lab environment for app developers, device OEMS, and telecom providers to create and test their products. In Snap, you can automate advanced use cases and easily debug on a variety of real devices. Test in a personalized lab custom configured to your specific requirements, ensuring device and application compatibility and performance across various user environments.</p>
        <Link to="/snap" class="btn btn-primary">Read More</Link>
      </div>
      <div class="col-md-5 right-text">
  <img src={snap} className='img-fluid' />
      </div>
    </div>
  </div>
</section>


<section class="bg-section-art mt-5 px-4 py-5 p-5 pb-5" >
  <div class="container">
    <div class="row align-items-center">

    <div class="col-md-5  py-5">
        <img src={fivegtext} className='img-fluid'/>
      </div>
      <div class="col-md-7  py-5">
      <h1 class="hero-head color-white"> <span class="ctrl-break-blue  fontweight600">Artemis</span> 
      <span class="ctrl-break1 d-block ctrl-break-blue"> Seamless XR Experiences on</span>
       <span class="ctrl-break-blue  last fontweight600"> 5G Networks</span>
       </h1>

        <p className='text-white'>Artemis is Procal's cutting-edge XR Network Performance Diagnostics solution, specifically designed for Extended Reality (XR) applications operating over 5G networks. This innovative platform offers real-time monitoring and comprehensive performance insights, empowering stakeholders to proactively optimize network and device performance, resulting in enhanced user experiences and unparalleled XR application reliability.</p>
        <Link to="/artemis" class="btn btn-primary">Read More</Link>
      </div>
  
    </div>
  </div>
</section>

<section class="bg-section-spot px-4 py-5 p-5 pb-5">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-7 left-text">
      <h1 class="hero-head color-white"> <span class="ctrl-break fontweight600">Spot Finder</span> <span class="ctrl-break"> Unlock Your Network's </span>       <span class="ctrl-break1 d-block ctrl-break fontweight600"> True Potential</span> </h1>
   
        <p>Spot Finder is an innovative solution developed by Procal to address the challenges faced by customers in installing Customer Premises Equipment (CPE) for 4G/5G networks. The product aims to empower customers with the ability to perform self-evaluation and installation of CPE, reducing their dependency on technical support engineers from Mobile Network Operators (MNOs) or Mobile Virtual Network Operators (MVNOs).</p>
        <Link to="/spot" class="btn btn-primary">Read More</Link>
      </div>
      <div class="col-md-5 right-text">
  {/* <img src={snansvg} className='img-fluid' /> */}
      </div>
    </div>
  </div>
</section>

      {/* <section className="px-4 p-5 pb-5 sec16">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 offset-lg-0 col-md-12 text-white ">
              <ScrollContainer snap="proximity">

                <ScrollPage page={0}>
                  <div className="col-lg-3 col-md-4 "><Animator animation={batch(MoveIn(200, 0), Fade(), MoveOut(0, -800))}><img src={snap} className='img-fluid' /></Animator></div>
                  <div className="col-lg-7 col-md-7 ">
                    <Animator animation={page1}>
                      <div style={{ textAlign: "left" }}>
                        <h3 style={{ color: "#000", fontSize: 40 }}>Snap Automation: Testing Made Simple</h3><br />
                        <span style={{ color: "#333", fontSize: 18 }}>
                          Snap is a cloud-native lab environment for app developers, device OEMS, and telecom providers to create and test their products. In Snap, you can automate advanced use cases and easily debug on a variety of real devices. Test in a personalized lab custom configured to your specific requirements, ensuring device and application compatibility and performance across various user environments.
                        </span>
                      </div>
                    </Animator>
                    
                    </div>

                </ScrollPage>
                <ScrollPage>
                  <div className="row">
                    <div className="col-lg-7 col-md-6">

                      <Animator animation={batch(MoveIn(200, 0),  MoveOut(-1000, 0))}>
                        <div style={{ textAlign: "left" }}>
                          <h3 style={{ color: "#222", fontSize: 40 }}>Advanced Features Tailored to Your Needs</h3><br />
                          <span style={{ color: "#333", fontSize: 18 }}>
                            Snap has a comprehensive suite of features designed to fit individual client requirements. Pick and choose to build the most effective testing environment for a cost-effective pricing model.
                          </span>
                          <span style={{ color: "#333", fontSize: 18 }}>
                            Snap is a cloud-native lab environment for app developers, device OEMS, and telecom providers to create and test their products. In Snap, you can automate advanced use cases and easily debug on a variety of real devices. Test in a personalized lab custom configured to your specific requirements, ensuring device and application compatibility and performance across various user environments.
                          </span>
                        </div>
                      </Animator></div>
                    <div className="col-lg-4 col-md-4">
                      <Animator animation={batch(MoveIn(200, 0), Fade(), MoveOut(0, -800))}><img src={whatsnew} className='img-fluid' />
                      </Animator></div>
                  </div>
                </ScrollPage>
                <ScrollPage>
                  <Animator animation={batch(FadeIn(), MoveOut(0, -100))}>

                    <h4 id="iUseDisnake" style={{ textAlign: "center", color: "#222" }}>
                      Artemis: Seamless XR Experiences on 5G Networks
                    </h4><br />
                    <div id="terminalDiv" className="d-flex justify-content-center">
                      <p style={{ textAlign: "center", color: "#FF5733", paddingRight: '15px' }}>Seamless XR Experiences |</p>
                      <p style={{ textAlign: "center", color: "#FF5733", paddingRight: '15px' }}>Proactive Issue Resolution |</p>
                      <p style={{ textAlign: "center", color: "#FF5733", paddingRight: '15px' }}>Optimized Network Utilization |</p>
                      <p style={{ textAlign: "center", color: "#FF5733", paddingRight: '15px' }}>Improved Device Performance</p>
                    </div>
                  </Animator>
                </ScrollPage>
                <ScrollPage>
                  <div className='row'>
                    <div className="col-lg-3 col-md-3"><Animator animation={batch(MoveIn(200, 0), Fade(), MoveOut(0, -800))}><img src={fiveg} className='img-fluid' />      </Animator></div>

                    <div className="col-lg-5 offset-lg-1 col-md-6">          <Animator animation={batch(Fade(), MoveOut(0, 200))}><h5 style={{ color: "#222", fontSize: 30 }}>
                      Artemis: Seamless XR Experiences on 5G Networks
                    </h5>
                      <br />
                      <span style={{ color: "#222", fontSize: 18 }}>
                        Artemis is Procal's cutting-edge XR Network Performance Diagnostics solution, specifically designed for Extended Reality (XR) applications operating over 5G networks. This innovative platform offers real-time monitoring and comprehensive performance insights, empowering stakeholders to proactively optimize network and device performance, resulting in enhanced user experiences and unparalleled XR application reliability.
                      </span>
                    </Animator>
                    </div>
                  </div>
                </ScrollPage>

              </ScrollContainer>
            </div>

          </div>
        </div>


      </section> */}


      <Footer />
    </>
  )
}

export default Products
