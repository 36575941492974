import React from 'react';
import Menu from '../../common/Menu';
import  { useEffect } from 'react';
import Footer from '../../common/Footer'
import './telecom.css';
import tone from './../../images/tone.png'
import sme from './../../images/smes.png'
import emerg from './../../images/emreg-tech.avif'
import auto from './../../images/automation-left.jpg'
import iphone from './../../images/iphone-mock.png'
import telcometone from './../../images/telecom-tone.png'
import Location from '../../common/contact/Location';
import cybersml from './../../images/lock.jpg'
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Map from '../../subpages/Map';
import Book from './book';
function Cybers() {


    
  
return (
<>
<Helmet>
<title>Cyber Security Services in USA, UK, UAE | IT Security Solutions</title>
<meta name="description" content="Procal offers cybersecurity consulting services & solutions in USA, UK, UAE, to embed security across your ecosystem to reduce risk in the future by securing today" />
<meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Cyber Security Services in USA, UK, UAE | IT Security Solutions" />
	<meta property="og:description" content="Procal offers cybersecurity consulting services &amp; solutions in USA, UK, UAE, to embed security across your ecosystem to reduce risk in the future by securing today" />
	<meta property="og:url" content="https://www.procaltech.com/solutions/cybersecurity/" />
	<link rel="canonical" href="https://www.procaltech.com/solutions/cybersecurity/" />
	<meta property="og:site_name" content="Procal Technologies" />

  </Helmet>


<Menu />



<section className='telecom-sec-6 pt100 pb-5' id='cybersecurity'>
<div className='container'>
</div>
<div class="container">
<div class="row d-flex align-items-center">
<div class="col-md-7 col-lg-6">
<div class="txt-block right-column white-color wow fadeInLeft">
<h2 class="h2-xs" >
Cybersecurity Solution Overview</h2>
<h5>Procal's cybersecurity solutions redefine the landscape of digital security, harnessing state-of-the-art technologies to fortify your organization against evolving cyber threats. We offer a holistic range of services, from governance risk and compliance to data privacy and protection, enabling businesses to navigate the complex cybersecurity terrain with confidence. With Procal's cybersecurity solutions, your digital assets are shielded, compliance is assured, and your organization is primed to excel in an increasingly digital and secure world.
</h5>
<button class="pill" type="button"><Link to="/vulnerability">Vulnerability Management</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/solutions/managed-security-services/">Managed Security Services</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/threat-Intelligence">Threat Intelligence</Link></button>
<button class="pill" type="button"><Link to="/end-point-security/">End Point Security</Link></button>
<button class="pill pill--selected4" type="button">
<Link to="/application-security/">Application Security</Link></button>
<button class="pill" type="button"><Link to="/server">Server Security</Link></button>


<button class="pill" type="button"><Link to="/solutions/penetration-testing/">Penetration</Link></button>
<button class="pill" type="button"><Link to="/solutions/ransomware-health-checks/">Ransomware Healthchecks</Link></button>
<button class="pill" type="button"><Link to="/solutions/security-frameworks-compliance/">Security Framework and Compliance</Link></button>

</div>
</div>
<div class="col-md-5 col-lg-6">
<div class="rel img-block left-column video-preview wow fadeInRight imgadjust"  >
<img src={cybersml} alt="video-preview" class="img-fluid" />
</div>
</div>

</div>
</div>
</section>

<Book/>

<Footer />
</>
)
}

export default Cybers
