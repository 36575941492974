import React from 'react';
import Menu from '../../common/Menu';
import girl from './../../images/enhancing-iot.jpg'
import Footer from '../../common/Footer'
import './../casestudy/case.css'

function Enhancing () {


  
return (
  <>
  <Menu/>



  <div className="container-case section-zqn">

  {/* Left Side (Image and Heading) */}
  <div className="left-section">

    <img
      src={girl}
      alt="Team Working"
    />
  </div>
  {/* Right Side (Text Content and Button) */}
  <div className="right-section">
    <p>Case Study</p>
    <h2>Enhancing Environmental Management through Procal's IoT Sensor Solution</h2>
    <p className="description-case">
    The Environmental Protection Agency (EPA) sought AT&T’s expertise to explore the potential of IoT sensor data in monitoring air quality, noise levels, and weather conditions in real-time.
    </p>
    <p>Revolutionizing Manhattan Traffic: A 5G/IoT Integration Success Story</p>


<div>
<svg width="51" height="46" viewBox="0 0 51 46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.256 40.981 1.2 27.313a5.05 5.05 0 0 1-.7-2.563v-3.802c0-.944.264-1.868.76-2.667L9.97 4.284a5.01 5.01 0 0 1 1.568-1.583l3.044-1.93A4.954 4.954 0 0 1 17.235 0h16.903c.939 0 1.858.267 2.653.771l3.007 1.907c.658.417 1.21.983 1.612 1.654l8.373 13.975c.47.783.717 1.68.717 2.595v3.8a5.06 5.06 0 0 1-.743 2.639l-8.48 13.83c-.316.516-.723.97-1.2 1.34l-3.16 2.448A4.958 4.958 0 0 1 33.88 46H17.32a4.96 4.96 0 0 1-2.782-.855l-3.779-2.563a5.006 5.006 0 0 1-1.502-1.6ZM34.138 2H17.236c-.56 0-1.108.159-1.583.46L12.61 4.39c-.38.24-.701.565-.94.95L2.96 19.338l-1.685-1.049 1.685 1.049a3.04 3.04 0 0 0-.459 1.61v3.802c0 .545.146 1.08.422 1.547l8.057 13.669c.227.385.535.713.901.961l3.78 2.563c.491.333 1.069.51 1.659.51h16.56c.654 0 1.291-.218 1.813-.621l3.158-2.448c.286-.222.53-.494.721-.805l8.48-13.83c.292-.478.448-1.03.448-1.593v-3.801c0-.553-.15-1.095-.433-1.567L39.694 5.36a3.001 3.001 0 0 0-.967-.993L35.72 2.46A2.954 2.954 0 0 0 34.138 2Z" fill="#0058DD"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M32.707 22.793 26 29.5a1 1 0 0 1-1.414-1.414l4.293-4.293h-9.586a1 1 0 1 1 0-2h9.586L24.586 17.5A1 1 0 0 1 26 16.086l6.707 6.707Z" fill="#0058DD"></path> </svg> <span>Previous Project</span></div>
  </div>
</div>


<div class="container container-case-1 right-section">
        <div class="row">
            <div class="col-md-6 ">
       
                <h2 className="gb-headline-6nzf">Challenge</h2>
                <p className="col-oe2f description-case" style={{ fontSize: 16 }}>The Environmental Protection Agency (EPA) sought AT&T’s expertise to explore the potential of IoT sensor data in monitoring air quality, noise levels, and weather conditions in real-time. The goal was to leverage 4G/5G networks to provide actionable data for environmental agencies, enabling informed decision-making by city authorities to enhance overall environmental quality. The vision included utilizing real-time alerts from IoT sensors to guide drivers, suggest alternative routes during air pollution spikes, and reduce carbon emissions in affected areas. Furthermore, the data aimed to improve public transportation connectivity and identify water quality issues in water plants, ensuring better household water quality.
                </p>

              
            </div>
            <div class="col-md-6">
           
<h2 className="gb-headline-6nzf">Achievements and Impact</h2>
<p className="col-oe2f description-case" style={{ fontSize: 16 }}>The partnership between Procal and AT&T showcased the significant advantages of deploying 5G-enabled IoT sensors for the EPA. These sensors effectively collected crucial real-time data, enabling rapid responses to environmental issues. The enhanced capabilities contributed substantially to improved environmental management and sustainability for the agency. Procal’s solution not only addressed the immediate challenges presented by air pollution, noise, and water quality issues but also provided a foundation for proactive decision-making and long-term environmental planning. The success of this collaboration demonstrated the potential of cutting-edge technology in creating a more sustainable and resilient environment.</p>


            </div>

            <div class="col-md-12 mt-5 casebox">     

            <h2 className="gb-headline-6nzf">Procal's Solution</h2>
                <p className="col-oe2f description-case" style={{ fontSize: 16 }}>
                Collaborating with AT&T, Procal played a pivotal role in developing an innovative Environmental Alert Dashboard, utilizing data collected from IoT sensors. This solution became instrumental in addressing environmental concerns by offering real-time insights into critical factors such as air quality, noise levels, and weather conditions. Procal’s unified environmental analytics and alert system portal empowered proactive analytics, allowing for precise real-time monitoring of environmental parameters. The platform facilitated swift responses to air quality concerns surpassing defined levels, addressed traffic congestion to reduce emissions, and monitored water quality to detect pollutants and contaminants. The Environmental Alert Dashboard emerged as a comprehensive platform, providing a unified view for all environmental-related activities.
                </p>
   

</div>
        </div>
    </div>
{/* <section className="container-rom container-case-1 ali-fxl">

  

  <div className="container-d1s">
    <div className="dd">
      <div className="container-d1s">
        <div className="wrapper-ti2">
          <div className="column-o8o column-fhi">
            <section className="1container-case-1">
         
              <div>
      
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>


</section> */}
  <Footer/>


  </>
)
}

export default Enhancing
