import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import logowhite from './../images/footer-logo.svg';
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
import iphone from './../images/oursolution-icon-footer.png'
function SolutionLeads() {
  return (
    <footer className="footer-section">

<div className="copyright-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 text-center text-lg-left">
           
            </div>

            <div className="col-lg-3 offset-lg-0 offset-lg-1 d-none d-lg-block text-right mt-3 img24">

            <div>
            <img src={iphone}  classname="x-auto "/>
        <span class="text-lg tw-text-white inline-block align-middle ml-2 tw-text-3xl "> Procal Solution</span>
    </div>
   <div className='align-center'> <span>  </span>   </div>
         
             
              </div>
            <div className="col-xl-8 col-lg-8 offset-lg-0 d-none d-lg-block text-right mt-3">
            <Link to="/solutions/cybersecurity-leads"><span className="tw-bg-blue-100 tw-text-blue-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-blue-900 dark:tw-text-blue-300">
            Cybersecurity Leads
</span></Link>

<Link to="/solutions/automation-testing-leads"><span className="tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-green-900 dark:tw-text-green-300 mt5">
Automation Testing Leads
</span></Link>

<Link to="/solutions/emerging-tech-leads"><span className="tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-red-900 dark:tw-text-red-300">
Emerging Tech Leads
</span></Link>
<div className='clear-fix mt-4'></div>
<Link to="/solutions/telecom-leads"><span className="tw-bg-gray-100 tw-text-gray-800 tw-text-xs tw-font-medium tw-me-2 tw-px-5 tw-py-3 tw-rounded-full dark:tw-bg-gray-700 dark:tw-text-gray-300">
Telecom Leads
</span></Link>
<Link to="/solutions/data-analytics-insights-leads"><span className="tw-bg-yellow-100 tw-text-yellow-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-yellow-900 dark:tw-text-yellow-300 mt-3">
Data Analytics and Insights Leads
</span></Link>
<Link to="/solutions/digital-transformation-leads"><span className="tw-bg-indigo-100 tw-text-indigo-800 tw-text-xs tw-font-medium tw-me-2 tw-px-2.5 tw-py-3 tw-rounded-full dark:tw-bg-indigo-900 dark:tw-text-indigo-300 mt-3">
Digital Transformation Solutions
</span></Link>



                </div>
          </div>
        </div>
      </div>
 
    </footer>
  )
}

export default SolutionLeads
