import React from 'react'
import { Animator, ScrollContainer, ScrollPage,page, batch, Fade, FadeIn, FadeOut,
  Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import Footer from '../../common/Footer';
import './company.css';
import Menu from '../../common/Menu';

function Company() {

  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());
  
return (
  <>
  <Menu/>
<div className='exact'>
<ScrollContainer snap="proximity">
    <ScrollPage page={0}>
      <Animator animation={batch(Sticky(), Fade())}>
        <h1 style={{ fontSize: 50, opacity:0 }}>We want to hear from you !!</h1>
      </Animator>
    </ScrollPage>
    <ScrollPage page={1}>
      <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
        <span style={{ fontSize: 25 }}>
        Fill out our form  😀
        </span>
      </Animator>
    </ScrollPage>
    <ScrollPage page={2}>
      <Animator animation={ZoomInScrollOut}>
        <span style={{ fontSize: 40 }}>and a Procal expert will reach out to you</span>
      </Animator>
    </ScrollPage>
    <ScrollPage page={3}>
      <div>
      <Animator animation={FadeUp}>
        {/* <span style={{ fontSize: 40 }}>I'm FadeUp ⛅️</span> */}
        <div id="feedback-form">
  <h2 class="header">Contact Today</h2>
  <div>
    <form>
    <input type="text" name="name" placeholder="FirstaName" />
    <input type="text" name="lname" placeholder="LastName" />
        <input type="text" name="email" placeholder="Email" />


      <input type="text"  name="phone" placeholder="Phone number" />
      <textarea name="feedback" rows="4" cols="50"></textarea>
      <button type="submit">Register</button>
    </form>
  </div>
</div>
      </Animator>
      </div>
    </ScrollPage>

    {/* <ScrollPage className='mt50'>
      <Animator animation={batch(Fade(), Sticky())}>
        <span style={{ fontSize: 40 }}>Done</span>
        <br />
        <span style={{ fontSize: 30 }}>
          There's FadeAnimation, MoveAnimation, StickyAnimation, ZoomAnimation
        </span>
      </Animator>
    </ScrollPage> */}

    {/* <ScrollPage>
      <Animator animation={batch(Fade(), Sticky())}>
        <span style={{ fontSize: 40 }}>Our Locations</span>
        <br />
        <span style={{ fontSize: 30 }}>
        Our diverse team brings a world of unique perspectives and niche capabilities to every project we undertake, serving clients worldwide with unparalleled dedication and excellence. Discover how our global presence enables us to deliver tailor-made solutions that transcend borders and exceed expectations.
        </span>
      </Animator>
    </ScrollPage> */}
  </ScrollContainer>

  <section className='px-4 vh100'>

<div className='container-sanp'>
  <div className='flex-container-snap p-5'>

    <div><h2>Snap Automation: Testing Made Simple</h2></div>
  </div>
  <div className='flex-container-snap pb-5'>


    <div class="cookieCard">
      <p class="cookieHeading">Comprehensive Testing</p>
      <p class="cookieDescription">Snap provides end-to-end manual or automated testing across a vast range of devices, networks, and locations, ensuring maximum coverage and reliable app performance.</p>

    </div>
    <div class="cookieCard">
      <p class="cookieHeading">Faster Time to Market</p>
      <p class="cookieDescription">By streamlining testing processes and offering actionable insights, Snap helps accelerate the development cycle, enabling faster delivery of high-quality apps on Android or iOS devices.</p>

    </div>

    <div class="cookieCard">
      <p class="cookieHeading">Security and Compliance</p>
      <p class="cookieDescription">Snap prioritizes security, providing secure testing environments and helping identify vulnerabilities, ensuring apps meet industry standards and compliance requirements.</p>

    </div>

    <div class="cookieCard">
      <p class="cookieHeading">Global Testing Infrastructure</p>
      <p class="cookieDescription">Snap’s extensive global network and one of a kind portable solution allows testing from various geographical locations, ensuring device/application compatibility and performance across diverse user environments.</p>

    </div>



  </div>
</div>
</section>

  <Footer/>
  </div>

  </>
)
}

export default Company
