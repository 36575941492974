import axios from 'axios'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { avatar } from './avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function Author() {
    const authorModel = {
        name: '',
        designation: '',
        profilePicture: avatar,
        bioText: '',
        socialMediaLink: '',
    }
    const [author, setAuthor] = useState([]);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');

    const fileToDataUri = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            resolve(event?.target?.result)
        };
        reader.readAsDataURL(file);
    })

    const handleChange = (event) => {
        const name = event.target.name;
        if (name === 'name') {
            authorModel.name = event.target.value;
        } else if (name === 'designation') {
            authorModel.designation = event.target.value;
        } else if (name === 'bioText') {
            authorModel.bioText = event.target.value;
        } else if (name === 'socialMediaLink') {
            authorModel.socialMediaLink = event.target.value;
        } else if (name === 'profilePicture') {
            if(event.target.files[0]) {
                fileToDataUri(event.target.files[0]).then(dataUri => {
                    authorModel.profilePicture = dataUri;
                })
            }
        }
    }
    const handleCreate = () => {
        axios.post(`${process.env.REACT_APP_BASE_URL}/addauthor`, authorModel).then(res => {
            loadAuthor();
            setMessage(res.data.message);
            setError(false);
        })
    }
    const loadAuthor = () => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/allauthor?limit=6`).then(res => {
            setAuthor([...res.data]);
        })
    }
    const handleDelete = (ca) => {
        const payload = {
            params: {
                id: ca?._id
            }
        }
        axios.delete(`${process.env.REACT_APP_BASE_URL}/removeauthor`, payload).then(res => {
            loadAuthor();
            setMessage(res.data.message);
            setError(false);
        })
    }
    useEffect(() => {
        loadAuthor()
    }, [])

    return (
        <div className='container-fluid p-0'>
            {/* <div className="bg-light pt-2 ps-5" style={{ height: '60px' }}>
                <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
            </div> */}
            <div className="row ">
                <div className="col-10 shadow-sm ">
                    {/* <div className="mx-auto mb-5" style={{width: '200px'}}>
                    <img src={require('../../assets/img/logo-blue-fnl.jpg')} />
                    </div> */}
                    {message && !error && (<div className='alert alert-success' role="alert">{message}</div>)}
                    <h5 className="card-title fw-bold pb-3">Add Author</h5>
                    <form >
                        <div className="mb-0">
                            <label htmlFor="exampleInputEmail1" className="form-label">Author Name</label>
                            <input onChange={handleChange} name='name' type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-0">
                            <label htmlFor="exampleInputEmail1" className="form-label">Designation</label>
                            <input onChange={handleChange} name='designation' type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-0">
                            <label htmlFor="exampleInputEmail1" className="form-label">About Author</label>
                            <input onChange={handleChange} name='bioText' type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-0">
                            <label htmlFor="exampleInputEmail1" className="form-label">Social Link</label>
                            <input onChange={handleChange} name='socialMediaLink' type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-0">
                            <label htmlFor="exampleInputEmail1" className="form-label">Avatar</label>
                            <div className="input-group">
                                <input onChange={handleChange} type="file" name="profilePicture" multiple />
                                {/* <span className="input-group-btn">
                                    <span className="btn btn-file " id='custom-button'>
                                        Browse <input onChange={handleChange} type="file" name="bannerImg" multiple />
                                    </span>
                                </span> */}
                                <input type="text" className="form-control" readOnly />
                            </div>
                        </div>
                        <button type="button" onClick={handleCreate} className="btn btn-primary col-3">Submit</button>
                    </form>
                    <div className="card mt-5">
                        <div className="card-header fw-bold">
                            All Author
                        </div>
                        <ul className="list-group list-group-flush">
                            {author.map(au => (
                                <li key={au._id} className="list-group-item d-flex justify-content-between align-items-center">{au.name} <span className="badge bg-primary rounded-pill" style={{cursor:'pointer'}} onClick={() => handleDelete(au)}><FontAwesomeIcon icon={faTrash} /></span></li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="col"></div>
            </div>

        </div>
    )
}

export default Author