import React, { useState } from "react";
import logo from './../images/logo-350.png';
import { BrowserRouter as Router, Switch, Route, Routes, Link } from "react-router-dom";
function Menu() {
  const imgwidth = {
    width: '190px',
    height: '30px'
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <>

    {/* only mobile version nav */}
    <nav className="tw-bg-grey-800  tw-text-white d-block d-sm-none tw-clear-both">
      <div className="tw-container tw-p-4 inline-grid">
        <div className="tw-float-left tw-inset-x-0.5">
        <Link to="/"><img src={logo} style={imgwidth}/></Link>
        </div>
    
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="tw-md:hidden tw-p-2 tw-rounded-md tw-bg-gray-700 tw-text-black hover:tw-bg-gray-600 tw-float-right"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-6 tw-w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Full Navigation Menu */}
        <div
          className={`${isMenuOpen ? "tw-flex" : "tw-hidden"
            } tw-w-full tw-flex-col tw-space-y-2 tw-md:flex tw-md:flex-row tw-md:space-y-0 tw-md:space-x-6`}
        >
          <ul className="tw-w-full tw-flex tw-flex-col tw-items-start tw-md:flex-row tw-md:items-center tw-md:space-x-4">
            {/* <li className="tw-text-black">
<a href="#" className="tw-hover:text-gray-300 ">
Company
</a>
</li> */}
       {/* new dropdown 1 */}

       <li className="tw-relative tw-group">
              <button
                className="tw-flex tw-items-center tw-text-black tw-w-full tw-py-2 tw-text-left tw-md:text-center"
                onClick={() => handleDropdown(1)}
              >
                <strong>Company</strong>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-4 tw-w-4 tw-ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {/* First Level Dropdown */}
              <ul
                className={`${openDropdown === 1 ? "tw-flex tw-flex-col" : "tw-hidden"
                  } tw-md:absolute tw-w-full tw-md:w-auto  tw-rounded-md tw-space-y-2 tw-md:tw-space-y-0 tw-p-2`}
              >
                <li>
                <Link to="/who-we-are/">Who We Are</Link>
                </li>


                <li>
              

                  <Link to="/what-we-do/" >  What We Do</Link>
                </li>


                <li>
                <Link to="/our-industries" >
                    Industries</Link>
                
                </li>
                <li className="tw-relative">
                <a
                    href="#"
                    className="tw-hover:text-gray-300 tw-block"
                  >
                    <Link to="/ourclient">Clients</Link>
                
                  </a>
                  {/* Second Level Dropdown */}

                </li>
                <li>
              
              <Link to="/announcement">Announcements</Link>

             
                </li>
              </ul>
            </li>

   {/* new dropdown 2 */}
       <li className="tw-relative tw-group">
              <button
                className="tw-flex tw-items-center tw-text-black tw-w-full tw-py-2 tw-text-left tw-md:text-center"
               
              >
                      <Link to="/products"><strong>Products</strong>    </Link>
          
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-4 tw-w-4 tw-ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  onClick={() => handleDropdown(2)}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
            
              </button>
              {/* First Level Dropdown */}
              <ul
                className={`${openDropdown === 2 ? "tw-flex tw-flex-col" : "tw-hidden"
                  } tw-md:absolute tw-w-full tw-md:w-auto  tw-rounded-md tw-space-y-2 tw-md:tw-space-y-0 tw-p-2`}
              >
                <li>
              
                    <Link to="/products/snap-automation/">Snap Automation</Link>
               
                
                </li>


                <li>
            
                  <Link to="/products/artemis/">Artemis</Link>
               
                </li>


                <li>
                
                    <Link to="/products/spot-finder/">Spot Finder</Link>
                 
               
                </li>
         
         
              </ul>
            </li>

      
         {/* new dropdown 3  */}
         <li className="tw-relative tw-group">
              <button
                className="tw-flex tw-items-center tw-w-full tw-py-2 tw-text-left tw-text-black tw-md:text-center"
               
              >
                <Link to="/solution"><strong>Solutions</strong>  </Link>
              
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-4 tw-w-4 tw-ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  onClick={() => handleDropdown(3)}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {/* First Level Dropdown */}
              <ul
                className={`${openDropdown === 3 ? "tw-flex tw-flex-col" : "tw-hidden"
                  } tw-md:absolute tw-w-full tw-md:w-auto tw-rounded-md tw-space-y-2 tw-md:tw-space-y-0 tw-p-2 tw-max-h-64 tw-overflow-y-auto`}
              >
        
      
           
              </ul>


              <ul
                className={`${openDropdown === 3 ? "tw-flex tw-flex-col" : "tw-hidden"
                  } tw-md:absolute tw-w-full tw-md:w-auto tw-rounded-md tw-space-y-2 tw-md:tw-space-y-0 tw-p-2 tw-max-h-64 tw-overflow-y-auto`}
              >

<li  className="tw-relative">
                
<Link to="/solutions/automation-testing">    <h6 className="titleN">Automation Testing</h6></Link>
            
              
                </li>
                <li>
                <li>
                <Link to="/solutions/robot-processing-automation/" className="tw-hover:text-gray-300 tw-block tw-py-2">  Robot Processing Automation</Link>
                </li>
                 
                  {/* Second Level Dropdown */}
            
                    <li>
              
                        <Link to="/solutions/automation-and-remote-testing/" className="tw-hover:text-gray-300 tw-block tw-py-2"> Test Automation and Remote Testing</Link>
                       
                     
                    </li>
          
                
                </li>
                <li>
             
                    <Link to="/solutions/cyber-security" className="tw-block tw-py-2 tw-underline tw-font-bold"><strong>Cyber Security</strong></Link>
                 
                 
                </li>
                <li className="tw-relative">
            

<Link to="/application-security/" className="tw-hover:text-gray-300 tw-block tw-py-2 tw-line-through">Application Security</Link>
                    
                   
                  {/* Second Level Dropdown */}
            
                    <li >
                    
                        <Link to="/end-point-security/" className="tw-hover:text-gray-300 tw-block tw-py-2"> End Point Security</Link>
                      
                 
                    </li>

                    <li>
                  

                       <Link to="/solutions/managed-security-services/" className="tw-hover:text-gray-300 tw-block tw-py-2">Managed Security Services</Link>
                      
                    </li>

                    <li>
                    
                      
                       <Link to="/solutions/penetration-testing/" className="tw-hover:text-gray-300 tw-block tw-py-2"> Penetration Testing</Link>
                    
                    </li>

                    <li>
                    
                        <Link to="/threat-Intelligence" className="tw-hover:text-gray-300 tw-block tw-py-2"> Threat Intelligence</Link>
                    
                    
                    </li>
                    <li>
                     
                        <Link to="/vulnerability" className="tw-hover:text-gray-300 tw-block tw-py-2"> Vulnerability Management</Link>
                      
                    
                    </li>
                    <li>
                   
                        <Link to="/solutions/ransomware-health-checks/" className="tw-hover:text-gray-300 tw-block tw-py-2"> Ransomware Health Checks</Link>
                    
                   
                    </li>
                    <li>
                    
                        <Link to="/solutions/security-frameworks-compliance/">Security Frameworkds and Compliance</Link>
                   
                
                    </li>
             
                    <li>
               
                   
                    <Link to="/solutions/data-analytics-and-insights" className="tw-hover:text-gray-300 tw-block tw-py-4"><strong>Data Analytics And Insights</strong></Link>
                 
                </li>
                <li className="tw-relative">
          
                        <Link to="/solutions/ai-ml-insights/" className="tw-hover:text-gray-300 tw-block tw-py-2"> AI| ML</Link>
              
              
                  {/* Second Level Dropdown */}
            
                    <li>
                   
                        <Link to="/solutions/big-data-analytics/" className="tw-hover:text-gray-300 tw-block tw-py-2"> Big Data Analytics</Link>
           
                     
                    </li>

                    <li>
                     
                        <Link to="/solutions/network-analytics/" className="tw-hover:text-gray-300 tw-block tw-py-2"> Network Analytics</Link>
                   
                   
                    </li>

                    <li>
                     
                        <Link to="/solutions/sport-analytics/" className="tw-hover:text-gray-300 tw-block tw-py-2">   Sport Analytics</Link>
                   
                    
                    </li>

           

          
                
                </li>

                <li>
                
                    <Link to="/solutions/emerging-technologies"  className="tw-hover:text-gray-300 tw-block tw-text-black tw-py-4"><strong>Emerging Tech XR/AR/VR</strong></Link>
                
                </li>

                <li className="tw-relative">
         
              
              <Link to="/solutions/automotive/" className="tw-hover:text-gray-300 tw-block tw-text-black tw-py-4">Automotive</Link>
              
                  {/* Second Level Dropdown */}
            
                    <li>
                 
           
           <Link to="/solutions/drones/" className="tw-hover:text-gray-300 tw-block tw-text-black tw-py-4">  Drones</Link>
                 
                    </li>

                    <li>
               

                      <Link to="/hardware-engg" className="tw-hover:text-gray-300 tw-block tw-text-black tw-py-4"> Hardware Engineering</Link>
                    </li>

                    <li>
            

                      <Link to="/solutions/robotics/" className="tw-hover:text-gray-300 tw-block tw-text-black tw-py-4"> Robotics</Link>
               
                    </li>

           
                    <li>
               
                  


                   <Link to="/solutions/xr-ar-vr/"> XR/AR/VR</Link>
                   
                    </li>
          
                
                </li>

                <li>
                
                    <Link to="/solutions/telecom" className="tw-hover:text-gray-300 tw-block tw-text-black tw-py-4"><strong>Telecom</strong></Link>
                   
               
                </li>
                <li className="tw-relative">
              
                        <Link to="/solutions/cloud/"   className="tw-hover:text-gray-300 tw-block tw-py-2">Cloud</Link>
              
                  
                  {/* Second Level Dropdown */}
            
                    <li>
                 
                        <Link to="/solutions/5g/" className="tw-hover:text-gray-300 tw-block tw-py-2">5G Solutions</Link>
           
                     
                    </li>

                    <li>
                   
                        <Link to="/solutions/esim/" className="tw-hover:text-gray-300 tw-block tw-py-2">eSIM</Link>
            
                 
                    </li>

                    <li>
                  
                        <Link to="/solutions/iot/" className="tw-hover:text-gray-300 tw-block tw-py-2"> IoT</Link>
                     
                     
                    </li>

           
                    <li>
                  
                        <Link to="/solutions/mobile-os/">Mobile OS</Link>
                   
                   
                    </li>

                    <li>
                  
                        <Link to="/solutions/private-networks/" className="tw-hover:text-gray-300 tw-block tw-py-2">Private Networks</Link>
             
                
                    </li>
                    <li>
                   
                        <Link to="/solutions/slicing/"  className="tw-hover:text-gray-300 tw-block tw-py-2">Slicing</Link>
              
                    
                    </li>
                    <li>
                  
                        <Link to="/solutions/smart-city/" className="tw-hover:text-gray-300 tw-block tw-py-2"> Smart City</Link>
               
                   
                    </li>
          
                
                </li>

                <li>
                
                    <Link to="/solutions/fintech" className="tw-hover:text-gray-300 tw-block tw-text-black tw-py-4 divide-x-4"><strong>Fintech</strong></Link>
                 
             
                </li>


                <li className="tw-relative">
          
                        <Link to="/solutions/building-operational-resilience/"  className="tw-hover:text-gray-300 tw-block tw-py-2">Building Operational Resilience</Link>
         
                  {/* Second Level Dropdown */}
            
                    <li>
                   
                        <Link to="/solutions/ffiec-assessment/"  className="tw-hover:text-gray-300 tw-block tw-py-2"> FFIEC Assessment</Link>
         
                   
                    </li>

                    <li>
                  
                        <Link to="/solutions/financial-services/" className="tw-hover:text-gray-300 tw-block tw-py-2">Financial Services</Link>
           
                    </li>

                    <li>
                   
                        <Link to="/solutions/regulatory-remediation/"   className="tw-hover:text-gray-300 tw-block tw-py-2">Regulatory Remediation</Link>
                     
                 
                    </li>

           
                    <li>
                    
                        <Link to="/solutions/robust-controls-design/" className="tw-hover:text-gray-300 tw-block tw-py-2">Robust Controls Design</Link>
                  
                  
                    </li>

                    <li>
                   
                        <Link to="/digitalbanking" className="tw-hover:text-gray-300 tw-block tw-py-2">    Digital Banking</Link>
       
              
                    </li>
                    <li>
                  
                        <Link to="/solutions/technology-modernization-and-automation/"  className="tw-hover:text-gray-300 tw-block tw-py-2">Technology Modernization and Automation</Link>
            
                    </li>
      
          
                
                </li>

                </li>
           
              </ul>

              {/* level 3 */}


        
            </li>

        
        
    {/* new dropdown 3 */}

    {/* new dropdown insight */}
    <li className="tw-relative tw-group">
              <button
                className="tw-flex tw-items-center tw-text-black tw-w-full tw-py-2 tw-text-left tw-md:text-center"
                onClick={() => handleDropdown(4)}
              >
                <strong>Insights</strong>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="tw-h-4 tw-w-4 tw-ml-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {/* First Level Dropdown */}
              <ul
                className={`${openDropdown === 4 ? "tw-flex tw-flex-col" : "tw-hidden"
                  } tw-md:absolute tw-w-full tw-md:w-auto  tw-rounded-md tw-space-y-2 tw-md:tw-space-y-0 tw-p-2`}
              >
                <li>
              
                    <Link to="/blog" className="tw-hover:text-gray-300 tw-block tw-py-2">Blog</Link>
                
                </li>


                <li>
               
                    <Link to="/careers" className="tw-hover:text-gray-300 tw-block tw-py-2"> Careers</Link>
                  
                 
                </li>


                <li>
                
                    <Link to="/caselisting" className="tw-hover:text-gray-300 tw-block tw-py-2"> Case Studies</Link>
                  
                
                </li>
       

              </ul>
            </li>

            <li className="tw-relative tw-group">
              <button
                className="tw-flex tw-items-center tw-text-black tw-w-full tw-py-2 tw-text-left tw-md:text-center"
      
              >
                <Link to="/contact-us"> <strong>Contact Us</strong></Link>
               

              </button>
              {/* First Level Dropdown */}
              <ul
                className={`${openDropdown === 12 ? "tw-flex tw-flex-col" : "tw-hidden"
                  } tw-md:absolute tw-w-full tw-md:w-auto  tw-rounded-md tw-space-y-2 tw-md:tw-space-y-0 tw-p-2`}
              >
              
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    {/* mobile version end */}

<nav className="navbar navbar-expand-lg top-nav fixed-top d-lg-block d-none" id='x'>
    <div className="container-fluid">
    <Link to="/"><img src={logo} /></Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#main_nav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse 1align-items-center justify-content-end" id="main_nav">
        <ul className="navbar-nav 1mx-lg-auto 1navbar-center">
        <li className="nav-item dropdown has-megamenu">
            <a
              className="nav-link dropdown-toggle animate__animated animate__bounce"
              href="#"
             data-bs-hover="dropdown"
            >
              Company
        
            </a>
            <div className="dropdown-menu megamenu 1bgwhitemenu" role="menu">
              <div className="row g-3">
                <div className="col-lg-2  offset-lg-7 col-12 p0">
                  <div className="col-megamenu border-top-left border-bottom-left">
                    <h6 className="titleN">  <Link to="/who-we-are/">Who We Are</Link></h6>
                    <h6 className="titleN"><Link to="/what-we-do/">What We Do</Link></h6>
                    {/* <hr className='new2'/> */}
                    <ul className="list-unstyled">
                      
                 
                  
                    <li>
                         <Link to="/our-industries">Industries</Link>
                      </li>
                      <li>
                      <Link to="/ourclient">Clients</Link>
                      </li>
                      <li>
                      <Link to="/announcement">Announcements</Link>
                      </li>
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>
     
                {/* end col-3 */}

               
                {/* end col-3 */}
              </div>
              {/* end row */}
            </div>{" "}
            {/* dropdown-mega-menu.// */}
          </li>
   
          <li className="nav-item dropdown has-megamenu">
            <a
              className="nav-link dropdown-toggle"
              
               data-bs-hover="dropdown"
            >
             
             <Link to="/products">Products</Link>
            </a>
            <div className="dropdown-menu megamenu dropdown 1bgwhitemenu" role="menu">
              <div className="row g-3">
                <div className="col-lg-2  offset-lg-8 col-12 p0">
                  <div className="col-megamenu border-top-right border-top-left border-bottom-left border-bottom-right">
                 
                    
              
                    <ul className="list-unstyled">
                      
                      <li>
                         <Link to="/products/snap-automation/">Snap Automation</Link>
                      </li>
                      <li>
                         <Link to="/products/artemis/">Artemis</Link>
                      </li>
                      <li>
                         <Link to="/products/spot-finder/">Spot Finder</Link>
                      </li>
                      {/* <Link to="/cocreate"> Cocreate
                      </Link> */}
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>
              
        
                {/* end col-3 */}

               
                {/* end col-3 */}
              </div>
              {/* end row */}
            </div>{" "}
            {/* dropdown-mega-menu.// */}
          </li>
       
          <li className="nav-item dropdown has-megamenu">
            <a
              className="nav-link dropdown-toggle"
              href="#"
               data-bs-hover="dropdown"
            >
             
             <Link to="/solution">Solutions</Link>
            </a>
            <div className="dropdown-menu megamenu 1bgwhitemenu" role="menu">
              <div className="row g-3">

              <div className="col-lg-2 col-12 p0 ">
                  <div className="col-megamenu">
                  <Link to="/solutions/automation-testing">    <h6 className="titleN">Automation Testing</h6></Link>
             
                    <ul className="list-unstyled">
                    <li>
                         <Link to="/solutions/robot-processing-automation/">Robot Processing Automation</Link>
                      </li>
                      <li>
                         <Link to="/solutions/automation-and-remote-testing/">Test Automation and Remote Testing</Link>
                      </li>
                 
                
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>

                <div className="col-lg-2 col-12 p0" >
                  <div className="col-megamenu ">
                  <Link to="/solutions/cyber-security"><h6 className="titleN">Cyber Security</h6></Link>
                
                    <ul className="list-unstyled ">
                    <li>
                         <Link to="/application-security/">Application Security</Link>
                      </li>
                      <li>
                         <Link to="/end-point-security/">End Point Security</Link>
                      </li>
                 
                      <li>
                         <Link to="/solutions/managed-security-services/">Managed Security Services</Link>
                      </li>
                   
                      <li>
                         <Link to="/solutions/penetration-testing/">Penetration Testing</Link>
                      </li>
                  
                      <li>
                         <Link to="/server">Server Security</Link>
                      </li>
                      <li>
                         <Link to="/threat-Intelligence">Threat Intelligence</Link>
                      </li>
                      <li>
                         <Link to="/vulnerability">Vulnerability Management</Link>
                      </li>
                      <li>
                         <Link to="/solutions/ransomware-health-checks/">Ransomware Health Checks</Link>
                      </li>
                      <li>
                         <Link to="/solutions/security-frameworks-compliance/">Security Frameworks and Compliance</Link>
                      </li>
                   
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>

                <div className="col-lg-2 col-12 p0">
                  <div className="col-megamenu">
                  <Link to="/solutions/data-analytics-and-insights">  <h6 className="titleN">Data Analytics And Insights</h6></Link>
          
                    <ul className="list-unstyled">
                      <li>
                         <Link to="/solutions/ai-ml-insights/">AI|ML</Link>
                      </li>
                      <li>
                         <Link to="/solutions/big-data-analytics/">Big Data Analytics</Link>
                      </li>
                      <li>
                         <Link to="/solutions/network-analytics/">Network Analytics</Link>
                      </li>
                   
                      <li>
                         <Link to="/solutions/sport-analytics/">Sport Analytics</Link>
                      </li>
                  
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>

                <div className="col-lg-2 col-12 p0">
                  <div className="col-megamenu">
                  <Link to="/solutions/emerging-technologies"> <h6 className="titleN">Emerging Tech XR/AR/VR</h6></Link>
                    
                    <ul className="list-unstyled">

                    <li>
                         <Link to="/solutions/automotive/">Automotive</Link>
                      </li>
                      <li>
                         <Link to="/solutions/drones/">Drones</Link>
                      </li>

                      <li>
                         <Link to="/hardware-engg">Hardware Engineering</Link>
                      </li>
                      <li>
                         <Link to="/solutions/robotics/">Robotics</Link>
                      </li>
                 
                    <li>
                         <Link to="/solutions/xr-ar-vr/">XR/AR/VR</Link>
                      </li>
                
                  
                 
                
                  
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>
                <div className="col-lg-2 col-12 p0">
                  <div className="col-megamenu  ">
                  <Link to="/solutions/telecom"><h6 className="titleN">Telecom</h6></Link>  
                    
                    <ul className="list-unstyled">

                    <li>
                         <Link to="/solutions/cloud/">Cloud</Link>
                      </li>

                      <li>
                         <Link to="/solutions/5g/">  5G Solutions</Link>
                      </li>
                    
                      <li>
                         <Link to="/solutions/esim/">eSIM</Link>
                      </li>
                      
                      <li>
                      <li>
                         <Link to="/solutions/iot/">IoT</Link>
                      </li>
                      <li>
                         <Link to="/solutions/mobile-os/">Mobile OS</Link>
                      </li>
                      <li>
                         <Link to="/solutions/private-networks/">Private Networks</Link>
                      </li>
                  
                
                         <Link to="/solutions/slicing/">Slicing</Link>
                      </li>
                   
                 
                      <li>
                         <Link to="/solutions/smart-city/">Smart City</Link>
                      </li>
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>

                <div className="col-lg-2 col-12 p0">
                  <div className="col-megamenu border-top-right border-bottom-right ">
                  <Link to="/solutions/fintech">    <h6 className="titleN">Fintech</h6></Link>
     

                    <ul className="list-unstyled">
                    <li>
                         <Link to="/solutions/building-operational-resilience/">Building Operational Resilience</Link>
                      </li>
                      <li>
                         <Link to="/solutions/ffiec-assessment/">FFIEC Assessment</Link>
                      </li>
                    <li>
                         <Link to="/solutions/financial-services/">Financial Services</Link>
                      </li>
                      
                      <li>
                         <Link to="/solutions/regulatory-remediation/">Regulatory Remediation</Link>
                      </li>
                      <li>
                         <Link to="/solutions/robust-controls-design/">Robust Controls Design</Link>
                      </li>
                      <li>
                         <Link to="/digitalbanking">Digital Banking</Link>
                      </li>
                      <li>
                         <Link to="/solutions/technology-modernization-and-automation/">Technology Modernization and Automation</Link>
                      </li>
                   
                   
               
                    

                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>
                {/* end col-3 */}
             
                {/* end col-3 */}
              
       
                {/* end col-3 */}

              
                {/* end col-3 */}
              </div>
              {/* end row */}
            </div>{" "}
            {/* dropdown-mega-menu.// */}
          </li>






          <li className="nav-item dropdown has-megamenu">
          <a
              className="nav-link dropdown-toggle"
              href="#"
              data-bs-toggle="dropdown"
            >
             
             <Link to="/">Insights</Link>
            </a>
            <div className="dropdown-menu megamenu 1bgwhitemenu " role="menu">
              <div className="row g-3">
                <div className="col-lg-2  offset-lg-10 col-12 p0">
                  <div className="col-megamenu  border-top-right border-top-left border-bottom-left border-bottom-right">
                    {/* <h6 className="titleN">Insights</h6> */}
                    
                    <ul className="list-unstyled">
                      
                      <li>
                         <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                         <Link to="/careers">Careers</Link>
                      </li>
                      <li>
                         <Link to="/caselisting">Case Studies</Link>
                      </li>
                
            
                    </ul>
                  </div>{" "}
                  {/* col-megamenu.// */}
                </div>
              
        
                {/* end col-3 */}

               
                {/* end col-3 */}
              </div>
              {/* end row */}
            </div>{" "}
            {/* dropdown-mega-menu.// */}
          </li>

          <li className="nav-item dropdown has-megamenu">
    
             
             <Link to="/contact-us"   className="nav-link dropdown-toggle"> Contact Us
             </Link>
         
            
            {/* dropdown-mega-menu.// */}
          </li>

        </ul>


        {/* <ul className="navbar-nav">
          <li className="nav-item">
      
         <a>
            <Link to="/Contact"> Contact Us
            </Link>
            </a>
          </li>

        
        </ul> */}
      </div>{" "}
      {/* navbar-collapse.// */}
    </div>{" "}
    {/* container-fluid.// */}
  </nav>


    </>
  )
}

export default Menu
