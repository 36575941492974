import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Footer from './../../common/Footer'
import girl from './../../images/threat-center-human.jpg'
import Book from './book';
import fea2 from './../../images/threat-left.jpg'
import fea4 from './../../images/threat-center.jpg'

import Slider from "react-slick";
import './../solutions/managed-security.css'
import './../solutions/vulnerability.css'


import cloud from './../../images/threat-slide.jpg'

import cutting from './../../images/threat-slide.jpg'


// apple ani end
function Threat() {



    const imageRef = useRef(null);
    const ref = useRef(null);

    useGSAP(() => {

        gsap.registerPlugin(ScrollTrigger);

        const targets = document.querySelectorAll('.list li');

        gsap.set('.list', { autoAlpha: 1 });

        const timeline = gsap.timeline({
            defaults: {
                overwrite: 'auto',
                ease: 'power1',
            },
            scrollTrigger: {
                end: '+=300%',
                pin: true,
                pinSpacing: true,
                scrub: true,
                start: 'top top',
                trigger: '.list',
            },
        });

        const stagger = 0.8;
        const duration = 1;

        gsap.set('.list', { autoAlpha: 1 });

        timeline.set(targets, { transformOrigin: 'center bottom' });

        timeline
            .from(targets, {
                duration: duration,
                opacity: 0,
                rotationX: 0,
                rotationY: 0,
                scale: 1,
                stagger: stagger,
                yPercent: 90,
            })
            .to(
                targets,
                {
                    duration: duration,
                    opacity: 0,
                    rotationX: 20,
                    rotationY: 2,
                    scale: 0.75,
                    stagger: stagger,
                    y: -100,
                },
                stagger
            );

        // Animation paragraph

        const element = ref.current;

        const pl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                start: 'top bottom-=20%',
                end: 'bottom top-=20%',
                scrub: true,
            },
        });

        pl.from(element, {
            opacity: 0,
            x: -100,

            duration: 1,
            ease: 'power2.out',
        });


        // Animation for the image reveal effect
        gsap.from(imageRef.current, {
            clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
            duration: 0.5, // Adjust duration
            ease: 'power4.in', // Adjust easing
            scrollTrigger: {
                trigger: '.erer',
                start: "top bottom",
                end: ".z-index-fyv",
                scrub: true,
                onUpdate: self => {
                    const progress = self.progress;
                    const points = [
                        `0% 0%`,
                        `${progress * 100}% 0%`,
                        `${progress * 100}% 100%`,
                        `0% 100%`
                    ];
                    gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
                },
            },
        });




        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.section-head-outline',
                start: 'top top',
                end: 'bottom top',
                scrub: 0.2,
            },
            ease: 'power.inOut',
        });

        tl.to('.title-outline', {
            yPercent: 100,
        })
            .to('.title--hello', {
                yPercent: 100,
            }, 0)
            .to('.title--outline', {
                yPercent: (i) => (i + 1) * 15 + 100,
            }, 0)
            .from('.image-box img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)

            .from('.image-box-center img', {
                yPercent: 15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".outline-box-center",
                    start: "top center",
                    scrub: 1,

                },
                ease: 'power.inOut',
            }, 0)
            .from('.image-box0 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box0",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)


            .from('.image-box1 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box1",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box2 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box2",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0)

            .from('.image-box3 img', {
                yPercent: 25,
                ease: "none",
                scrollTrigger: {
                    trigger: ".image-box3",
                    start: "top center",
                    scrub: 2,

                }, ease: 'power.inOut',
            }, 0);



    });

    // slick slider code start
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const settings2 = {
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        waitForAnimate: false
    };
    // slick slider end
    useEffect(() => {


    });




    return (
        <>
        <Helmet>
        <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="Threat Intelligence | Procal" />
	<meta property="og:description" content="Threat Intelligence
Threat Intelligence, a vital cornerstone of modern cybersecurity, is the practice of collecting, analyzing, and acting on data related to emerging threats, vulnerabilities, and malicious actors. It empowers organizations to stay ahead of cyber adversaries, fortify their defenses, and safeguard their critical digital assets." />
	<meta property="og:url" content="https://www.procaltech.com/threat-Intelligence" />
    <link rel="canonical" href="https://www.procaltech.com/threat-Intelligence" />
	<meta property="og:site_name" content="Procal Technologies" />
        </Helmet>
            <Menu />




            <section className='relative1  five-sec-2 start'>


                <div className='container'>
                    <div class="outline-box">
                        <section class="section-outline section-head-outline">
                            {/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


                            <div className='col-lg-7'>
                                <div class="section-head__title flex-basis-666">

                                    <h1 class="title-outline">
                                        Threat
                                    </h1>

                                    <h2 class="title-outline">
                                        Intelligence
                                    </h2>
                                    <span class="title--outline green">
                                        Intelligence
                                    </span>
                                    <span class="title--outline green">
                                        Intelligence
                                    </span>
                                    <span class="title--outline green">
                                        Intelligence
                                    </span>
                                    <span class="title--outline green">
                                        Intelligence
                                    </span>
                                    <span class="title--outline green">
                                        Intelligence
                                    </span>
                                    <span class="title--outline green">
                                        Intelligence
                                    </span>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='sidetext'>
                                    <div class="image-box">
                                        <img src={girl} className='img-fluid' />
                                    </div>
                                </div></div>


                        </section>
                    </div>





                </div>


            </section>

            <section className="container iot-sec4 ptop  pb-5 erer">
                <div class="container">

                    <div class="row d-flex align-items-center1">


                        <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
                            <img
                                ref={imageRef}
                                src={cloud}
                                alt="Your Image"
                                className="img-fluid"
                            />
                        </div>

                        <div class="col-md-8 col-lg-8">
                            <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center1 mb-5">Threat Intelligence</h2>
                                <p>Threat Intelligence, a vital cornerstone of modern cybersecurity, is the practice of collecting, analyzing, and acting on data related to emerging threats, vulnerabilities, and malicious actors. It empowers organizations to stay ahead of cyber adversaries, fortify their defenses, and safeguard their critical digital assets.</p>

                                <p>At Procal, we recognize the ever-growing significance of threat intelligence in today's interconnected landscape. Cyber threats continue to evolve in complexity and sophistication, with adversaries constantly seeking new ways to exploit vulnerabilities and compromise the security of organizations. Procal's Threat Intelligence solutions are designed to address the challenges posed by this ever-changing threat landscape. Our suite of tools, expert knowledge, and comprehensive services provides organizations with the actionable insights they need to protect their data, systems, and reputation. By leveraging our expertise, organizations can fortify their defenses, minimize the potential impact of cyberattacks, and reduce the likelihood of security breaches.</p>






                            </div>
                        </div>

                        <div class="col-md-4 col-lg-4 image-box">
                            <img src={fea2} className='img-fluid' />
                        </div>

                        <div class="col-md-12 col-lg-12">






                        </div>
                    </div>
                </div>
            </section>

            {/* center section */}
            <div class="container-rze u-position-21c">
                <div class="row-jsz u-mb-17o u-z-index-vag">
                    <div class="col-wjg col-phw"></div>
                    <div class="col-wjg col-e7o">
                        <h2 class="style-GAVo4" id="style-GAVo4">
                            <div class="1text-vn6 sidefont" >Threat Intelligence</div>
                            <div class="1snipcss0-4-5-7 sidefont" >  is a vital</div>
                            <div class="1snipcss0-4-5-7 sidefont" >   cornerstone  </div>
                            <div class="1text-ggq sidefont">  of modern </div>
                            <div class="1text-ggq sidefont">  cybersecurity</div>
                        </h2>
                    </div>
                </div>
                <div class="row-jsz u-mb-17o u-z-index-vag outline-box-center">
                    <div class="col-wjg col-phw"></div>
                    <div class="col-wjg col-z42">
                        <h6 class="des-5xpt-ggq" >Robust security posture, and comply with industry-specific security standards.</h6>
                    </div>
                </div>
                <div class="row-8oo">
                    <div class="col-wjg col-b63"></div>
                    <div class="col-wjg col-b63">
                        <div class="u-img-a9d image-box-center"><img src={fea4} class="u-img-hcf" /></div>
                    </div>
                    <div class="col-wjg col-b63"></div>
                </div>
                <div class="row-jsz u-mb-17o u-z-index-vag">
                    <div class="col-wjg col-s8h" ></div>
                    <div class="col-wjg col-e7o">
                        <p class="style-CgO1k" id="style-CgO1k"></p>
                        <div class="text-vn6 ygqvp" >Threat </div>
                        <div class="text-vn6 ygqvp" > Intelligence</div>
                        <div class="text-vn6 ygqvp" > excels at </div>
                        <div class="text-vn6 ygqvp" > safeguarding   </div>
                        <div class="text-vn6 ygqvp" > critical digital  </div>
                        <div class="text-vn6 ygqvp" >assets</div>
                        <p></p>
                    </div>
                </div>
                <div class="row-jsz u-z-index-vag">
                    <div class="col-wjg col-phw"></div>
                    <div class="col-wjg col-e7o">
                        <p class="style-RWfkB" id="style-RWfkB"></p>
                        <div class="ygqvp " >from start</div>
                        
                        <div class="ygqvp" >to </div>
                        <div class="ygqvp" > finish</div>
                        <p></p>
                    </div>
                </div>
            </div>
            {/* center section end */}
            <section className="container-rom container-vul ali-fxl">
                <div className="container-d1s">
                    <div className="container-rom container-2e2">
                        <div className="container-d1s">
                            <div className="wrapper-ti2">
                                <div className="column-o8o column-fhi">
                                    <section className="container-rom container-coh ani-lcm fa-6jh">
                                        <div className="container-d1s" style={{ backgroundColor: '#898989', padding: '28px' }}>
                                            <h2 className="gb-headline-6nz">Vigilant Eye on the Cyber Landscape</h2>
                                            <p className="col-oe2" style={{ fontSize: 16 }}>
                                                Our dedicated team of security experts continually monitors the threat landscape, keeping a vigilant eye on a wide range of data sources, including open-source threat feeds, dark web sources, and internal network logs. This approach ensures that we have a real-time view of emerging threats and vulnerabilities, allowing us to provide timely and relevant threat intelligence to our clients.
                                            </p>



                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* whats new section */}
            <section className="container sec10 py-5 p-5 pb-5">
                <div className="row py-lg-5">
                    <div className="col-lg-12 col-md-8 mx-auto">


                        <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>


                    </div>


                </div>

                {/* slicky slider */}
                <div className="slider-container">

                    <Slider {...settings2}>



                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Data Collection</h4>

                                    <p>Procal utilizes advanced tools and methodologies for comprehensive data collection, including open-source intelligence, industry reports, and dark web monitoring, ensuring a thorough understanding of potential threats and vulnerabilities.</p>

                                </div>


                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Assessment</h4>

                                    <p>Once identified, Procal assesses vulnerabilities to comprehend their severity and potential impact on the organization's security. This includes evaluating the risk associated with each vulnerability and determining the likelihood of exploitation.</p>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Analysis</h4>

                                    <p>With a team of skilled Threat Intelligence analysts, Procal conducts in-depth analysis to identify patterns and trends, assesses the credibility of information, and provides valuable insights into potential cybersecurity threats.</p>

                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Contextualization</h4>

                                    <p>Procal contextualizes Threat Intelligence by aligning gathered information with the organization's specific risk landscape, industry nuances, and internal factors. This tailored approach enhances the relevance of Threat Intelligence for effective decision-making.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Indicators of Compromise (IOCs)</h4>

                                    <p>Procal excels in identifying and analyzing IOCs, offering organizations a precise understanding of potential security incidents and the necessary steps to mitigate these risks.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Tactics, Techniques, and Procedures (TTPs)</h4>

                                    <p>Procal's expertise in understanding threat actor TTPs allows organizations to fortify their defenses by implementing measures specifically designed to counter the methods employed by potential attackers.</p>

                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Sharing and Collaboration</h4>

                                    <p>Procal fosters collaboration by facilitating the sharing of Threat Intelligence insights within the organization and with external partners. This collective approach strengthens the overall cybersecurity posture.</p>


                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Implementation</h4>

                                    <p>Procal translates Threat Intelligence into actionable strategies, assisting organizations in updating security policies, enhancing detection systems, and refining incident response plans for more effective cybersecurity measures.</p>


                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Continuous Monitoring</h4>

                                    <p>Procal's continuous monitoring services ensure that organizations remain vigilant against emerging threats. Regular updates and adjustments to cybersecurity strategies are made based on the latest Threat Intelligence insights.</p>


                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="row dive-cobalt align-items-center">
                                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                                    <div className="col-bg">

                                        <div className="box03 bg-coral-medium list-video">
                                            <img src={cutting} className="card-img1" alt="..." />


                                        </div>

                                    </div>
                                </div>


                                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                                    <h4>Feedback Loop</h4>

                                    <p>Procal establishes a feedback loop to assess the effectiveness of implemented security measures. This iterative process allows organizations to continuously refine their cybersecurity posture based on real-world outcomes. By partnering with Procal for threat intelligence, organizations gain a strategic advantage in the ongoing battle against cyber threats. Our comprehensive threat intelligence solutions provide the knowledge, tools, and expertise needed to protect your digital assets, reduce vulnerabilities, and safeguard your business against the ever-evolving landscape of cybersecurity threats. We enable organizations to make informed decisions, respond rapidly to emerging threats, and maintain a resilient cybersecurity posture.</p>


                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>

                {/* slicky slider end */}



            </section>


            {/* whats new section end */}


            <Book/>

            <Footer />
        </>
    )
}

export default Threat
