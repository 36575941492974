import React, { useEffect, useRef } from 'react';
import Menu from '../../common/Menu';
import Footer from '../../common/Footer'
import './telecom.css';
import cybersml from './../../images/lock.jpg'
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TweenLite, Circ } from 'gsap';
function Book() {

	const canvasRef = useRef(null);
	const largeHeaderRef = useRef(null);
  
	useEffect(() => {
	  let width, height, ctx, points, target, animateHeader = true;
  
	  // Main
	  initHeader();
	  initAnimation();
	  addListeners();
  
	  function initHeader() {
		width = window.innerWidth;
		height = 400;
		target = { x: width / 2, y: height / 2 };
  
		largeHeaderRef.current.style.height = height + 'px';
  
		const canvas = canvasRef.current;
		canvas.width = width;
		canvas.height = height;
		ctx = canvas.getContext('2d');
  
		// create points
		points = [];
		for (let x = 0; x < width; x += width / 20) {
		  for (let y = 0; y < height; y += height / 20) {
			let px = x + Math.random() * width / 20;
			let py = y + Math.random() * height / 20;
			let p = { x: px, originX: px, y: py, originY: py };
			points.push(p);
		  }
		}
  
		// for each point find the 5 closest points
		points.forEach((p1, i) => {
		  let closest = [];
		  points.forEach((p2) => {
			if (p1 !== p2) {
			  let placed = false;
			  for (let k = 0; k < 5; k++) {
				if (!placed) {
				  if (!closest[k]) {
					closest[k] = p2;
					placed = true;
				  }
				}
			  }
  
			  for (let k = 0; k < 5; k++) {
				if (!placed) {
				  if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
					closest[k] = p2;
					placed = true;
				  }
				}
			  }
			}
		  });
		  p1.closest = closest;
		});
  
		// assign a circle to each point
		points.forEach(p => {
		  let c = new Circle(p, 2 + Math.random() * 2, 'rgba(255,255,255,0.3)');
		  p.circle = c;
		});
	  }
  
	  function addListeners() {
		if (!('ontouchstart' in window)) {
		// window.addEventListener('mousemove', mouseMove);
		}
		window.addEventListener('scroll', scrollCheck);
		window.addEventListener('resize', resize);
	  }
  
	   function mouseMove(e) {
	 	const posx = e.pageX || e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
	 	const posy = e.pageY || e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
	 	target.x = posx;
	 	target.y = posy;
	 }
  
	  function scrollCheck() {
		animateHeader = document.body.scrollTop <= height;
	  }
  
	  function resize() {
		width = window.innerWidth;
		height = 500;
		largeHeaderRef.current.style.height = height + 'px';
		canvasRef.current.width = width;
		canvasRef.current.height = height;
	  }
  
	  function initAnimation() {
		animate();
		points.forEach(shiftPoint);
	  }
  
	  function animate() {
		if (animateHeader) {
		  ctx.clearRect(0, 0, width, height);
		  points.forEach(p => {
			if (Math.abs(getDistance(target, p)) < 4000) {
			  p.active = 0.3;
			  p.circle.active = 0.6;
			} else if (Math.abs(getDistance(target, p)) < 20000) {
			  p.active = 0.1;
			  p.circle.active = 0.3;
			} else if (Math.abs(getDistance(target, p)) < 40000) {
			  p.active = 0.02;
			  p.circle.active = 0.1;
			} else {
			  p.active = 0;
			  p.circle.active = 0;
			}
  
			drawLines(p);
			p.circle.draw();
		  });
		}
		requestAnimationFrame(animate);
	  }
  
	  function shiftPoint(p) {
		TweenLite.to(p, 1 + 1 * Math.random(), {
		  x: p.originX - 50 + Math.random() * 100,
		  y: p.originY - 50 + Math.random() * 100,
		  ease: Circ.easeInOut,
		  onComplete: () => shiftPoint(p),
		});
	  }
  
	  function drawLines(p) {
		if (!p.active) return;
		p.closest.forEach(p2 => {
		  ctx.beginPath();
		  ctx.moveTo(p.x, p.y);
		  ctx.lineTo(p2.x, p2.y);
		  ctx.strokeStyle = 'rgba(255,255,32,' + p.active + ')';
		  ctx.stroke();
		});
	  }
  
	  function Circle(pos, rad, color) {
		this.pos = pos || null;
		this.radius = rad || null;
		this.color = color || null;
		this.draw = () => {
		  if (!this.active) return;
		  ctx.beginPath();
		  ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 100 * Math.PI, false);
		  ctx.fillStyle = 'rgba(255,255,32,' + this.active + ')';
		  ctx.fill();
		};
	  }
  
	  function getDistance(p1, p2) {
		return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
	  }
  
	  return () => {
		window.removeEventListener('mousemove', mouseMove);
		window.removeEventListener('scroll', scrollCheck);
		window.removeEventListener('resize', resize);
	  };
	}, []);
  


	return (
		<>
			<Helmet>
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="article" />
				<meta property="og:title" content="Cyber Security Services in USA, UK, UAE | IT Security Solutions" />
				<meta property="og:description" content="Procal offers cybersecurity consulting services &amp; solutions in USA, UK, UAE, to embed security across your ecosystem to reduce risk in the future by securing today" />
				<meta property="og:url" content="https://procaltech.com/solutions/cybersecurity/" />
				<meta property="og:site_name" content="Procal Technologies" />

			</Helmet>


			<div id="large-header" className="large-header" ref={largeHeaderRef}>
      <canvas id="demo-canvas" ref={canvasRef}></canvas>
      <h1 className="main-title">
	  Ready to take your business to the next level?<br/> <span className="thin">Schedule a free consultation with our team to discover how we can help!</span>
      </h1>

	  <div className='btnpos'>
	<Link to="/contact-us#consult" ><div class="codepen-button"><span>Book a free Consultation</span></div>

</Link>



</div>
    </div>


			{/* <section className='book 1pt100 pb-5' id='cybersecurity'>
			
				<div class="container text-center">
					<div class="row d-flex align-items-center">
						<div class="col-md-12 col-lg-12">
						<div class="container mt-3">

  <div class="mt-4 p-5 1bg-dark text-white rounded">
    <h1>Ready to take your business to the next level?</h1> 
    <p>Schedule a free consultation with our team to discover how we can help!</p> 


	<Link to="/contact-us#consult" ><button class="book-btn">
Book a free Consultation
</button></Link>
  </div>
</div>
						</div>
				

					</div>
				</div>
			</section> */}


		</>
	)
}

export default Book
