import React, { useEffect } from 'react';
import './../home/home.css'
function generateKolor() {
    var red = Math.floor((Math.random() * 255) - 100);
    var green = Math.floor((Math.random() * 255) + 50);
    var blue = Math.floor((Math.random() * 255) + 200);
    var alpha = Math.random();
    var kolor = "rgba(" + red + "," + green + "," + blue + "," + alpha + ")";
    return kolor;
}

const styles = {
    display: "block",
    position: "absolute",
    boxSizing: "border-box",
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: "100px 200px",
    overflow: "hidden",
    top: 0,
    left: 0,
    opacity: "1",
    backgroundImage: `linear-gradient(${generateKolor()},${generateKolor()})`,
    backgroundSize: "100% 100vh",
    filter: "url(#goo)"
};

const contStyles = {
    display: "block",
    position: "absolute",
    boxSizing: "border-box",
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: "100px 200px",
    top: "60px",
    left: "40px",
    opacity: "0.7",
    transform: "scaleX(-1) scaleY(-1)",
    filter: "url(#goo)"
};

function makeBoxes(targetBox, minWidth, minHeight) {
    const boxes = [];
    for (let i = 1; i <= 100; i++) {
        const delayWiggle = Math.floor((Math.random() * 1000)) + "ms";
        const durationWiggle = Math.floor((Math.random() * 1000) + 2000) + "ms";
        const thisbgKolor = generateKolor();
        const thisbgKolor2 = generateKolor();
        const widthHeight = Math.floor(((Math.random() * 120) + minWidth)) + "px";
        const thisStyle = {
            display: "inline-block",
            width: widthHeight,
            height: widthHeight,
            borderRadius: "50%",
            margin: "-5px",
            opacity: "1",
            backgroundImage: `linear-gradient(${thisbgKolor},${thisbgKolor2})`,
            backgroundSize: "100% 100%",
            position: "relative",
            animationDelay: Math.floor((Math.random() * 2000)) + "ms",
            animationDuration: Math.floor((Math.random() * 1000) + 8000) + "ms"
        };
        boxes.push(<div key={i} className="boxouter" style={{ animationDelay: delayWiggle, animationDuration: durationWiggle }}>
            <div className={`box box${i}`} style={thisStyle}></div>
        </div>);
    }
    return boxes;
}

function MyComponent() {
    useEffect(() => {
        document.body.style = styles;
        const boxes = makeBoxes("body", 10, 10);
        document.body.append(...boxes);
        document.body.innerHTML += '<div class="container"></div>';
        const container = document.querySelector('.container');
        container.style = contStyles;
        const containerBoxes = makeBoxes(".container", 10, 10);
        container.append(...containerBoxes);
    }, []);

    return (
    
    <>
        <h1>Hot Lava</h1>
        <svg className="goo-defs" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <defs>
                <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                    <feComposite in="SourceGraphic" in2="goo" operator="atop" /> 
                </filter>
            </defs>
        </svg>

        <h1>Hot Lava</h1>

<svg class="goo-defs" xmlns="http://www.w3.org/2000/svg" version="1.1">
	<defs>
		<filter id="goo">
			<feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
			<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
			<feComposite in="SourceGraphic" in2="goo" operator="atop"/> 
		</filter>
	</defs>
</svg>
    </>
    )
}

export default MyComponent;
