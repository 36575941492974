import React from 'react';
import Menu from './../../common/Menu';
import Location from './../../common/contact/Location';
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import Slider from "react-slick";
import Footer from './../../common/Footer'
import girl from './../../images/5ggirl.svg'
import mobile from './../../images/5g-cloud.png'
import momockup from './../../images/xr-iphone.avif'
import tall from './../../images/tallgirl.svg'
import girleps from './../../images/girl-llustrator.jpg'
import spider from './../../images/tallgirl.svg'
import './../solutions/fiveg.css'
import Ourclient from './ourclient';
import whatsnew from './../../images/whats-new-bg.png'
import cloud from './../../images/img-12.png'
import banking from './../../images/jio5.jpg'
import cutting from './../../images/cutting-edge.jpg'
import { Helmet } from "react-helmet";
import Book from './book';
// apple ani end
function Fiveg() {

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.section-head-outline',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.2,
      },
      ease: 'power.inOut',
    });

    tl.to('.title-outline', {
      yPercent: 100,
    })
      .to('.title--hello', {
        yPercent: 100,
      }, 0)
      .to('.title--outline', {
        yPercent: (i) => (i + 1) * 15 + 100,
      }, 0)
      .from('.image-box img', {
        yPercent: 15,
        ease: "none",
        scrollTrigger: {
            trigger: ".outline-box",
            start: "top center",
            scrub: 1,
         
        }
      }, 0);

    // Return cleanup function
    return () => {
      tl.kill(); // Kill the timeline to avoid memory leaks
    };
});

  // slick slider code start
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false
  };
  // slick slider end
  useEffect(() => {
  
  }, []); // Empty dependency array ensures 


  return (
    <>
    <Helmet>

    <title>5G Technology Solutions for Telecom & Enterprises | USA,UK,UAE</title>
    <meta name="description" content="Procal’s comprehensive 5G Technology solutions cover non-standalone and standalone 5G deployment, network slicing, private networks, device certification, and device diagnostics" />
    <meta property="og:locale" content="en_US" />
	<meta property="og:type" content="article" />
	<meta property="og:title" content="5G Technology Solutions for Telecom &amp; Enterprises | USA,UK,UAE" />
	<meta property="og:description" content="Procal’s comprehensive 5G Technology solutions cover non-standalone and standalone 5G deployment, network slicing, private networks, device certification, and device diagnostics" />
	<meta property="og:url" content="https://www.procaltech.com/solutions/5g" />
	<meta property="og:site_name" content="Procal Technologies" />
  <link rel="canonical" href="https://www.procaltech.com/solutions/5g" />

  </Helmet>
      <Menu />
      {/* <section  className='fiveg-sec1'>
<div className='col-lg-12'>

<svg width="400" height="400" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<defs>
<filter id="displacementFilter">
<feTurbulence type="fractalNoise" baseFrequency="0.03" numOctaves="3" result="noise" />
<feDisplacementMap in="SourceGraphic" in2="noise" scale="50" xChannelSelector="R" yChannelSelector="G" />
</filter>
<mask id="circleMask">
<circle cx="200" cy="200" r="0" fill="yellow" className="displacement" />
</mask>
</defs>
<image
xlinkHref={dot}
width="400"
height="400"
mask="url(#circleMask)"
/>
</svg>


</div>

</section> */}



      <section className='relative1  five-sec-2 start'>


        <div className='container'>
          <div class="outline-box">
            <section class="section-outline section-head-outline">

              {/* <div class="section-head__images">
      <div class="image-box">
        <img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
      </div>
    </div> */}

<div className='col-lg-7'>  <div class="section-head__title flex-basis-666 ">
                <span class="title--hello">
                  5G
                </span>
                <h1 class="title-outline">
                  SOLUTION
                </h1>
                <span class="title--outline">
                  SOLUTION
                </span>
                <span class="title--outline">
                  SOLUTION
                </span>
                <span class="title--outline">
                  SOLUTION
                </span>
                <span class="title--outline">
                  SOLUTION
                </span>
                <span class="title--outline">
                  SOLUTION
                </span>
                <span class="title--outline">
                  SOLUTION
                </span>
              </div></div>

            <div className='col-lg-5'>
              <div className='sidetext'>
                <div class="image-box">
                <img src={cloud} className='img-fluid' />     
                </div>
              </div></div>



            </section>
          </div>


          {/* <svg width="400" height="400" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
<defs>
<filter id="displacementFilter">
<feTurbulence type="fractalNoise" baseFrequency="0.03" numOctaves="3" result="noise" />
<feDisplacementMap in="SourceGraphic" in2="noise" scale="50" xChannelSelector="R" yChannelSelector="G" />
</filter>
<mask id="circleMask">
<circle cx="200" cy="200" r="0" fill="yellow" className="displacement" />
</mask>
</defs>
<image
xlinkHref={dot}
width="400"
height="400"
mask="url(#circleMask)"
/>
</svg> */}



        </div>


      </section>
      <section className="container iot-sec4 ptop  pb-5 erer">
        <div class="container">

          <div class="row d-flex align-items-center">


            <div class="col-md-12 col-lg-12" style={{ display: 'none' }}>
              <img
                // ref={imageRef01}
                src={banking}
                alt="Your Image"
                className="img-fluid"
              />
            </div>

            <div class="col-md-5 col-lg-5">
              <div class="txt-block right-column wwhite-color wow fadeInLeft" >
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center mb-5">The power of 5G</h2>
                <p>The strength of 5G technology comes from its sophisticated design, delivering a robust network that executes tasks more quickly, transfers data at higher speeds, supports a larger number of connections, and offers reduced latency for improved user experiences. Beyond just speed, 5G networks are crafted to handle increased user demands, virtual activities, and network-based operations, while simplifying the identification and resolution of systemic issues. These enhancements lead to faster time-to-market and greater reliability, ultimately resulting in higher customer satisfaction as users can access complex technical solutions without sacrificing speed or efficiency.
                </p>

                <p>The significance of 5G extends to its potential to revolutionize various industries and enable previously impossible technologies. With its focus on speed, flexibility, and efficiency, 5G optimizes performance across all operational aspects. Given the current technological landscape, the underlying network's strength, speed, and flexibility play a crucial role in a business's overall technical capabilities and success. Procal, with its team of 5G subject matter experts, offers customized and cost-effective guidance throughout the adoption and deployment phases of 5G.</p>
                <p>Procal's comprehensive 5G solutions cover non-standalone and standalone 5G deployment, network slicing, private networks, device certification, and device diagnostics. Our experts assist in navigating the 4G/5G standards and certifications established by governing bodies such as 3GPP, GSMA, ITU, GCF, and PTCRB. By designing solutions based on a foundation of 5G, we enable creative engineering, provide more automation options, and instill confidence in the testing and implementation processes. Our robust network infrastructure ensures seamless integration of future technologies.”</p>




              </div>
            </div>

            <div class="col-md-7 col-lg-7">
              <img src={banking} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>



      {/* whats new section */}
      <section className="container sec10 py-5 p-5 pb-5">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-8 mx-auto">


            <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center ">Procal’s Areas of Expertise</h2>
         

          </div>


        </div>

        {/* slicky slider */}
        <div className="slider-container">
          <Slider {...settings2}>


            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cloud} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>NSA and SA 5G Deployment</h4>
                
                  <p>Procal is a leading provider of comprehensive 5G deployment solutions, offering both non-standalone (NSA) and standalone (SA) options. Our seamless non-standalone deployment integrates effortlessly with existing 4G infrastructure, facilitating a smooth transition to 5G. On the other hand, our standalone deployment provides a robust 5G network with enhanced performance and expanded functionalities. With our adaptable and scalable solutions, we empower enterprises to fully leverage the transformative potential of 5G technology, unlocking new possibilities for growth and innovation.</p>
               
                </div>


              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cloud} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Network Slicing</h4>
                
                  <p>Procal’s advanced slicing services expertly optimize the efficient distribution of dedicated resources among different user groups and applications, thereby significantly enhancing security, customization, and overall operational efficiency. This strategic resource allocation enables the seamless coexistence of diverse services, including Internet of Things (IoT), mission-critical communications, and robust enterprise applications, on a shared infrastructure, leading to exceptional performance and unparalleled scalability. Leveraging the power of Procal’s slicing services, businesses across industries gain a competitive edge by unlocking a plethora of new opportunities and achieving resounding success in today’s dynamic digital landscape.</p>
             
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={cloud} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Embracing AI & Automation for Cloud Solutions: A Technological Imperative</h4>
               
                  <p>Embracing AI and automation in cloud solutions is a game-changer for businesses! With Procal Technologies, you can harness the power of intelligent systems to streamline processes and boost efficiency. Imagine automating routine tasks while leveraging AI to gain insights from your data. Our solutions make it easy to adapt to changing needs, helping you stay agile and competitive. Taking cloud strategy to the next level to unlock the full potential of existing technology</p>
               
                </div>
              </div>
            </div>
            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={banking} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Private Networks</h4>
    
                  <p>Procal is a leading provider of private network solutions, empowering businesses with secure and dedicated environments. Leveraging advanced technologies and a robust infrastructure, we prioritize data confidentiality and integrity, ensuring seamless communication and collaboration within organizations. Our customized private network solutions offer enhanced privacy, reliability, and performance, addressing the unique needs of businesses across diverse industries. Trust Procal to deliver tailored network solutions that safeguard your data and support your business growth.</p>
   
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={banking} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Device Certification</h4>
    
                  <p>Procal’s device certification solution offers seamless compliance and reliability for your devices through comprehensive testing and evaluation. Our streamlined process ensures efficient certification, reducing time-to-market for your products and giving you a competitive edge. With Procal’s rigorous verification of compliance with industry standards and regulations, you can trust that your devices meet the highest quality benchmarks, ensuring customer satisfaction and maintaining regulatory compliance with ease. Our commitment to excellence empowers your business to thrive in a rapidly evolving market.</p>
   
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3 offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={banking} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>Device Diagnostics</h4>
    
                  <p>Procal’s device diagnostics solution revolutionizes business analysis by offering comprehensive and efficient performance analysis. Leveraging advanced algorithms and cutting-edge technology, our solution provides real-time monitoring, accurate fault detection, proactive maintenance alerts, and detailed analytics. By implementing our expertise, organizations can streamline operations, boost productivity, extend device lifespan, and achieve substantial cost savings. With improved customer satisfaction as a result, businesses can establish a strong competitive edge in their respective industries.</p>
   
                </div>
              </div>
            </div>

            <div>
              <div className="row dive-cobalt align-items-center">
                <div className="col-lg-3  offset-lg-1 col-md-3 col-12">
                  <div className="col-bg">

                    <div className="box03 bg-coral-medium list-video">
                      <img src={banking} className="card-img1" alt="..." />


                    </div>

                  </div>
                </div>


                <div className="col-lg-7 col-md-7 col-12 align-items-center">

                  <h4>4G/5G Standards and Certifications</h4>
    
                  <p>Procal stands out by offering tailored and cost-effective solutions that adhere to industry standards and certifications like GCF and PTCRB for 4G/5G technology. Our expert team is dedicated to delivering personalized solutions that precisely align with the unique requirements of each client, ensuring their utmost satisfaction and success in today’s rapidly evolving technological landscape. With our comprehensive approach and attention to detail, we strive to exceed expectations and provide unmatched value to our clients.</p>
   
                </div>
              </div>
            </div>
          </Slider>
        </div>

        {/* slicky slider end */}



      </section>


      {/* whats new section end */}


      <section className="px-4 py-5 p-5 pb-5 sec13">
          <div className="container-fluid">
            <div className="row py-lg-5w">
              <div className="col-lg-8 offset-lg-1 col-md-8 mx-auto text-white botm-20">
                <h2 className="display-5 lh-1 mb-3 animate-text-2 text-center">Experience the Procal Difference</h2>
                <h3 className="text-center mt-5">We want to hear from you!</h3>

                <div className="input-container">
                  <input type="text" className="input-field mt-5" placeholder="Enter your E-mail address.." />
                  <button className="send-button  mt-5">&#10148;</button>
                </div>
              </div>

            </div>
          </div>

        </section>




      {/* <div className='light-theme'>
<section className="products" id='start'>
<div className="inner products__background">


</div>
</section>
</div> */}
<Book/>
      <Footer />
    </>
  )
}

export default Fiveg
