import React from 'react'
import  {useEffect, useState , useRef} from "react";
import { gsap } from "gsap";
import './newmenu.css';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Animator, ScrollContainer, ScrollPage,page, batch, Fade, FadeIn, FadeOut,
    Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import Footer from '../common/Footer';





function Navbarmenu() {
    gsap.registerPlugin(ScrollTrigger);

    const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

    useEffect(() => {
 

        
          }, []);
        
  return (
    <>
 <nav className="navbar">
      <div className="navbar-brand">Brand</div>
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        <a href="#home">Home</a>
        <a href="#services">Services</a>
        <a href="#home">Home</a>
        <a href="#services">Services</a>
        <a href="#home">Home</a>
        <a href="#services">Services</a>
        <a href="#home">Home</a>
        <div className="dropdown">
          <a onClick={toggleDropdown}>
            More
          </a>
          <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
            <a href="#about">About</a>
            <a href="#contact">Contact</a>
          </div>
        </div>
        <a href="#home">Home</a>
        <a href="#services">Services</a>
        <div className="dropdown">
          <a onClick={toggleDropdown}>
            More
          </a>
          <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
            <a href="#about">About</a>
            <a href="#contact">Contact</a>
          </div>
        </div>
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
    <section className='e'>
    <section class="section1"></section>
        <div className='container'>
      <h1>Our Location All Of The World</h1>
      <div class="box box1 red">
    <div class="box-bg"></div>
    Text 1
  </div>
  <div className="box box2 purple">
    <div class="box-bg"></div>
    Text 2
  </div>
  <div className="box box3 green" >
    <div className="box-bg"></div>
    Text 3
  </div>
      </div>
      <section class="section1"></section>
      </section>
      <Footer/>
    </>

  )
}

export default Navbarmenu
