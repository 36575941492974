import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './../../common/contact/location.css'
import createGlobe from "cobe";
import { useSpring } from 'react-spring';
import { useGSAP } from "@gsap/react";
import ireland from './../../images/ireland-city.png'
import germany from './../../images/germany-city.png'
import turkey from './../../images/turkey-city.png'
import fea2 from './../../images/free-consult.png'
import youtube from './../../images/youtube-logo.png'
import linkedin from './../../images/linkeding-logo.png'
import twitter from './../../images/x-logo.png'
import newyork from './../../images/new-york.png'
import dubai from './../../images/dubai-city.png'
import 'bootstrap/dist/css/bootstrap.min.css';
const Location = () => {

  const imageRef = useRef(null);
  const ref = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const canvasRef = useRef(null);
  const pointerInteracting = useRef(null);
  const pointerInteractionMovement = useRef(0);
  const [{ r }, api] = useSpring(() => ({
    r: 0,
    config: {
      mass: 1,
      tension: 280,
      friction: 40,
      precision: 0.001,
    },
  }));

  // useGSAP(() => {
  //   gsap.registerPlugin(ScrollTrigger);
  //   const targets = document.querySelectorAll('.list li');

  //   gsap.set('.list', { autoAlpha: 1 });

  //   const timeline = gsap.timeline({
  //     defaults: {
  //       overwrite: 'auto',
  //       ease: 'power1',
  //     },
  //     scrollTrigger: {
  //       end: '+=300%',
  //       pin: true,
  //       pinSpacing: true,
  //       scrub: true,
  //       start: 'top top',
  //       trigger: '.list',
  //     },
  //   });

  //   const stagger = 0.8;
  //   const duration = 1;

  //   gsap.set('.list', { autoAlpha: 1 });

  //   timeline.set(targets, { transformOrigin: 'center bottom' });

  //   timeline
  //     .from(targets, {
  //       duration: duration,
  //       opacity: 0,
  //       rotationX: 0,
  //       rotationY: 0,
  //       scale: 1,
  //       stagger: stagger,
  //       yPercent: 90,
  //     })
  //     .to(
  //       targets,
  //       {
  //         duration: duration,
  //         opacity: 0,
  //         rotationX: 20,
  //         rotationY: 2,
  //         scale: 0.75,
  //         stagger: stagger,
  //         y: -100,
  //       },
  //       stagger
  //     );

  //   // Animation paragraph

  //   const element = ref.current;

  //   const pl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: element,
  //       start: 'top bottom-=20%',
  //       end: 'bottom top-=20%',
  //       scrub: true,
  //     },
  //   });

  //   pl.from(element, {
  //     opacity: 0,
  //     x: -100,

  //     duration: 1,
  //     ease: 'power2.out',
  //   });


  //   // Animation for the image reveal effect
  //   gsap.from(imageRef.current, {
  //     clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
  //     duration: 0.5, // Adjust duration
  //     ease: 'power4.in', // Adjust easing
  //     scrollTrigger: {
  //       trigger: '.erer',
  //       start: "top bottom",
  //       end: ".z-index-fyv",
  //       scrub: true,
  //       onUpdate: self => {
  //         const progress = self.progress;
  //         const points = [
  //           `0% 0%`,
  //           `${progress * 100}% 0%`,
  //           `${progress * 100}% 100%`,
  //           `0% 100%`
  //         ];
  //         gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
  //       },
  //     },
  //   });




  //   const tl = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: '.section-head-outline',
  //       start: 'top top',
  //       end: 'bottom top',
  //       scrub: 0.2,
  //     },
  //     ease: 'power.inOut',
  //   });

  //   tl.to('.title-outline', {
  //     yPercent: 100,
  //   })
  //     .to('.title--hello', {
  //       yPercent: 100,
  //     }, 0)
  //     .to('.title--outline', {
  //       yPercent: (i) => (i + 1) * 15 + 100,
  //     }, 0)
  //     .from('.image-box img', {
  //       yPercent: 15,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: ".outline-box",
  //         start: "top center",
  //         scrub: 1,

  //       },
  //       ease: 'power.inOut',
  //     }, 0)

  //     .from('.image-box-center img', {
  //       yPercent: 15,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: ".outline-box-center",
  //         start: "top center",
  //         scrub: 1,

  //       },
  //       ease: 'power.inOut',
  //     }, 0)
  //     .from('.image-box0 img', {
  //       yPercent: 25,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: ".image-box0",
  //         start: "top center",
  //         scrub: 2,

  //       }, ease: 'power.inOut',
  //     }, 0)


  //     .from('.image-box1 img', {
  //       yPercent: 25,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: ".image-box1",
  //         start: "top center",
  //         scrub: 2,

  //       }, ease: 'power.inOut',
  //     }, 0)

  //     .from('.image-box2 img', {
  //       yPercent: 25,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: ".image-box2",
  //         start: "top center",
  //         scrub: 2,

  //       }, ease: 'power.inOut',
  //     }, 0)

  //     .from('.image-box3 img', {
  //       yPercent: 25,
  //       ease: "none",
  //       scrollTrigger: {
  //         trigger: ".image-box3",
  //         start: "top center",
  //         scrub: 2,

  //       }, ease: 'power.inOut',
  //     }, 0);



  // });






  useEffect(() => {
    
    let phi = 0;
    let width = 0;

    const onResize = () => {
      if (canvasRef.current) {
        width = canvasRef.current.offsetWidth;
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    const globe = createGlobe(canvasRef.current, {
      width: 700,
      height: 600,
      onRender: () => { },
      devicePixelRatio:2,
      phi: 0,
      theta: 0.3,
      dark: 0,
      diffuse: 0.2,
      mapSamples: 4000,
      mapBrightness: 2,
      baseColor: [1, 1, 1],
      markerColor: [251 / 255, 100 / 255, 21 / 255],
      glowColor: [1, 1, 1],
      markers: [    // longitude latitude
        { location: [37.7595, -122.4367], size: 0.1 },
        { location: [40.549688, 74.612437], size: 0.1 },
        { location: [41.753687, -88.211062], size: 0.1 },
        { location: [40.567062, 74.609063], size: 0.1 },
        { location: [40.549688, -74.612437], size: 0.1 },
        { location: [41.753687, -88.211062], size: 0.1 },
        { location: [33.043563, -96.734188], size: 0.1 },

        { location: [33.043563, 96.734188], size: 0.1 },
        { location: [52.523688, 13.288063], size: 0.1 },
        { location: [41.054187, 29.000062], size: 0.1 },
        { location: [30.672562, 76.842938], size: 0.1 },
        { location: [17.425562, 78.420063], size: 0.1 },
        { location: [25.184063, 55.260937], size: 0.1 },

        // last location below
        { location: [21.266937, 79.082313], size: 0.1 }],
      onRender: (state) => {
        if (!pointerInteracting.current) {
          phi += 0.002;
        }

        state.phi = phi + r.get();
        state.width = width * 2;
        state.height = width * 2;
      },
    });

    setTimeout(() => {
      if (canvasRef.current) {
        canvasRef.current.style.opacity = '1';
      }
    }, 1000);

    return () => {
      globe.destroy();
     // window.removeEventListener('resize', onResize);
    };
  }, [r]);


  // anchor page code start
  
  
  
  
  
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash]);
  // anchor page code end

  return (

    <>

<div class="icon-bar">

  <a href="https://x.com/procal_tech" class="twitter" title='X' target='_blank'><img src={twitter}/></a> 
  <a href="https://www.linkedin.com/company/procaltech/" class="linkedin" title='Linkedin' target='_blank'><img src={linkedin}/></a>
  <a href="https://www.youtube.com/@PROCAL_TECH" class="youtube" title='YouTube' target='_blank'><img src={youtube}/></a> 
</div>

{/* 
      <section className='relative1  five-sec-2 start contactbg'>


        <div className='container'>
          <div className="row d-flex align-items-center1">

            <div className="col-md-8 col-lg-8 spacetop">



              <h1 class="contact-heading ctrl-break fontweight600">Contact Us</h1>

              <h2 className='contact-text'>Headquarter</h2>
              <p className='contact-text-para'>
                92 East Main Street, Suite:405 Somerville,<br />
                New Jersey 08876 United States.<br />

              </p>
              <h2 className='contact-text'>Phone Number</h2>
              <p className='contact-text-para'>(732)-568-4786</p>

            </div>


          </div>


          <div className='leftbox'>


          </div>






        </div>


      </section> */}

      {/* absract img banner  */}

      <div class="bg-image-abs d-flex justify-content-center align-items-center vh-100 mo-padding">
    {/* <h1 class="text-center text-white">Centered Text on Fullwidth Background</h1> */}
    <div>
    <h1 class="contact-heading ctrl-break fontweight600  text-center">Contact Us</h1>
    <h2 className='contact-text mt-5'>Headquarter</h2>
              <p className='contact-text-para'>
                92 East Main Street, Suite:405 Somerville,<br />
                New Jersey 08876 United States.<br />

              </p>
              <h2 className='contact-text'>Phone Number</h2>
              <p className='contact-text-para'>(732)-568-4786</p>
         
      
          
              <a href="/contact-us#ourlocation"><button type="button" class="btn btn-warning">Our Locations</button></a>
              </div>
  </div>

  {/* abscact img banner end */}

      <div className='clearfix'>  </div>

      {/* center section */}

      {/* <div className='container iot-sec4 pt-5 '>

<div className='row'>
<div className='col-12 col-lg-10 offset-lg-0 col-sm-8'>

<h3>Our Locations </h3>
<p>Our diverse team brings a world of unique perspectives and niche capabilities to every project we undertake, serving clients worldwide with unparalleled dedication and excellence. Discover how our global presence enables us to deliver tailor-made solutions that transcend borders and exceed expectations.</p>
</div>
</div>
</div>  */}
      <section className='relative1  bgglobe five-sec-2 start py-5 mb-5'>




        <div className=''
          style={{
            width: '50%',
            // maxWidth: '100%',
            aspectRatio: 1,
            margin: 'auto',
            position: 'relative',
          }}
        >


          <canvas
            ref={canvasRef}
            // onPointerDown={(e) => {
            //   pointerInteracting.current = e.clientX - pointerInteractionMovement.current;
            //   canvasRef.current.style.cursor = 'grabbing';
            // }}
            // onPointerUp={() => {
            //   pointerInteracting.current = null;
            //   canvasRef.current.style.cursor = 'grab';
            // }}
            // onPointerOut={() => {
            //   pointerInteracting.current = null;
            //   canvasRef.current.style.cursor = 'grab';
            // }}
            // onMouseMove={(e) => {
            //   if (pointerInteracting.current !== null) {
            //     const delta = e.clientX - pointerInteracting.current;
            //     pointerInteractionMovement.current = delta;
            //     api.start({
            //       r: delta / 200,
            //     });
            //   }
            // }}
            // onTouchMove={(e) => {
            //   if (pointerInteracting.current !== null && e.touches[0]) {
            //     const delta = e.touches[0].clientX - pointerInteracting.current;
            //     pointerInteractionMovement.current = delta;
            //     api.start({
            //       r: delta / 100,
            //     });
            //   }
            // }}
            style={{
              width: '100%',
              height: '100%',
              cursor: 'grab',
              contain: 'layout paint size',
              opacity: 0,
              transition: 'opacity 1s ease',
            }}
          />
        </div>
      </section>
      {/* center section end */}
      {/* <div className='freeflow-contact'>

<div className='flex-container-location'>
<div className='flex-cloumn'>
<div className='titleN'>Contact Us</div>
<h4 className=''>Address</h4>
<p>92 East Main Street, Suite:405 Somerville,<br/>
New Jersey 08876 United States.</p>
<h4>Phone Number</h4>
<p>(732)-568-4786</p>
</div>
</div>
</div> */}


      <div className='ourlocation text-center' id='ourlocation'>

        <div class="container">

        </div>

      </div>
      <div className='freeflow'>
        <div className="container mt-3">
          <div className="row">
            <div className='col-lg-9 offset-lg-0 col-sm-10 col-md-10 col-12 1text-center'>
              <div class="jumbotron">
                <h1>Our Locations</h1>
                <p>Our diverse team brings a world of unique perspectives and niche capabilities to every project we undertake, serving clients worldwide with unparalleled dedication and excellence. Discover how our global presence enables us to deliver tailor-made solutions that transcend borders and exceed expectations.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3">
          <div className="row">
            <div className="col-12 col-lg-9 ">
              <ul className="nav 2flex-column nav-pills" role="tablist" aria-orientation="vertical">



                <li className="nav-item locationtab">
                  <button
                    className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                    onClick={() => handleTabClick(0)}
                  >
                    North America
                  </button>
                </li>
                <li className="nav-item locationtab">
                  <button
                    className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => handleTabClick(1)}
                  >
                    Europe
                  </button>
                </li>
                <li className="nav-item locationtab">
                  <button
                    className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => handleTabClick(2)}
                  >
                    Asia
                  </button>
                </li>
                <li className="nav-item locationtab">
                  <button
                    className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                    onClick={() => handleTabClick(3)}
                  >
                    Middle East
                  </button>
                </li>
              </ul>
            </div>

            <div className="col-12 col-lg-9 ">
              <div className="tab-content">


                <div className={`tab-pane ${activeTab === 0 ? 'active' : ''}`} role="tabpanel">

                  <div className='flex-container-location'>



                  </div>
                  <div className='flex-container-location'>

                    <div className='flex-items-location mb-2'>

                      <div className='block-icon'>

                        <div className='loc1'><img src={newyork} className='img-fluid' /></div>

                        <div className='loc1'>
                          <h4 className='titleN'>New Jersey</h4>
                          <p>
                            92 East Main Street Suite:405<br />
                            Somerville, New Jersey 08876<br />
                            United States</p></div>
                      </div>


                    </div>
                    <div className='flex-items-location mt-3 mb-2'>

                      <div className='block-icon'>
                        <div className='loc1'><img src={newyork} className='img-fluid' /></div>
                        <div className='loc1'>
                          <h4 className='titleN'>New Jersey</h4>
                          <p>
                            324 Courtyard Drive,<br />
                            Hillsborough, NJ 08844</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-container-location'>
            
                    <div className='flex-items-location mt-3 mb-2'>
                      <div className='block-icon'>
                        <div className='loc1'><img src={newyork} className='img-fluid' /></div>
                        <div className='loc1'>
                          <h4 className='titleN'>Texas</h4>
                          <p>
                            3401 Cluster Rd Plano, Texas 75023<br />
                            United States</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 1 ? 'active' : ''}`} role="tabpanel">

                  <div className='flex-container-location  mt-3 mb-2'>

                    <div className='flex-items-location  mt-3 mb-2'>
                      <div className='block-icon'>
                        <div className='loc1'><img src={ireland} className='img-fluid' /></div>
                        <div className='loc1'>

                          <h4 className='titleN'>Ireland</h4>
                          <p>31 Glentain Manor Upper Carnamuggah, <br />County Donegal<br />
                            Ireland</p>

                        </div>
                      </div>
                    </div>

                    <div className='flex-items-location  mt-3 mb-2'>
                      <div className='block-icon'>
                        <div className='loc1'><img src={germany} className='img-fluid' /></div>
                        <div className='loc1'>
                          <h4 className='titleN'> Germany</h4>
                          <p>Procaltech GmbH<br />
                            c/o LETRIX<br />
                            Heubnerweg 7<br />
                            14059 Berlin Germany</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex-items-location mt-3 mb-2'>
                    <div className='block-icon'>
                      <div className='loc1'><img src={turkey} className='img-fluid' /></div>
                      <div className='loc1'>
                        <h4 className='titleN'>Turkey</h4>
                        <p>Hakki Yeten Cad Selenium Plaza No: 10/1<br />
                          Kat5-6, Fulya,<br /> Istanbul 34349
                          Turkey
                          Ireland</p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane ${activeTab === 2 ? 'active' : ''}`} role="tabpanel">



                  <div className='flex-container-location mt-3 mb-2'>
                    <div className='flex-items-location'>
                      <div className='block-icon'>
                        <div className='loc1'><img src={dubai} className='img-fluid' /></div>
                        <div className='loc1'>

                          <h4 className='titleN'>Mohali</h4>
                          <p>D 190 Industrial Area Sector 74,<br /> SAS Nagar Punjab 160071<br />
                            India</p>



                        </div>
                      </div>
                    </div>

                    <div className='flex-items-location  mt-3 mb-2'>
                      <div className='block-icon'>
                        <div className='loc1'><img src={dubai} className='img-fluid' /></div>
                        <div className='loc1'>
                          <h4 className='titleN'>Hyderabad</h4>
                          <p>Shangrilla Plaza, <br />Unit 407, <br />Road No 2,<br /> Banjarahills, Hyderabad, <br />Telangana 500034 India</p>


                        </div>
                      </div>
                    </div>
                  </div>


                </div>
                <div className={`tab-pane ${activeTab === 3 ? 'active' : ''}`} role="tabpanel">
                  <div className='flex-container-location mt-3 mb-2'>
                    <div className='flex-items-location'>
                      <div className='block-icon'>
                        <div className='loc1'><img src={dubai} className='img-fluid' /></div>
                        <div className='loc1'>

                          <h4 className='titleN'>Dubai</h4>
                          <p>Savvy One Businesses Center, <br />25th Floor, The Citadel Tower, <br />Business Bay, Sheikh Zayed Road, <br />Dubai, UAE</p>



                        </div>
                      </div>
                    </div>

                    <div className='flex-items-location  mt-3 mb-2'>
                      <div className='block-icon'>
                        <div className='loc1'><img src={dubai} className='img-fluid' /></div>
                        <div className='loc1'>

                          <h4 className='titleN'>Dubai22</h4>
                          <p>Savvy One Businesses Center, <br />25th Floor, The Citadel Tower, <br />Business Bay, Sheikh Zayed Road, <br />Dubai, UAE</p>



                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>



      <section className="container iot-sec4 pb-5 erer" id='consult'>
        <div class="container">

          <div class="row d-flex align-items-center1">



            <div class="col-md-4 col-lg-4 offset-lg-1 image-box">
              <img src={fea2} className='img-fluid' />
            </div>
            <div class="col-md-7 col-lg-7">
              <div className="container-trans">


                <div className="contact-form-trans trans">

                  <h2 className='text-center'>Free Client Consultation</h2>
                  <p className='text-center'>Request a free consultation meeting to discuss services.</p>
                  <form className='rfs'>

                    <input type="text" name="name" placeholder="Name" />
                    <input type="email" name="email" placeholder="Email" />
                    <input type="text" name="phone" placeholder="Phone" />
                    <textarea name="message" placeholder="Message" defaultValue={""} />
                    &nbsp;
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
            </div>




          </div>
        </div>
      </section>


    </>
  )
}

export default Location;