import React from 'react';
import Menu from './../../common/Menu';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from 'react';
import girl from './../../images/cocreate-logo.png'

import fea6 from './../../images/reality-3.jpg'
import reality1 from './../../images/reality1.png'

import whatsnew from './../../images/why-cocreate.png'

import Footer from './../../common/Footer'
import './../cocreate/cocreate.css'


// apple ani end
function Cocreate() {



const imageRef = useRef(null);
const ref = useRef(null);

useGSAP(() => {

gsap.registerPlugin(ScrollTrigger);

const targets = document.querySelectorAll('.list li');

gsap.set('.list', { autoAlpha: 1 });

const timeline = gsap.timeline({
defaults: {
overwrite: 'auto',
ease: 'power1',
},
scrollTrigger: {
end: '+=300%',
pin: true,
pinSpacing: true,
scrub: true,
start: 'top top',
trigger: '.list',
},
});

const stagger = 0.8;
const duration = 1;

gsap.set('.list', { autoAlpha: 1 });

timeline.set(targets, { transformOrigin: 'center bottom' });

timeline
.from(targets, {
duration: duration,
opacity: 0,
rotationX: 0,
rotationY: 0,
scale: 1,
stagger: stagger,
yPercent: 90,
})
.to(
targets,
{
duration: duration,
opacity: 0,
rotationX: 20,
rotationY: 2,
scale: 0.75,
stagger: stagger,
y: -100,
},
stagger
);

// Animation paragraph

const element = ref.current;

const pl = gsap.timeline({
scrollTrigger: {
trigger: element,
start: 'top bottom-=20%',
end: 'bottom top-=20%',
scrub: true,
},
});

pl.from(element, {
opacity: 0,
x: -100,

duration: 1,
ease: 'power2.out',
});


// Animation for the image reveal effect
gsap.from(imageRef.current, {
clipPath: 'polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)',
duration: 0.5, // Adjust duration
ease: 'power4.in', // Adjust easing
scrollTrigger: {
trigger: '.erer',
start: "top bottom",
end: ".z-index-fyv",
scrub: true,
onUpdate: self => {
const progress = self.progress;
const points = [
`0% 0%`,
`${progress * 100}% 0%`,
`${progress * 100}% 100%`,
`0% 100%`
];
gsap.set(imageRef.current, { clipPath: `polygon(${points.join(', ')})` });
},
},
});




const tl = gsap.timeline({
scrollTrigger: {
trigger: '.section-head-outline',
start: 'top top',
end: 'bottom top',
scrub: 0.2,
},
ease: 'power.inOut',
});

tl.to('.title-outline', {
yPercent: 100,
})
.to('.title--hello', {
yPercent: 100,
}, 0)
.to('.title--outline', {
yPercent: (i) => (i + 1) * 15 + 100,
}, 0)
.from('.image-box img', {
yPercent: 15,
ease: "none",
scrollTrigger: {
trigger: ".outline-box",
start: "top center",
scrub: 1,

},
ease: 'power.inOut',
}, 0)
.from('.image-box0 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box0",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)


.from('.image-box1 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box1",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box2 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box2",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0)

.from('.image-box3 img', {
yPercent: 25,
ease: "none",
scrollTrigger: {
trigger: ".image-box3",
start: "top center",
scrub: 2,

}, ease: 'power.inOut',
}, 0);



});

// slick slider code start
const settings = {
dots: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1
};

const settings2 = {
dots: true,
fade: true,
infinite: true,
speed: 500,
slidesToShow: 1,
slidesToScroll: 1,
autoplay: true,
waitForAnimate: false
};
// slick slider end
useEffect(() => {


});




return (
<>
<Menu />




<section className='relative1  cocreate-sec1 1five-sec-2 start'>


<div className='container'>
<div class="outline-box">
<section class="section-outline section-head-outline">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}


<div className='col-lg-3'>
<div className='sidetext'>
<div class="image-box">
<img src={girl} className='img-fluid' />
</div>
</div>
</div>
<div className='col-lg-8 offset-lg-1  1best-text'>
<div className='section_title'>Cocreate is an intelligent automation platform</div>

<p className='lead'>COCREATE  to create enterprise and consumer technologies, products, and solutions. The platform uses Artificial Intelligence and Automation Technologies. The platform aims to automate the end-to-end delivery lifecycle, provide intelligent automation capabilities, and offer a comprehensive set of features for application deployment, management, and scaling.</p>

<p className='lead'>An AI powered intelligent automation platform for Application Development, Product Testing, Devops for Cloud and on-prem ecosystems.</p>

</div>


</section>
</div>





</div>


</section>


<div className="img-container relative" id="cocreate-sec2">
<div id='the-div'>
<div className='container co-padding'>
<div className='row'>
<div className='col-lg-6' id="cocreate-txt">
<div ><span class="section_title">Transform Your Vision into Reality</span>
<p class="lead 1sub_text mt-3">Discover how AI can help you convert your ideas into solutions running in production. cocreate empowers you to bring your vision to life, seamlessly transitioning from concept to market-ready solutions. Whether you're developing applications, testing products, or managing DevOps for cloud and on-prem ecosystems, cocreate streamlines the process and accelerates your time-to-market.</p>
{/* <img src="stickers.png" width="300px" /> */}
</div></div>
<div className='col-lg-5 offset-lg-1'>
<div className='rightvision'><img src={reality1} className='img-fluid' /></div></div>
</div>
</div>



</div>
</div>



<div className="img-container relative" id="cocreate-sec3">
<div id='the-div'>
<div className='container co-padding' >
<div className='row'>
<div className='col-lg-5 '>
<div className='rightvision'><img src={fea6} className='img-fluid' /></div></div>
<div className='col-lg-6 offset-lg-1' id="cocreate-txt">
<div ><span class="section_title">From Idea to Market-Ready Solution</span>
<p class="lead 1sub_text mt-3">cocreate takes you beyond just automation. It offers a pathway to transform your innovative ideas into fully functional, production-ready applications. By leveraging the platform's intelligent automation capabilities, you can ensure that your solutions are not only deployed efficiently but also adhere to best practices and high security standards.</p>
{/* <img src="stickers.png" width="300px" /> */}
</div></div>

</div>
</div>



</div>
</div>


<section className='relative1  cocreate-sec100 1five-sec-2 start pb120'>


<div className='container'>
<div class="outline-box">
<section class="section-outline2">
{/* <div class="section-head__images">
<div class="image-box">
<img src="https://images.prismic.io/useallfive/OGU3MWUyMWItMWJhMy00YzdjLTk5OTUtZjcwMTQwZjMzYWMz_ricarda-messner-use-all-five-interview-1.jpg" />     
</div>
</div>  */}



<div className='col-lg-12 offset-lg-0  1best-text paddingall'>
<div className='section_title text-center mb-5'>Feature</div>
<div class="card-feature">


<div class="card-feature-7">
<h4>Code Repository & Domains</h4>
<p>automate the setup of code repositories and domain
configurtions to streamline tenanat creation.</p>
</div>

<div class="card-feature-1">
<h4>Backend Infrastrucutre</h4>
<p>Automatically provision
backend infrastructure to support application deployment
and scaling
</p>
</div>
<div class="card-feature-2">
<h4>Application FE,BE, & Data</h4>
<p>Generate boilerplate code for frontend and backend applications, along with
Kubernetes Helm charts.</p>
</div>




</div>

<div class="card-feature">




<div class="card-feature-3">
<h4>Microservice & Micro Frontend</h4>
<p>Provide integrated support for CDN, API Gateway, and
microservice-based architecture along with application creation.</p>
</div>
<div class="card-feature-4"><h4>GitOps Based CI-CD</h4>
<p>Auto generate continuous
integration and continuous
deployment (CI/CD) pipelines
on Gitlab & AgroCD as part of application creaetion.</p></div>
<div class="card-feature-5"><h4>Security Enhancement</h4>
<p>
Production-grade security by managing secrets and access control with Vault
</p></div>

</div>
</div>

</section>
</div>





</div>


</section>

<div className="img-container relative" id="cocreate-sec2">
<div id='the-div'>
<div className='container co-padding'>
<div className='row'>
<div className='col-lg-12 col-12'>    <div className='section_title text-center mb-5'>Why Cocreate ?</div></div>
<div className='col-lg-6' id="why-txt">
<ul class="newlist">
<li><i class="pulse green" ></i> CoCREATE is a platform to Develop, Integrate, and Launch your products seemlesly with AI powered intelligent automation.</li>
<li><i class="pulse green" ></i>AI powered context specific code generation &amp; reviews</li>
<li><i class="pulse green" ></i>AI powered development and testing &amp; technology recommendation</li>
<li><i class="pulse green" ></i>AI powered observability, monitoring, and root cause analysis</li></ul>

</div>
<div className='col-lg-5 offset-lg-1'>
<div className='rightvision'><img src={whatsnew} className='img-fluid' /></div></div>
</div>
</div>



</div>
</div>






<Footer />
</>
)
}

export default Cocreate
